<template>
  <div class="wrapper">
    <Menu></Menu>
    <div class="body" style="padding: 0px 13px">

      <div class="row">
        <img class="d-block w-100" src="/assets/images/drops-win.png" alt="Second slide"/>
      </div>

      <div class="row" v-for="(i, x) in categories" :key="getKey(x)" :id="i.name" style="background-color: #120028">

        <div v-show="i.status === 1  " class="casino-headers col-12" style="font-size: 1.3em;margin: 5px;padding: 0.4em;background-color: rgb(39, 39, 63);color: white;">
          <span v-text="i.name"></span>
        </div>

        <!-- new card design -->
        <div v-show="i.status === 1  " style="text-align: center;overflow-x: scroll;overflow-y: hidden;white-space: nowrap;width: 100%;">

          <div class="card col-lg-2 col-md-2 col-sm-2" v-for="(o, index) in i.games" :key="getKey(index)">

            <a :href="'#' + o.game_id">
              <img
                  :src="o.image"
                  alt="Avatar"
                  style="
                  max-width: 100%;
                  height: 140px;
                  border-radius: 10px;
                  border: 1px solid #ddd;
                "
              />
            </a>

            <!-- modal here -->
            <div :id="o.game_id" class="modal-window" style="color: white">

              <div style="background-color: #353b40">

                <a href="#" title="Close" class="modal-close">X</a>

                <h1>{{ o.game_name }}</h1>

                <div>
                  <img :src="o.image" alt="gameimg" height="100"/>
                </div>

                <div class="text-center" >

<!--                  <router-link v-show="parseInt(o.has_demo) === 1" :to="{name: 'casinogames',params: {gameName: o.game_name,launchURL: o.demo_url,launchType: 1,gameID: o.game_id},}">-->
<!--                    <button class="btn btn-warning"><b>DEMO</b></button>-->
<!--                  </router-link>-->

                  <router-link :to="{name: 'casinogames',params: {gameName: o.game_name,gameID: o.game_id,providerID: o.provider_id}}">
                    <button class="btn btn-success"><b>PLAY NOW</b></button>
                  </router-link>

                </div>

              </div>
            </div>
            <!-- modal here -->
          </div>
        </div>
        <!-- new card design -->
      </div>
    </div>
    <BottomFooter></BottomFooter>
    <bottom-navigation active-bets="0" page="freebet"></bottom-navigation>
  </div>
</template>
<style scoped>
.card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  width: 37%;
  border-radius: 5px;
  margin-right: 5px;
  margin-bottom: 5px;
  display: inline-block;
}

.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.container {
  padding: 2px 16px;
}

.modal-window {
  position: fixed;
  background-color: rgba(255, 255, 255, 0.25);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
  transition: all 0.3s;
}

.modal-window:target {
  visibility: visible;
  opacity: 1;
  pointer-events: auto;
}

.modal-window > div {
  width: 400px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 2em;
  background: white;
}

.modal-window header {
  font-weight: bold;
}

.modal-window h1 {
  font-size: 150%;
  margin: 0 0 15px;
}

.modal-close {
  color: #aaa;
  line-height: 50px;
  font-size: 80%;
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;
  width: 70px;
  text-decoration: none;
}

.modal-close:hover {
  color: black;
}

.container {
  display: grid;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.modal-window > div {
  border-radius: 1rem;
}

.modal-window div:not(:last-of-type) {
  margin-bottom: 15px;
}
</style>
<script>

const Menu = () => import("@/components/Menu.vue");
import BottomFooter from "@/components/BottomFooter";
import BottomNavigation from "@/components/BottomNavigation";
import casino from "@/services/casino";

export default {
  name: "Casino",
  components: {
    Menu,
    BottomFooter,
    BottomNavigation,
  },
  data: function () {
    return {
      match_id: 0,
      game_id: 0,
      fixture: {},
      initial_fixture: {},
      market_group: {
        id: 0,
      },
      totals: [],
      live: false,
      seconds: 0,
      possible_win: "",
      payout: "",
      tax: "",
      odd_id: "",
      outcome: {},
      msisdn: "",
      password: "",
      loading: "",
      code: "",
      action_verify_password: false,
      action_success: false,
      message: "",
      allgamesdata: [],
      provider_id: 5,
      categories:  [
          {
            "id": 15,
            "name": "Popular",
            "priority": 100,
            "status": 1,
            "games": [
              {
                "category": "Popular",
                "description": "",
                "game_id": "aviator",
                "game_name": "Aviator",
                "id": 674,
                "image": "https://storage.googleapis.com/maybet/spribe/aviator.png",
                "priority": 100,
                "provider_id": 7,
                "provider_name": "Spribe",
                "status": 1
              },
              {
                "category": "Popular",
                "description": "",
                "game_id": "rla",
                "game_name": "Roulette",
                "id": 641,
                "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/rla.png",
                "priority": 99,
                "provider_id": 1,
                "provider_name": "Pragmatic Play",
                "status": 1
              },
              {
                "category": "Popular",
                "description": "",
                "game_id": "rocketman",
                "game_name": "Rocket Man",
                "id": 667,
                "image": "https://storage.googleapis.com/maybet/elbet/rocketman.png",
                "priority": 99,
                "provider_id": 4,
                "provider_name": "Elbet",
                "status": 1
              },
              {
                "category": "Popular",
                "description": "",
                "game_id": "JetX",
                "game_name": "JetX",
                "id": 27,
                "image": "https://storage.googleapis.com/maybet/smartsoft/JetX.png",
                "priority": 98,
                "provider_id": 5,
                "provider_name": "Smartsoft Gaming",
                "status": 1
              },
              {
                "category": "Popular",
                "description": "",
                "game_id": "vs20olympx",
                "game_name": "Gates of Olympus 1000",
                "id": 93,
                "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vs20olympx.png",
                "priority": 98,
                "provider_id": 1,
                "provider_name": "Pragmatic Play",
                "status": 1
              },
              {
                "category": "Popular",
                "description": "",
                "game_id": "vs20sugarnudge",
                "game_name": "Sugar Supreme Powernudge",
                "id": 110,
                "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vs20sugarnudge.png",
                "priority": 98,
                "provider_id": 1,
                "provider_name": "Pragmatic Play",
                "status": 1
              },
              {
                "category": "Popular",
                "description": "",
                "game_id": "vs20starlightx",
                "game_name": "Starlight Princess 1000",
                "id": 111,
                "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vs20starlightx.png",
                "priority": 98,
                "provider_id": 1,
                "provider_name": "Pragmatic Play",
                "status": 1
              },
              {
                "category": "Popular",
                "description": "",
                "game_id": "vs20starlight",
                "game_name": "Starlight Princess",
                "id": 313,
                "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vs20starlight.png",
                "priority": 98,
                "provider_id": 1,
                "provider_name": "Pragmatic Play",
                "status": 1
              },
              {
                "category": "Popular",
                "description": "",
                "game_id": "vs20superx",
                "game_name": "Super X",
                "id": 320,
                "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vs20superx.png",
                "priority": 98,
                "provider_id": 1,
                "provider_name": "Pragmatic Play",
                "status": 1
              },
              {
                "category": "Popular",
                "description": "",
                "game_id": "vs20rhinoluxe",
                "game_name": "Great Rhino Deluxe",
                "id": 335,
                "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vs20rhinoluxe.png",
                "priority": 98,
                "provider_id": 1,
                "provider_name": "Pragmatic Play",
                "status": 1
              },
              {
                "category": "Popular",
                "description": "",
                "game_id": "vs15godsofwar",
                "game_name": "Zeus vs Hades - Gods of War",
                "id": 144,
                "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vs15godsofwar.png",
                "priority": 97,
                "provider_id": 1,
                "provider_name": "Pragmatic Play",
                "status": 1
              },
              {
                "category": "Popular",
                "description": "",
                "game_id": "vs20stickywild",
                "game_name": "Wild Bison Charge",
                "id": 151,
                "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vs20stickywild.png",
                "priority": 96,
                "provider_id": 1,
                "provider_name": "Pragmatic Play",
                "status": 1
              },
              {
                "category": "Popular",
                "description": "",
                "game_id": "vs9aztecgemsdx",
                "game_name": "Aztec Gems Deluxe",
                "id": 337,
                "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vs9aztecgemsdx.png",
                "priority": 90,
                "provider_id": 1,
                "provider_name": "Pragmatic Play",
                "status": 1
              },
              {
                "category": "Popular",
                "description": "",
                "game_id": "FootballX",
                "game_name": "FootballX",
                "id": 31,
                "image": "https://storage.googleapis.com/maybet/smartsoft/FootballX.png",
                "priority": 56,
                "provider_id": 5,
                "provider_name": "Smartsoft Gaming",
                "status": 1
              },
              {
                "category": "Popular",
                "description": "",
                "game_id": "vs20gravity",
                "game_name": "Gravity Bonanza",
                "id": 113,
                "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vs20gravity.png",
                "priority": 2,
                "provider_id": 1,
                "provider_name": "Pragmatic Play",
                "status": 1
              },
              {
                "category": "Popular",
                "description": "",
                "game_id": "CrazyApe",
                "game_name": "Crazy Ape",
                "id": 15,
                "image": "https://storage.googleapis.com/maybet/smartsoft/CrazyApe.png",
                "priority": 1,
                "provider_id": 5,
                "provider_name": "Smartsoft Gaming",
                "status": 1
              },
              {
                "category": "Popular",
                "description": "",
                "game_id": "PlinkoX",
                "game_name": "PlinkoX",
                "id": 57,
                "image": "https://storage.googleapis.com/maybet/smartsoft/PlinkoX.png",
                "priority": 1,
                "provider_id": 5,
                "provider_name": "Smartsoft Gaming",
                "status": 1
              },
              {
                "category": "Popular",
                "description": "",
                "game_id": "vs20stckwldsc",
                "game_name": "Pot of Fortune",
                "id": 72,
                "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vs20stckwldsc.png",
                "priority": 1,
                "provider_id": 1,
                "provider_name": "Pragmatic Play",
                "status": 1
              },
              {
                "category": "Popular",
                "description": "",
                "game_id": "vs20treesot",
                "game_name": "Trees of Treasure",
                "id": 73,
                "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vs20treesot.png",
                "priority": 1,
                "provider_id": 1,
                "provider_name": "Pragmatic Play",
                "status": 1
              },
              {
                "category": "Popular",
                "description": "",
                "game_id": "vs10bbbnz",
                "game_name": "Big Bass Day at the Races",
                "id": 74,
                "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vs10bbbnz.png",
                "priority": 1,
                "provider_id": 1,
                "provider_name": "Pragmatic Play",
                "status": 1
              },
              {
                "category": "Popular",
                "description": "",
                "game_id": "vswaysbewaretd",
                "game_name": "Beware The Deep Megaways",
                "id": 75,
                "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vswaysbewaretd.png",
                "priority": 1,
                "provider_id": 1,
                "provider_name": "Pragmatic Play",
                "status": 1
              },
              {
                "category": "Popular",
                "description": "",
                "game_id": "vs20clustext",
                "game_name": "Gears of Horus",
                "id": 76,
                "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vs20clustext.png",
                "priority": 1,
                "provider_id": 1,
                "provider_name": "Pragmatic Play",
                "status": 1
              },
              {
                "category": "Popular",
                "description": "",
                "game_id": "vs20sugarrushx",
                "game_name": "Sugar Rush 1000",
                "id": 79,
                "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vs20sugarrushx.png",
                "priority": 1,
                "provider_id": 1,
                "provider_name": "Pragmatic Play",
                "status": 1
              },
              {
                "category": "Popular",
                "description": "",
                "game_id": "vs10bbfloats",
                "game_name": "Big Bass Floats my Boat",
                "id": 80,
                "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vs10bbfloats.png",
                "priority": 1,
                "provider_id": 1,
                "provider_name": "Pragmatic Play",
                "status": 1
              },
              {
                "category": "Popular",
                "description": "",
                "game_id": "vs10luckfort",
                "game_name": "Good Luck & Good Fortune",
                "id": 82,
                "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vs10luckfort.png",
                "priority": 1,
                "provider_id": 1,
                "provider_name": "Pragmatic Play",
                "status": 1
              },
              {
                "category": "Popular",
                "description": "",
                "game_id": "vs20yotdk",
                "game_name": "Year Of The Dragon King",
                "id": 83,
                "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vs20yotdk.png",
                "priority": 1,
                "provider_id": 1,
                "provider_name": "Pragmatic Play",
                "status": 1
              },
              {
                "category": "Popular",
                "description": "",
                "game_id": "vs20loksriches",
                "game_name": "Loki's Riches",
                "id": 86,
                "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vs20loksriches.png",
                "priority": 1,
                "provider_id": 1,
                "provider_name": "Pragmatic Play",
                "status": 1
              },
              {
                "category": "Popular",
                "description": "",
                "game_id": "vs10strawberry",
                "game_name": "Strawberry Cocktail",
                "id": 87,
                "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vs10strawberry.png",
                "priority": 1,
                "provider_id": 1,
                "provider_name": "Pragmatic Play",
                "status": 1
              },
              {
                "category": "Popular",
                "description": "",
                "game_id": "vswayswildgang",
                "game_name": "The Wild Gang",
                "id": 95,
                "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vswayswildgang.png",
                "priority": 1,
                "provider_id": 1,
                "provider_name": "Pragmatic Play",
                "status": 1
              },
              {
                "category": "Popular",
                "description": "",
                "game_id": "vswaysstampede",
                "game_name": "Fire Stampede",
                "id": 99,
                "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vswaysstampede.png",
                "priority": 1,
                "provider_id": 1,
                "provider_name": "Pragmatic Play",
                "status": 1
              },
              {
                "category": "Popular",
                "description": "",
                "game_id": "vs10bbsplxmas",
                "game_name": "Big Bass Christmas Bash",
                "id": 102,
                "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vs10bbsplxmas.png",
                "priority": 1,
                "provider_id": 1,
                "provider_name": "Pragmatic Play",
                "status": 1
              },
              {
                "category": "Popular",
                "description": "",
                "game_id": "vs20sugrux",
                "game_name": "Sugar Rush Xmas",
                "id": 104,
                "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vs20sugrux.png",
                "priority": 1,
                "provider_id": 1,
                "provider_name": "Pragmatic Play",
                "status": 1
              },
              {
                "category": "Popular",
                "description": "",
                "game_id": "vswaysmoneyman",
                "game_name": "The Money Men Megaways",
                "id": 105,
                "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vswaysmoneyman.png",
                "priority": 1,
                "provider_id": 1,
                "provider_name": "Pragmatic Play",
                "status": 1
              },
              {
                "category": "Popular",
                "description": "",
                "game_id": "vs20maskgame",
                "game_name": "Cash Chips",
                "id": 108,
                "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vs20maskgame.png",
                "priority": 1,
                "provider_id": 1,
                "provider_name": "Pragmatic Play",
                "status": 1
              },
              {
                "category": "Popular",
                "description": "",
                "game_id": "vs40infwild",
                "game_name": "Infective Wild",
                "id": 109,
                "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vs40infwild.png",
                "priority": 1,
                "provider_id": 1,
                "provider_name": "Pragmatic Play",
                "status": 1
              },
              {
                "category": "Popular",
                "description": "",
                "game_id": "vswaystut",
                "game_name": "Book of Tut Megaways",
                "id": 112,
                "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vswaystut.png",
                "priority": 1,
                "provider_id": 1,
                "provider_name": "Pragmatic Play",
                "status": 1
              },
              {
                "category": "Popular",
                "description": "",
                "game_id": "vs40rainbowr",
                "game_name": "Rainbow Reels",
                "id": 114,
                "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vs40rainbowr.png",
                "priority": 1,
                "provider_id": 1,
                "provider_name": "Pragmatic Play",
                "status": 1
              },
              {
                "category": "Popular",
                "description": "",
                "game_id": "vs20dhcluster",
                "game_name": "Twilight Princess",
                "id": 116,
                "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vs20dhcluster.png",
                "priority": 1,
                "provider_id": 1,
                "provider_name": "Pragmatic Play",
                "status": 1
              },
              {
                "category": "Popular",
                "description": "",
                "game_id": "vswaysstrlght",
                "game_name": "Fortunes of Aztec",
                "id": 117,
                "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vswaysstrlght.png",
                "priority": 1,
                "provider_id": 1,
                "provider_name": "Pragmatic Play",
                "status": 1
              },
              {
                "category": "Popular",
                "description": "",
                "game_id": "vswaysftropics",
                "game_name": "Frozen Tropics",
                "id": 118,
                "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vswaysftropics.png",
                "priority": 1,
                "provider_id": 1,
                "provider_name": "Pragmatic Play",
                "status": 1
              },
              {
                "category": "Popular",
                "description": "",
                "game_id": "vs10bhallbnza",
                "game_name": "Big Bass Halloween",
                "id": 119,
                "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vs10bhallbnza.png",
                "priority": 1,
                "provider_id": 1,
                "provider_name": "Pragmatic Play",
                "status": 1
              },
              {
                "category": "Popular",
                "description": "",
                "game_id": "vswaysincwnd",
                "game_name": "Gold Oasis",
                "id": 121,
                "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vswaysincwnd.png",
                "priority": 1,
                "provider_id": 1,
                "provider_name": "Pragmatic Play",
                "status": 1
              },
              {
                "category": "Popular",
                "description": "",
                "game_id": "vs20piggybank",
                "game_name": "Piggy Bankers",
                "id": 122,
                "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vs20piggybank.png",
                "priority": 1,
                "provider_id": 1,
                "provider_name": "Pragmatic Play",
                "status": 1
              },
              {
                "category": "Popular",
                "description": "",
                "game_id": "vs20earthquake",
                "game_name": "Cyclops Smash",
                "id": 123,
                "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vs20earthquake.png",
                "priority": 1,
                "provider_id": 1,
                "provider_name": "Pragmatic Play",
                "status": 1
              },
              {
                "category": "Popular",
                "description": "",
                "game_id": "vs50dmdcascade",
                "game_name": "Diamond Cascade",
                "id": 124,
                "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vs50dmdcascade.png",
                "priority": 1,
                "provider_id": 1,
                "provider_name": "Pragmatic Play",
                "status": 1
              },
              {
                "category": "Popular",
                "description": "",
                "game_id": "vswaysrockblst",
                "game_name": "Rocket Blast Megaways",
                "id": 125,
                "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vswaysrockblst.png",
                "priority": 1,
                "provider_id": 1,
                "provider_name": "Pragmatic Play",
                "status": 1
              },
              {
                "category": "Popular",
                "description": "",
                "game_id": "vs20lvlup",
                "game_name": "Pub Kings",
                "id": 126,
                "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vs20lvlup.png",
                "priority": 1,
                "provider_id": 1,
                "provider_name": "Pragmatic Play",
                "status": 1
              },
              {
                "category": "Popular",
                "description": "",
                "game_id": "vs50jucier",
                "game_name": "Sky Bounty",
                "id": 127,
                "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vs50jucier.png",
                "priority": 1,
                "provider_id": 1,
                "provider_name": "Pragmatic Play",
                "status": 1
              },
              {
                "category": "Popular",
                "description": "",
                "game_id": "vs20forge",
                "game_name": "Forge of Olympus",
                "id": 128,
                "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vs20forge.png",
                "priority": 1,
                "provider_id": 1,
                "provider_name": "Pragmatic Play",
                "status": 1
              },
              {
                "category": "Popular",
                "description": "",
                "game_id": "vswaysbbhas",
                "game_name": "Big Bass Hold & Spinner Megaways",
                "id": 129,
                "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vswaysbbhas.png",
                "priority": 1,
                "provider_id": 1,
                "provider_name": "Pragmatic Play",
                "status": 1
              },
              {
                "category": "Popular",
                "description": "",
                "game_id": "vs10trail",
                "game_name": "Mustang Trail",
                "id": 130,
                "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vs10trail.png",
                "priority": 1,
                "provider_id": 1,
                "provider_name": "Pragmatic Play",
                "status": 1
              },
              {
                "category": "Popular",
                "description": "",
                "game_id": "vs20beefed",
                "game_name": "Fat Panda",
                "id": 136,
                "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vs20beefed.png",
                "priority": 1,
                "provider_id": 1,
                "provider_name": "Pragmatic Play",
                "status": 1
              },
              {
                "category": "Popular",
                "description": "",
                "game_id": "vs10bbextreme",
                "game_name": "Big Bass Amazon Xtreme",
                "id": 137,
                "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vs10bbextreme.png",
                "priority": 1,
                "provider_id": 1,
                "provider_name": "Pragmatic Play",
                "status": 1
              },
              {
                "category": "Popular",
                "description": "",
                "game_id": "vs20procount",
                "game_name": "Wisdom of Athena",
                "id": 138,
                "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vs20procount.png",
                "priority": 1,
                "provider_id": 1,
                "provider_name": "Pragmatic Play",
                "status": 1
              },
              {
                "category": "Popular",
                "description": "",
                "game_id": "vs20wildparty",
                "game_name": "3 Buzzing Wilds",
                "id": 139,
                "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vs20wildparty.png",
                "priority": 1,
                "provider_id": 1,
                "provider_name": "Pragmatic Play",
                "status": 1
              },
              {
                "category": "Popular",
                "description": "",
                "game_id": "vs20mysteryst",
                "game_name": "Country Farming",
                "id": 147,
                "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vs20mysteryst.png",
                "priority": 1,
                "provider_id": 1,
                "provider_name": "Pragmatic Play",
                "status": 1
              },
              {
                "category": "Popular",
                "description": "",
                "game_id": "vswayswwriches",
                "game_name": "Wild Wild Riches Megaways",
                "id": 163,
                "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vswayswwriches.png",
                "priority": 1,
                "provider_id": 1,
                "provider_name": "Pragmatic Play",
                "status": 1
              }
            ]
          },
              {
                "id": 7,
                "name": "Video slots",
                "priority": 90,
                "status": 1,
                "games": [
                  {
                    "category": "Video slots",
                    "description": "",
                    "game_id": "vs20sugarnudge",
                    "game_name": "Sugar Supreme Powernudge",
                    "id": 110,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vs20sugarnudge.png",
                    "priority": 98,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Video slots",
                    "description": "",
                    "game_id": "vs20starlightx",
                    "game_name": "Starlight Princess 1000",
                    "id": 111,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vs20starlightx.png",
                    "priority": 98,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Video slots",
                    "description": "",
                    "game_id": "vs20superx",
                    "game_name": "Super X",
                    "id": 320,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vs20superx.png",
                    "priority": 98,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Video slots",
                    "description": "",
                    "game_id": "vs20rhinoluxe",
                    "game_name": "Great Rhino Deluxe",
                    "id": 335,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vs20rhinoluxe.png",
                    "priority": 98,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Video slots",
                    "description": "",
                    "game_id": "vs15godsofwar",
                    "game_name": "Zeus vs Hades - Gods of War",
                    "id": 144,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vs15godsofwar.png",
                    "priority": 97,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Video slots",
                    "description": "",
                    "game_id": "vs20stickywild",
                    "game_name": "Wild Bison Charge",
                    "id": 151,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vs20stickywild.png",
                    "priority": 96,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Video slots",
                    "description": "",
                    "game_id": "vs9aztecgemsdx",
                    "game_name": "Aztec Gems Deluxe",
                    "id": 337,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vs9aztecgemsdx.png",
                    "priority": 90,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Video slots",
                    "description": "",
                    "game_id": "vs20gravity",
                    "game_name": "Gravity Bonanza",
                    "id": 113,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vs20gravity.png",
                    "priority": 2,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Video slots",
                    "description": "",
                    "game_id": "vs20stckwldsc",
                    "game_name": "Pot of Fortune",
                    "id": 72,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vs20stckwldsc.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Video slots",
                    "description": "",
                    "game_id": "vs20treesot",
                    "game_name": "Trees of Treasure",
                    "id": 73,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vs20treesot.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Video slots",
                    "description": "",
                    "game_id": "vs10bbbnz",
                    "game_name": "Big Bass Day at the Races",
                    "id": 74,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vs10bbbnz.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Video slots",
                    "description": "",
                    "game_id": "vswaysbewaretd",
                    "game_name": "Beware The Deep Megaways",
                    "id": 75,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vswaysbewaretd.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Video slots",
                    "description": "",
                    "game_id": "vs20clustext",
                    "game_name": "Gears of Horus",
                    "id": 76,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vs20clustext.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Video slots",
                    "description": "",
                    "game_id": "vs20powerpays",
                    "game_name": "Red Hot Luck",
                    "id": 81,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vs20powerpays.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Video slots",
                    "description": "",
                    "game_id": "vs10luckfort",
                    "game_name": "Good Luck & Good Fortune",
                    "id": 82,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vs10luckfort.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Video slots",
                    "description": "",
                    "game_id": "vs20yotdk",
                    "game_name": "Year Of The Dragon King",
                    "id": 83,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vs20yotdk.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Video slots",
                    "description": "",
                    "game_id": "vs20bigdawgs",
                    "game_name": "The Big Dawgs",
                    "id": 84,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vs20bigdawgs.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Video slots",
                    "description": "",
                    "game_id": "vswaysexpandng",
                    "game_name": "Castle of Fire",
                    "id": 85,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vswaysexpandng.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Video slots",
                    "description": "",
                    "game_id": "vs20loksriches",
                    "game_name": "Loki's Riches",
                    "id": 86,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vs20loksriches.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Video slots",
                    "description": "",
                    "game_id": "vs10strawberry",
                    "game_name": "Strawberry Cocktail",
                    "id": 87,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vs10strawberry.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Video slots",
                    "description": "",
                    "game_id": "vswaysfirewmw",
                    "game_name": "Blazing Wilds Megaways",
                    "id": 88,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vswaysfirewmw.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Video slots",
                    "description": "",
                    "game_id": "vs20mmmelon",
                    "game_name": "Mighty Munching Melons",
                    "id": 89,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vs20mmmelon.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Video slots",
                    "description": "",
                    "game_id": "vs20multiup",
                    "game_name": "Wheel O'Gold",
                    "id": 90,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vs20multiup.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Video slots",
                    "description": "",
                    "game_id": "vs20mergedwndw",
                    "game_name": "Blade & Fangs",
                    "id": 91,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vs20mergedwndw.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Video slots",
                    "description": "",
                    "game_id": "vswaysfltdrgny",
                    "game_name": "Floating Dragon New Year Festival Ultra Megaways Hold & Spin",
                    "id": 92,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vswaysfltdrgny.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Video slots",
                    "description": "",
                    "game_id": "vswayscfglory",
                    "game_name": "Chase For Glory",
                    "id": 94,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vswayscfglory.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Video slots",
                    "description": "",
                    "game_id": "vswayswildgang",
                    "game_name": "The Wild Gang",
                    "id": 95,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vswayswildgang.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Video slots",
                    "description": "",
                    "game_id": "vs20cjcluster",
                    "game_name": "Candy Jar Clusters",
                    "id": 96,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vs20cjcluster.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Video slots",
                    "description": "",
                    "game_id": "vs20nilefort",
                    "game_name": "Nile Fortune",
                    "id": 97,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vs20nilefort.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Video slots",
                    "description": "",
                    "game_id": "vs50jfmulthold",
                    "game_name": "Juicy Fruits Multihold",
                    "id": 98,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vs50jfmulthold.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Video slots",
                    "description": "",
                    "game_id": "vswaysstampede",
                    "game_name": "Fire Stampede",
                    "id": 99,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vswaysstampede.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Video slots",
                    "description": "",
                    "game_id": "vs10ddcbells",
                    "game_name": "Ding Dong Christmas Bells",
                    "id": 100,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vs10ddcbells.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Video slots",
                    "description": "",
                    "game_id": "vswaystimber",
                    "game_name": "Timber Stacks",
                    "id": 101,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vswaystimber.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Video slots",
                    "description": "",
                    "game_id": "vs10bbsplxmas",
                    "game_name": "Big Bass Christmas Bash",
                    "id": 102,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vs10bbsplxmas.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Video slots",
                    "description": "",
                    "game_id": "vswaysraghex",
                    "game_name": "Tundra's Fortune",
                    "id": 103,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vswaysraghex.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Video slots",
                    "description": "",
                    "game_id": "vs20sugrux",
                    "game_name": "Sugar Rush Xmas",
                    "id": 104,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vs20sugrux.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Video slots",
                    "description": "",
                    "game_id": "vswaysmoneyman",
                    "game_name": "The Money Men Megaways",
                    "id": 105,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vswaysmoneyman.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Video slots",
                    "description": "",
                    "game_id": "vswayscharms",
                    "game_name": "5 Frozen Charms Megaways",
                    "id": 106,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vswayscharms.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Video slots",
                    "description": "",
                    "game_id": "vs20sugarcoins",
                    "game_name": "Viking Forge",
                    "id": 107,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vs20sugarcoins.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Video slots",
                    "description": "",
                    "game_id": "vs20maskgame",
                    "game_name": "Cash Chips",
                    "id": 108,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vs20maskgame.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Video slots",
                    "description": "",
                    "game_id": "vs40infwild",
                    "game_name": "Infective Wild",
                    "id": 109,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vs40infwild.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Video slots",
                    "description": "",
                    "game_id": "vswaystut",
                    "game_name": "Book of Tut Megaways",
                    "id": 112,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vswaystut.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Video slots",
                    "description": "",
                    "game_id": "vs40rainbowr",
                    "game_name": "Rainbow Reels",
                    "id": 114,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vs40rainbowr.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Video slots",
                    "description": "",
                    "game_id": "vs10gdchalleng",
                    "game_name": "8 Golden Dragon Challenge",
                    "id": 115,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vs10gdchalleng.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Video slots",
                    "description": "",
                    "game_id": "vs20dhcluster",
                    "game_name": "Twilight Princess",
                    "id": 116,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vs20dhcluster.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Video slots",
                    "description": "",
                    "game_id": "vswaysstrlght",
                    "game_name": "Fortunes of Aztec",
                    "id": 117,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vswaysstrlght.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Video slots",
                    "description": "",
                    "game_id": "vswaysftropics",
                    "game_name": "Frozen Tropics",
                    "id": 118,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vswaysftropics.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Video slots",
                    "description": "",
                    "game_id": "vs10bhallbnza",
                    "game_name": "Big Bass Halloween",
                    "id": 119,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vs10bhallbnza.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Video slots",
                    "description": "",
                    "game_id": "vs40demonpots",
                    "game_name": "Demon Pots",
                    "id": 120,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vs40demonpots.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Video slots",
                    "description": "",
                    "game_id": "vswaysincwnd",
                    "game_name": "Gold Oasis",
                    "id": 121,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vswaysincwnd.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Video slots",
                    "description": "",
                    "game_id": "vs20piggybank",
                    "game_name": "Piggy Bankers",
                    "id": 122,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vs20piggybank.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Video slots",
                    "description": "",
                    "game_id": "vs20earthquake",
                    "game_name": "Cyclops Smash",
                    "id": 123,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vs20earthquake.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Video slots",
                    "description": "",
                    "game_id": "vs50dmdcascade",
                    "game_name": "Diamond Cascade",
                    "id": 124,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vs50dmdcascade.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Video slots",
                    "description": "",
                    "game_id": "vswaysrockblst",
                    "game_name": "Rocket Blast Megaways",
                    "id": 125,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vswaysrockblst.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Video slots",
                    "description": "",
                    "game_id": "vs20lvlup",
                    "game_name": "Pub Kings",
                    "id": 126,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vs20lvlup.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Video slots",
                    "description": "",
                    "game_id": "vs50jucier",
                    "game_name": "Sky Bounty",
                    "id": 127,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vs50jucier.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Video slots",
                    "description": "",
                    "game_id": "vs20forge",
                    "game_name": "Forge of Olympus",
                    "id": 128,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vs20forge.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Video slots",
                    "description": "",
                    "game_id": "vswaysbbhas",
                    "game_name": "Big Bass Hold & Spinner Megaways",
                    "id": 129,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vswaysbbhas.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Video slots",
                    "description": "",
                    "game_id": "vs10trail",
                    "game_name": "Mustang Trail",
                    "id": 130,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vs10trail.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Video slots",
                    "description": "",
                    "game_id": "vs20candyblitz",
                    "game_name": "Candy Blitz",
                    "id": 131,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vs20candyblitz.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Video slots",
                    "description": "",
                    "game_id": "vs20lobcrab",
                    "game_name": "Lobster Bob's Crazy Crab Shack",
                    "id": 132,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vs20lobcrab.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Video slots",
                    "description": "",
                    "game_id": "vs20cashmachine",
                    "game_name": "Cash Box",
                    "id": 133,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vs20cashmachine.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Video slots",
                    "description": "",
                    "game_id": "vswayspowzeus",
                    "game_name": "Power of Merlin Megaways",
                    "id": 134,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vswayspowzeus.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Video slots",
                    "description": "",
                    "game_id": "vs20hstgldngt",
                    "game_name": "Heist for the Golden Nuggets",
                    "id": 135,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vs20hstgldngt.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Video slots",
                    "description": "",
                    "game_id": "vs20beefed",
                    "game_name": "Fat Panda",
                    "id": 136,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vs20beefed.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Video slots",
                    "description": "",
                    "game_id": "vs10bbextreme",
                    "game_name": "Big Bass Amazon Xtreme",
                    "id": 137,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vs10bbextreme.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Video slots",
                    "description": "",
                    "game_id": "vs20procount",
                    "game_name": "Wisdom of Athena",
                    "id": 138,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vs20procount.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Video slots",
                    "description": "",
                    "game_id": "vs20wildparty",
                    "game_name": "3 Buzzing Wilds",
                    "id": 139,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vs20wildparty.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Video slots",
                    "description": "",
                    "game_id": "vs20splmystery",
                    "game_name": "Spellbinding Mystery",
                    "id": 140,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vs20splmystery.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Video slots",
                    "description": "",
                    "game_id": "vs243nudge4gold",
                    "game_name": "Hellvis Wild",
                    "id": 141,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vs243nudge4gold.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Video slots",
                    "description": "",
                    "game_id": "vs20jewelparty",
                    "game_name": "Jewel Rush",
                    "id": 142,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vs20jewelparty.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Video slots",
                    "description": "",
                    "game_id": "vs25spotz",
                    "game_name": "Knight Hot Spotz",
                    "id": 143,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vs25spotz.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Video slots",
                    "description": "",
                    "game_id": "vswayseternity",
                    "game_name": "Diamonds of Egypt",
                    "id": 145,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vswayseternity.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Video slots",
                    "description": "",
                    "game_id": "vs20clustwild",
                    "game_name": "Sticky Bees",
                    "id": 146,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vs20clustwild.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Video slots",
                    "description": "",
                    "game_id": "vs20mysteryst",
                    "game_name": "Country Farming",
                    "id": 147,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vs20mysteryst.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Video slots",
                    "description": "",
                    "game_id": "vs10fdrasbf",
                    "game_name": "Floating Dragon - Dragon Boat Festival",
                    "id": 148,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vs10fdrasbf.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Video slots",
                    "description": "",
                    "game_id": "vs9outlaw",
                    "game_name": "Pirates Pub",
                    "id": 149,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vs9outlaw.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Video slots",
                    "description": "",
                    "game_id": "vs20lampinf",
                    "game_name": "Lamp Of Infinity",
                    "id": 150,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vs20lampinf.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Video slots",
                    "description": "",
                    "game_id": "vs10gizagods",
                    "game_name": "Gods of Giza",
                    "id": 152,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vs10gizagods.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Video slots",
                    "description": "",
                    "game_id": "vswaysrsm",
                    "game_name": "Wild Celebrity Bus Megaways",
                    "id": 153,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vswaysrsm.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Video slots",
                    "description": "",
                    "game_id": "vs10jnmntzma",
                    "game_name": "Jane Hunter and the Mask of Montezuma",
                    "id": 154,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vs10jnmntzma.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Video slots",
                    "description": "",
                    "game_id": "vs20excalibur",
                    "game_name": "Excalibur Unleashed",
                    "id": 155,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vs20excalibur.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Video slots",
                    "description": "",
                    "game_id": "vswaysredqueen",
                    "game_name": "The Red Queen",
                    "id": 156,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vswaysredqueen.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Video slots",
                    "description": "",
                    "game_id": "vs20goldclust",
                    "game_name": "Rabbit Garden",
                    "id": 157,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vs20goldclust.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Video slots",
                    "description": "",
                    "game_id": "vs10kingofdth",
                    "game_name": "Kingdom of the Dead",
                    "id": 158,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vs10kingofdth.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Video slots",
                    "description": "",
                    "game_id": "vs10bbhas",
                    "game_name": "Big Bass - Hold & Spinner",
                    "id": 159,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vs10bbhas.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Video slots",
                    "description": "",
                    "game_id": "vswaysmonkey",
                    "game_name": "3 Dancing Monkeys",
                    "id": 160,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vswaysmonkey.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Video slots",
                    "description": "",
                    "game_id": "vs20hotzone",
                    "game_name": "African Elephant",
                    "id": 161,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vs20hotzone.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Video slots",
                    "description": "",
                    "game_id": "vs20sknights",
                    "game_name": "The Knight King",
                    "id": 162,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vs20sknights.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Video slots",
                    "description": "",
                    "game_id": "vswayswwriches",
                    "game_name": "Wild Wild Riches Megaways",
                    "id": 163,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vswayswwriches.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Video slots",
                    "description": "",
                    "game_id": "vs20mvwild",
                    "game_name": "Jasmine Dreams",
                    "id": 164,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vs20mvwild.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Video slots",
                    "description": "",
                    "game_id": "vs10powerlines",
                    "game_name": "Peak Power",
                    "id": 165,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vs10powerlines.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Video slots",
                    "description": "",
                    "game_id": "vs12tropicana",
                    "game_name": "Club Tropicana",
                    "id": 166,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vs12tropicana.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Video slots",
                    "description": "",
                    "game_id": "vswaysultrcoin",
                    "game_name": "Cowboy Coins",
                    "id": 167,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vswaysultrcoin.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Video slots",
                    "description": "",
                    "game_id": "vs20mochimon",
                    "game_name": "Mochimon",
                    "id": 168,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vs20mochimon.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Video slots",
                    "description": "",
                    "game_id": "vs20pistols",
                    "game_name": "Wild West Duels",
                    "id": 169,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vs20pistols.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Video slots",
                    "description": "",
                    "game_id": "vswaysmorient",
                    "game_name": "Mystery of the Orient",
                    "id": 170,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vswaysmorient.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Video slots",
                    "description": "",
                    "game_id": "vs20doghousemh",
                    "game_name": "The Dog House Multihold",
                    "id": 171,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vs20doghousemh.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Video slots",
                    "description": "",
                    "game_id": "vs20clspwrndg",
                    "game_name": "Sweet Powernudge",
                    "id": 172,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vs20clspwrndg.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Video slots",
                    "description": "",
                    "game_id": "vs20ltng",
                    "game_name": "Pinup Girls",
                    "id": 173,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vs20ltng.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Video slots",
                    "description": "",
                    "game_id": "vs20mammoth",
                    "game_name": "Mammoth Gold Megaways",
                    "id": 174,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vs20mammoth.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Video slots",
                    "description": "",
                    "game_id": "vs25spgldways",
                    "game_name": "Secret City Gold",
                    "id": 175,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vs25spgldways.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Video slots",
                    "description": "",
                    "game_id": "vs20drgbless",
                    "game_name": "Dragon Hero",
                    "id": 176,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vs20drgbless.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Video slots",
                    "description": "",
                    "game_id": "vswayswwhex",
                    "game_name": "Wild Wild Bananas",
                    "id": 177,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vswayswwhex.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Video slots",
                    "description": "",
                    "game_id": "vs20superlanche",
                    "game_name": "Monster Superlanche",
                    "id": 178,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vs20superlanche.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Video slots",
                    "description": "",
                    "game_id": "vs10fisheye",
                    "game_name": "Fish Eye",
                    "id": 179,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vs10fisheye.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Video slots",
                    "description": "",
                    "game_id": "vs25archer",
                    "game_name": "Fire Archer",
                    "id": 180,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vs25archer.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Video slots",
                    "description": "",
                    "game_id": "vswayspizza",
                    "game_name": "PIZZA PIZZA PIZZA",
                    "id": 181,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vswayspizza.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Video slots",
                    "description": "",
                    "game_id": "vs20lcount",
                    "game_name": "Gems of Serengeti",
                    "id": 182,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vs20lcount.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Video slots",
                    "description": "",
                    "game_id": "vswaysfuryodin",
                    "game_name": "Fury of Odin Megaways",
                    "id": 183,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vswaysfuryodin.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Video slots",
                    "description": "",
                    "game_id": "vs12bbbxmas",
                    "game_name": "Bigger Bass Blizzard - Christmas Catch",
                    "id": 184,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vs12bbbxmas.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Video slots",
                    "description": "",
                    "game_id": "vs20schristmas",
                    "game_name": "Starlight Christmas",
                    "id": 185,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vs20schristmas.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Video slots",
                    "description": "",
                    "game_id": "vs10snakeeyes",
                    "game_name": "Snakes & Ladders - Snake Eyes",
                    "id": 186,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vs10snakeeyes.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Video slots",
                    "description": "",
                    "game_id": "vs10crownfire",
                    "game_name": "Crown of Fire",
                    "id": 187,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vs10crownfire.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Video slots",
                    "description": "",
                    "game_id": "vs20dugems",
                    "game_name": "Hot Pepper",
                    "id": 188,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vs20dugems.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Video slots",
                    "description": "",
                    "game_id": "vs25rlbank",
                    "game_name": "Reel Banks",
                    "id": 189,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vs25rlbank.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Video slots",
                    "description": "",
                    "game_id": "vs20theights",
                    "game_name": "Towering Fortunes",
                    "id": 190,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vs20theights.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Video slots",
                    "description": "",
                    "game_id": "vs20sparta",
                    "game_name": "Shield Of Sparta",
                    "id": 191,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vs20sparta.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Video slots",
                    "description": "",
                    "game_id": "vs20kraken2",
                    "game_name": "Release the Kraken 2",
                    "id": 192,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vs20kraken2.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Video slots",
                    "description": "",
                    "game_id": "vs20porbs",
                    "game_name": "Santa's Great Gifts",
                    "id": 193,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vs20porbs.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Video slots",
                    "description": "",
                    "game_id": "vs25kfruit",
                    "game_name": "Aztec Blaze",
                    "id": 194,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vs25kfruit.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Video slots",
                    "description": "",
                    "game_id": "vswaysconcoll",
                    "game_name": "Firebird Spirit - Connect & Collect",
                    "id": 195,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vswaysconcoll.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Video slots",
                    "description": "",
                    "game_id": "vs20mtreasure",
                    "game_name": "Pirate Golden Age",
                    "id": 196,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vs20mtreasure.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Video slots",
                    "description": "",
                    "game_id": "vs20swordofares",
                    "game_name": "Sword of Ares",
                    "id": 197,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vs20swordofares.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Video slots",
                    "description": "",
                    "game_id": "vs10bbkir",
                    "game_name": "Big Bass Bonanza - Keeping it Reel",
                    "id": 198,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vs10bbkir.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Video slots",
                    "description": "",
                    "game_id": "vswaysstrwild",
                    "game_name": "Candy Stars",
                    "id": 199,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vswaysstrwild.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Video slots",
                    "description": "",
                    "game_id": "vs10tut",
                    "game_name": "Book Of Tut Respin",
                    "id": 200,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vs10tut.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Video slots",
                    "description": "",
                    "game_id": "vswaysfrywld",
                    "game_name": "Spin & Score Megaways",
                    "id": 201,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vswaysfrywld.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Video slots",
                    "description": "",
                    "game_id": "vswaysfltdrg",
                    "game_name": "Floating Dragon Hold & Spin Megaways",
                    "id": 202,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vswaysfltdrg.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Video slots",
                    "description": "",
                    "game_id": "vs20muertos",
                    "game_name": "Muertos Multiplier Megaways",
                    "id": 203,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vs20muertos.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Video slots",
                    "description": "",
                    "game_id": "vs20octobeer",
                    "game_name": "Octobeer Fortunes",
                    "id": 204,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vs20octobeer.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Video slots",
                    "description": "",
                    "game_id": "vs20mparty",
                    "game_name": "Wild Hop & Drop",
                    "id": 205,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vs20mparty.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Video slots",
                    "description": "",
                    "game_id": "vs5strh",
                    "game_name": "Striking Hot 5",
                    "id": 206,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vs5strh.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Video slots",
                    "description": "",
                    "game_id": "vs40mstrwild",
                    "game_name": "Happy Hooves",
                    "id": 207,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vs40mstrwild.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Video slots",
                    "description": "",
                    "game_id": "vswaysbook",
                    "game_name": "Book of Golden Sands",
                    "id": 208,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vswaysbook.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Video slots",
                    "description": "",
                    "game_id": "vs20underground",
                    "game_name": "Down the Rails",
                    "id": 209,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vs20underground.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Video slots",
                    "description": "",
                    "game_id": "vs1024gmayhem",
                    "game_name": "Gorilla Mayhem",
                    "id": 210,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vs1024gmayhem.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Video slots",
                    "description": "",
                    "game_id": "vs20trswild2",
                    "game_name": "Black Bull",
                    "id": 211,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vs20trswild2.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Video slots",
                    "description": "",
                    "game_id": "vs10mmm",
                    "game_name": "Magic Money Maze",
                    "id": 212,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vs10mmm.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Video slots",
                    "description": "",
                    "game_id": "vs40sh",
                    "game_name": "Shining Hot 40",
                    "id": 213,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vs40sh.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Video slots",
                    "description": "",
                    "game_id": "vs40hotburnx",
                    "game_name": "Hot To Burn Extreme",
                    "id": 214,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vs40hotburnx.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Video slots",
                    "description": "",
                    "game_id": "vs5sh",
                    "game_name": "Shining Hot 5",
                    "id": 215,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vs5sh.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Video slots",
                    "description": "",
                    "game_id": "vs20fh",
                    "game_name": "Fire Hot 20",
                    "id": 216,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vs20fh.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Video slots",
                    "description": "",
                    "game_id": "vs100firehot",
                    "game_name": "Fire Hot 100",
                    "id": 217,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vs100firehot.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Video slots",
                    "description": "",
                    "game_id": "vs20wolfie",
                    "game_name": "Greedy Wolf",
                    "id": 218,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vs20wolfie.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Video slots",
                    "description": "",
                    "game_id": "vs5firehot",
                    "game_name": "Fire Hot 5",
                    "id": 219,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vs5firehot.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Video slots",
                    "description": "",
                    "game_id": "vs40firehot",
                    "game_name": "Fire Hot 40",
                    "id": 220,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vs40firehot.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Video slots",
                    "description": "",
                    "game_id": "vswaysjkrdrop",
                    "game_name": "Tropical Tiki",
                    "id": 221,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vswaysjkrdrop.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Video slots",
                    "description": "",
                    "game_id": "vs20amuleteg",
                    "game_name": "Fortune of Giza",
                    "id": 222,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vs20amuleteg.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Video slots",
                    "description": "",
                    "game_id": "vs25bomb",
                    "game_name": "Bomb Bonanza",
                    "id": 223,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vs25bomb.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Video slots",
                    "description": "",
                    "game_id": "vs25copsrobbers",
                    "game_name": "Cash Patrol",
                    "id": 224,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vs25copsrobbers.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Video slots",
                    "description": "",
                    "game_id": "vs100sh",
                    "game_name": "Shining Hot 100",
                    "id": 225,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vs100sh.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Video slots",
                    "description": "",
                    "game_id": "vs20sugarrush",
                    "game_name": "Sugar Rush",
                    "id": 226,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vs20sugarrush.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Video slots",
                    "description": "",
                    "game_id": "vs10egrich",
                    "game_name": "Queen of Gods",
                    "id": 227,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vs10egrich.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Video slots",
                    "description": "",
                    "game_id": "vs20sh",
                    "game_name": "Shining Hot 20",
                    "id": 228,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vs20sh.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Video slots",
                    "description": "",
                    "game_id": "vs40cosmiccash",
                    "game_name": "Cosmic Cash",
                    "id": 229,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vs40cosmiccash.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Video slots",
                    "description": "",
                    "game_id": "vswayszombcarn",
                    "game_name": "Zombie Carnival",
                    "id": 230,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vswayszombcarn.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Video slots",
                    "description": "",
                    "game_id": "vs10txbigbass",
                    "game_name": "Big Bass Splash",
                    "id": 231,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vs10txbigbass.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Video slots",
                    "description": "",
                    "game_id": "vs20stickysymbol",
                    "game_name": "The Great Stick-up",
                    "id": 232,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vs20stickysymbol.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Video slots",
                    "description": "",
                    "game_id": "vs50northgard",
                    "game_name": "North Guardians",
                    "id": 233,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vs50northgard.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Video slots",
                    "description": "",
                    "game_id": "vs20cleocatra",
                    "game_name": "Cleocatra",
                    "id": 234,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vs20cleocatra.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Video slots",
                    "description": "",
                    "game_id": "vs10firestrike2",
                    "game_name": "Fire Strike 2",
                    "id": 235,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vs10firestrike2.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Video slots",
                    "description": "",
                    "game_id": "vs5littlegem",
                    "game_name": "Little Gem Hold and Spin",
                    "id": 236,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vs5littlegem.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Video slots",
                    "description": "",
                    "game_id": "vswayswildwest",
                    "game_name": "Wild West Gold Megaways",
                    "id": 237,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vswayswildwest.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Video slots",
                    "description": "",
                    "game_id": "vs20mustanggld2",
                    "game_name": "Clover Gold",
                    "id": 238,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vs20mustanggld2.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Video slots",
                    "description": "",
                    "game_id": "vs10spiritadv",
                    "game_name": "Spirit of Adventure",
                    "id": 239,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vs10spiritadv.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Video slots",
                    "description": "",
                    "game_id": "vs20gobnudge",
                    "game_name": "Goblin Heist Powernudge",
                    "id": 240,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vs20gobnudge.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Video slots",
                    "description": "",
                    "game_id": "vs40cleoeye",
                    "game_name": "Eye of Cleopatra",
                    "id": 241,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vs40cleoeye.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Video slots",
                    "description": "",
                    "game_id": "vs20bermuda",
                    "game_name": "Bermuda Riches",
                    "id": 242,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vs20bermuda.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Video slots",
                    "description": "",
                    "game_id": "vs1024lionsd",
                    "game_name": "5 Lions Dance",
                    "id": 243,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vs1024lionsd.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Video slots",
                    "description": "",
                    "game_id": "vs10chkchase",
                    "game_name": "Chicken Chase",
                    "id": 244,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vs10chkchase.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Video slots",
                    "description": "",
                    "game_id": "vs20drtgold",
                    "game_name": "Drill That Gold",
                    "id": 245,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vs20drtgold.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Video slots",
                    "description": "",
                    "game_id": "vswayslions",
                    "game_name": "5 Lions Megaways",
                    "id": 246,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vswayslions.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Video slots",
                    "description": "",
                    "game_id": "vs20farmfest",
                    "game_name": "Barn Festival",
                    "id": 247,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vs20farmfest.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Video slots",
                    "description": "",
                    "game_id": "vs20rainbowg",
                    "game_name": "Rainbow Gold",
                    "id": 248,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vs20rainbowg.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Video slots",
                    "description": "",
                    "game_id": "vs10tictac",
                    "game_name": "Tic Tac Take",
                    "id": 249,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vs10tictac.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Video slots",
                    "description": "",
                    "game_id": "vs10amm",
                    "game_name": "Amazing Money Machine",
                    "id": 250,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vs10amm.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Video slots",
                    "description": "",
                    "game_id": "vs10bbbonanza",
                    "game_name": "Big Bass Bonanza",
                    "id": 251,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vs10bbbonanza.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Video slots",
                    "description": "",
                    "game_id": "vs25bkofkngdm",
                    "game_name": "Book Of Kingdoms",
                    "id": 252,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vs25bkofkngdm.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Video slots",
                    "description": "",
                    "game_id": "vs10bookviking",
                    "game_name": "Book of Vikings",
                    "id": 253,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vs10bookviking.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Video slots",
                    "description": "",
                    "game_id": "vswaysbankbonz",
                    "game_name": "Cash Bonanza",
                    "id": 254,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vswaysbankbonz.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Video slots",
                    "description": "",
                    "game_id": "vs10bookfallen",
                    "game_name": "Book of Fallen",
                    "id": 255,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vs10bookfallen.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Video slots",
                    "description": "",
                    "game_id": "vs20terrorv",
                    "game_name": "Cash Elevator",
                    "id": 256,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vs20terrorv.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Video slots",
                    "description": "",
                    "game_id": "vs40bigjuan",
                    "game_name": "Big Juan",
                    "id": 257,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vs40bigjuan.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Video slots",
                    "description": "",
                    "game_id": "vs25btygold",
                    "game_name": "Bounty Gold",
                    "id": 258,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vs25btygold.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Video slots",
                    "description": "",
                    "game_id": "vswaysbbb",
                    "game_name": "Big Bass Bonanza Megaways",
                    "id": 259,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vswaysbbb.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Video slots",
                    "description": "",
                    "game_id": "vswaysbufking",
                    "game_name": "Buffalo King Megaways",
                    "id": 260,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vswaysbufking.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Video slots",
                    "description": "",
                    "game_id": "vs12bbb",
                    "game_name": "Bigger Bass Bonanza",
                    "id": 261,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vs12bbb.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Video slots",
                    "description": "",
                    "game_id": "vs10cowgold",
                    "game_name": "Cowboys Gold",
                    "id": 262,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vs10cowgold.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Video slots",
                    "description": "",
                    "game_id": "vswayscryscav",
                    "game_name": "Crystal Caverns Megaways",
                    "id": 263,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vswayscryscav.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Video slots",
                    "description": "",
                    "game_id": "vs20daydead",
                    "game_name": "Day of Dead",
                    "id": 264,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vs20daydead.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Video slots",
                    "description": "",
                    "game_id": "vs20xmascarol",
                    "game_name": "Christmas Carol Megaways",
                    "id": 265,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vs20xmascarol.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Video slots",
                    "description": "",
                    "game_id": "vs20chickdrop",
                    "game_name": "Chicken Drop",
                    "id": 266,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vs20chickdrop.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Video slots",
                    "description": "",
                    "game_id": "vs20colcashzone",
                    "game_name": "Colossal Cash Zone",
                    "id": 267,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vs20colcashzone.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Video slots",
                    "description": "",
                    "game_id": "vswayschilheat",
                    "game_name": "Chilli Heat Megaways",
                    "id": 268,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vswayschilheat.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Video slots",
                    "description": "",
                    "game_id": "vs5drhs",
                    "game_name": "Dragon Hot Hold & Spin",
                    "id": 269,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vs5drhs.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Video slots",
                    "description": "",
                    "game_id": "vs10bxmasbnza",
                    "game_name": "Christmas Big Bass Bonanza",
                    "id": 270,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vs10bxmasbnza.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Video slots",
                    "description": "",
                    "game_id": "vs432congocash",
                    "game_name": "Congo Cash",
                    "id": 271,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vs432congocash.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Video slots",
                    "description": "",
                    "game_id": "vswaysxjuicy",
                    "game_name": "Extra Juicy Megaways",
                    "id": 272,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vswaysxjuicy.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Video slots",
                    "description": "",
                    "game_id": "vs10eyestorm",
                    "game_name": "Eye of the Storm",
                    "id": 273,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vs10eyestorm.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  }
                ]
              },
              {
                "id": 21,
                "name": "Top Picks",
                "priority": 80,
                "status": 1,
                "games": [
                  {
                    "category": "Top Picks",
                    "description": "",
                    "game_id": "aviator",
                    "game_name": "Aviator",
                    "id": 674,
                    "image": "https://storage.googleapis.com/maybet/spribe/aviator.png",
                    "priority": 100,
                    "provider_id": 7,
                    "provider_name": "Spribe",
                    "status": 1
                  },
                  {
                    "category": "Top Picks",
                    "description": "",
                    "game_id": "rla",
                    "game_name": "Roulette",
                    "id": 641,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/rla.png",
                    "priority": 99,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Top Picks",
                    "description": "",
                    "game_id": "rocketman",
                    "game_name": "Rocket Man",
                    "id": 667,
                    "image": "https://storage.googleapis.com/maybet/elbet/rocketman.png",
                    "priority": 99,
                    "provider_id": 4,
                    "provider_name": "Elbet",
                    "status": 1
                  },
                  {
                    "category": "Top Picks",
                    "description": "",
                    "game_id": "JetX",
                    "game_name": "JetX",
                    "id": 27,
                    "image": "https://storage.googleapis.com/maybet/smartsoft/JetX.png",
                    "priority": 98,
                    "provider_id": 5,
                    "provider_name": "Smartsoft Gaming",
                    "status": 1
                  },
                  {
                    "category": "Top Picks",
                    "description": "",
                    "game_id": "vs20olympx",
                    "game_name": "Gates of Olympus 1000",
                    "id": 93,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vs20olympx.png",
                    "priority": 98,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Top Picks",
                    "description": "",
                    "game_id": "vs20sugarnudge",
                    "game_name": "Sugar Supreme Powernudge",
                    "id": 110,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vs20sugarnudge.png",
                    "priority": 98,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Top Picks",
                    "description": "",
                    "game_id": "vs20starlightx",
                    "game_name": "Starlight Princess 1000",
                    "id": 111,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vs20starlightx.png",
                    "priority": 98,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Top Picks",
                    "description": "",
                    "game_id": "vs20starlight",
                    "game_name": "Starlight Princess",
                    "id": 313,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vs20starlight.png",
                    "priority": 98,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Top Picks",
                    "description": "",
                    "game_id": "vs20rhinoluxe",
                    "game_name": "Great Rhino Deluxe",
                    "id": 335,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vs20rhinoluxe.png",
                    "priority": 98,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Top Picks",
                    "description": "",
                    "game_id": "vs15godsofwar",
                    "game_name": "Zeus vs Hades - Gods of War",
                    "id": 144,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vs15godsofwar.png",
                    "priority": 97,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Top Picks",
                    "description": "",
                    "game_id": "1",
                    "game_name": "Comet",
                    "id": 1,
                    "image": "https://stgameswesteu.blob.core.windows.net/strapi-uploads/assets/gameicon_comet_crash_x3_3fac109cdf.png",
                    "priority": 95,
                    "provider_id": 3,
                    "provider_name": "STP",
                    "status": 1
                  },
                  {
                    "category": "Top Picks",
                    "description": "",
                    "game_id": "JetX3",
                    "game_name": "JetX3",
                    "id": 38,
                    "image": "https://storage.googleapis.com/maybet/smartsoft/JetX3.png",
                    "priority": 90,
                    "provider_id": 5,
                    "provider_name": "Smartsoft Gaming",
                    "status": 1
                  },
                  {
                    "category": "Top Picks",
                    "description": "",
                    "game_id": "vs9aztecgemsdx",
                    "game_name": "Aztec Gems Deluxe",
                    "id": 337,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vs9aztecgemsdx.png",
                    "priority": 90,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Top Picks",
                    "description": "",
                    "game_id": "FootballX",
                    "game_name": "FootballX",
                    "id": 31,
                    "image": "https://storage.googleapis.com/maybet/smartsoft/FootballX.png",
                    "priority": 56,
                    "provider_id": 5,
                    "provider_name": "Smartsoft Gaming",
                    "status": 1
                  },
                  {
                    "category": "Top Picks",
                    "description": "",
                    "game_id": "vs20gravity",
                    "game_name": "Gravity Bonanza",
                    "id": 113,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vs20gravity.png",
                    "priority": 2,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Top Picks",
                    "description": "",
                    "game_id": "13",
                    "game_name": "Rock Paper Scissors",
                    "id": 13,
                    "image": "https://stgameswesteu.blob.core.windows.net/strapi-uploads/assets/gameicon_rps_x3_7bb476c3cb.png",
                    "priority": 1,
                    "provider_id": 3,
                    "provider_name": "STP",
                    "status": 1
                  },
                  {
                    "category": "Top Picks",
                    "description": "",
                    "game_id": "CrazyHuntX",
                    "game_name": "Crazy HuntX",
                    "id": 17,
                    "image": "https://storage.googleapis.com/maybet/smartsoft/CrazyHuntX.png",
                    "priority": 1,
                    "provider_id": 5,
                    "provider_name": "Smartsoft Gaming",
                    "status": 1
                  },
                  {
                    "category": "Top Picks",
                    "description": "",
                    "game_id": "vs20sugarrushx",
                    "game_name": "Sugar Rush 1000",
                    "id": 79,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vs20sugarrushx.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  }
                ]
              },
              {
                "id": 4,
                "name": "Slots",
                "priority": 80,
                "status": 1,
                "games": [
                  {
                    "category": "Slots",
                    "description": "",
                    "game_id": "vs20olympx",
                    "game_name": "Gates of Olympus 1000",
                    "id": 93,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vs20olympx.png",
                    "priority": 98,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Slots",
                    "description": "",
                    "game_id": "vs20sugarnudge",
                    "game_name": "Sugar Supreme Powernudge",
                    "id": 110,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vs20sugarnudge.png",
                    "priority": 98,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Slots",
                    "description": "",
                    "game_id": "vs20starlightx",
                    "game_name": "Starlight Princess 1000",
                    "id": 111,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vs20starlightx.png",
                    "priority": 98,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Slots",
                    "description": "",
                    "game_id": "vs20starlight",
                    "game_name": "Starlight Princess",
                    "id": 313,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vs20starlight.png",
                    "priority": 98,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Slots",
                    "description": "",
                    "game_id": "vs20rhinoluxe",
                    "game_name": "Great Rhino Deluxe",
                    "id": 335,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vs20rhinoluxe.png",
                    "priority": 98,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Slots",
                    "description": "",
                    "game_id": "vs20gravity",
                    "game_name": "Gravity Bonanza",
                    "id": 113,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vs20gravity.png",
                    "priority": 2,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Slots",
                    "description": "",
                    "game_id": "CrazyApe",
                    "game_name": "Crazy Ape",
                    "id": 15,
                    "image": "https://storage.googleapis.com/maybet/smartsoft/CrazyApe.png",
                    "priority": 1,
                    "provider_id": 5,
                    "provider_name": "Smartsoft Gaming",
                    "status": 1
                  },
                  {
                    "category": "Slots",
                    "description": "",
                    "game_id": "Viking",
                    "game_name": "Viking",
                    "id": 19,
                    "image": "https://storage.googleapis.com/maybet/smartsoft/Viking.png",
                    "priority": 1,
                    "provider_id": 5,
                    "provider_name": "Smartsoft Gaming",
                    "status": 1
                  },
                  {
                    "category": "Slots",
                    "description": "",
                    "game_id": "TheKingdomOfTheElves",
                    "game_name": "The Kingdom of The Elves",
                    "id": 20,
                    "image": "https://storage.googleapis.com/maybet/smartsoft/TheKingdomOfTheElves.png",
                    "priority": 1,
                    "provider_id": 5,
                    "provider_name": "Smartsoft Gaming",
                    "status": 1
                  },
                  {
                    "category": "Slots",
                    "description": "",
                    "game_id": "Babylon",
                    "game_name": "Babylon",
                    "id": 21,
                    "image": "https://storage.googleapis.com/maybet/smartsoft/Babylon.png",
                    "priority": 1,
                    "provider_id": 5,
                    "provider_name": "Smartsoft Gaming",
                    "status": 1
                  },
                  {
                    "category": "Slots",
                    "description": "",
                    "game_id": "MagicGarden10",
                    "game_name": "Magic Garden 10",
                    "id": 24,
                    "image": "https://storage.googleapis.com/maybet/smartsoft/MagicGarden10.png",
                    "priority": 1,
                    "provider_id": 5,
                    "provider_name": "Smartsoft Gaming",
                    "status": 1
                  },
                  {
                    "category": "Slots",
                    "description": "",
                    "game_id": "Samurai",
                    "game_name": "Samurai",
                    "id": 25,
                    "image": "https://storage.googleapis.com/maybet/smartsoft/Samurai.png",
                    "priority": 1,
                    "provider_id": 5,
                    "provider_name": "Smartsoft Gaming",
                    "status": 1
                  },
                  {
                    "category": "Slots",
                    "description": "",
                    "game_id": "BookOfWin",
                    "game_name": "Book of Win",
                    "id": 29,
                    "image": "https://storage.googleapis.com/maybet/smartsoft/BookOfWin.png",
                    "priority": 1,
                    "provider_id": 5,
                    "provider_name": "Smartsoft Gaming",
                    "status": 1
                  },
                  {
                    "category": "Slots",
                    "description": "",
                    "game_id": "Dark",
                    "game_name": "Dark",
                    "id": 30,
                    "image": "https://storage.googleapis.com/maybet/smartsoft/Dark.png",
                    "priority": 1,
                    "provider_id": 5,
                    "provider_name": "Smartsoft Gaming",
                    "status": 1
                  },
                  {
                    "category": "Slots",
                    "description": "",
                    "game_id": "WorldWarII",
                    "game_name": "World War II",
                    "id": 34,
                    "image": "https://storage.googleapis.com/maybet/smartsoft/WorldWarII.png",
                    "priority": 1,
                    "provider_id": 5,
                    "provider_name": "Smartsoft Gaming",
                    "status": 1
                  },
                  {
                    "category": "Slots",
                    "description": "",
                    "game_id": "Vampires",
                    "game_name": "Vampires",
                    "id": 35,
                    "image": "https://storage.googleapis.com/maybet/smartsoft/Vampires.png",
                    "priority": 1,
                    "provider_id": 5,
                    "provider_name": "Smartsoft Gaming",
                    "status": 1
                  },
                  {
                    "category": "Slots",
                    "description": "",
                    "game_id": "WildsAndGods",
                    "game_name": "WildsAndGods",
                    "id": 36,
                    "image": "https://storage.googleapis.com/maybet/smartsoft/WildsAndGods.png",
                    "priority": 1,
                    "provider_id": 5,
                    "provider_name": "Smartsoft Gaming",
                    "status": 1
                  },
                  {
                    "category": "Slots",
                    "description": "",
                    "game_id": "SummerCocktail",
                    "game_name": "Summer Cocktail",
                    "id": 41,
                    "image": "https://storage.googleapis.com/maybet/smartsoft/SummerCocktail.png",
                    "priority": 1,
                    "provider_id": 5,
                    "provider_name": "Smartsoft Gaming",
                    "status": 1
                  },
                  {
                    "category": "Slots",
                    "description": "",
                    "game_id": "Dark100",
                    "game_name": "Dark 100",
                    "id": 42,
                    "image": "https://storage.googleapis.com/maybet/smartsoft/Dark100.png",
                    "priority": 1,
                    "provider_id": 5,
                    "provider_name": "Smartsoft Gaming",
                    "status": 1
                  },
                  {
                    "category": "Slots",
                    "description": "",
                    "game_id": "Evolution",
                    "game_name": "Evolution",
                    "id": 47,
                    "image": "https://storage.googleapis.com/maybet/smartsoft/Evolution.png",
                    "priority": 1,
                    "provider_id": 5,
                    "provider_name": "Smartsoft Gaming",
                    "status": 1
                  },
                  {
                    "category": "Slots",
                    "description": "",
                    "game_id": "Zombies",
                    "game_name": "Zombies",
                    "id": 48,
                    "image": "https://storage.googleapis.com/maybet/smartsoft/Zombies.png",
                    "priority": 1,
                    "provider_id": 5,
                    "provider_name": "Smartsoft Gaming",
                    "status": 1
                  },
                  {
                    "category": "Slots",
                    "description": "",
                    "game_id": "WildJungle",
                    "game_name": "Wild Jungle",
                    "id": 49,
                    "image": "https://storage.googleapis.com/maybet/smartsoft/WildJungle.png",
                    "priority": 1,
                    "provider_id": 5,
                    "provider_name": "Smartsoft Gaming",
                    "status": 1
                  },
                  {
                    "category": "Slots",
                    "description": "",
                    "game_id": "SweetCocktail",
                    "game_name": "Sweet Cocktail",
                    "id": 50,
                    "image": "https://storage.googleapis.com/maybet/smartsoft/SweetCocktail.png",
                    "priority": 1,
                    "provider_id": 5,
                    "provider_name": "Smartsoft Gaming",
                    "status": 1
                  },
                  {
                    "category": "Slots",
                    "description": "",
                    "game_id": "MagicGarden40",
                    "game_name": "Magic Garden 40",
                    "id": 52,
                    "image": "https://storage.googleapis.com/maybet/smartsoft/MagicGarden40.png",
                    "priority": 1,
                    "provider_id": 5,
                    "provider_name": "Smartsoft Gaming",
                    "status": 1
                  },
                  {
                    "category": "Slots",
                    "description": "",
                    "game_id": "BurningIce",
                    "game_name": "Burning Ice",
                    "id": 54,
                    "image": "https://storage.googleapis.com/maybet/smartsoft/BurningIce.png",
                    "priority": 1,
                    "provider_id": 5,
                    "provider_name": "Smartsoft Gaming",
                    "status": 1
                  },
                  {
                    "category": "Slots",
                    "description": "",
                    "game_id": "BurningIce10",
                    "game_name": "Burning Ice 10",
                    "id": 56,
                    "image": "https://storage.googleapis.com/maybet/smartsoft/BurningIce10.png",
                    "priority": 1,
                    "provider_id": 5,
                    "provider_name": "Smartsoft Gaming",
                    "status": 1
                  },
                  {
                    "category": "Slots",
                    "description": "",
                    "game_id": "BurningIce40",
                    "game_name": "Burning Ice 40",
                    "id": 58,
                    "image": "https://storage.googleapis.com/maybet/smartsoft/BurningIce40.png",
                    "priority": 1,
                    "provider_id": 5,
                    "provider_name": "Smartsoft Gaming",
                    "status": 1
                  },
                  {
                    "category": "Slots",
                    "description": "",
                    "game_id": "GeniesBonanza",
                    "game_name": "Genie's Bonanza",
                    "id": 59,
                    "image": "https://storage.googleapis.com/maybet/smartsoft/GeniesBonanza.png",
                    "priority": 1,
                    "provider_id": 5,
                    "provider_name": "Smartsoft Gaming",
                    "status": 1
                  },
                  {
                    "category": "Slots",
                    "description": "",
                    "game_id": "MultiHot5",
                    "game_name": "Multi Hot 5",
                    "id": 62,
                    "image": "https://storage.googleapis.com/maybet/smartsoft/MultiHot5.png",
                    "priority": 1,
                    "provider_id": 5,
                    "provider_name": "Smartsoft Gaming",
                    "status": 1
                  },
                  {
                    "category": "Slots",
                    "description": "",
                    "game_id": "Aztec",
                    "game_name": "Aztec",
                    "id": 63,
                    "image": "https://storage.googleapis.com/maybet/smartsoft/Aztec.png",
                    "priority": 1,
                    "provider_id": 5,
                    "provider_name": "Smartsoft Gaming",
                    "status": 1
                  },
                  {
                    "category": "Slots",
                    "description": "",
                    "game_id": "MoonStone",
                    "game_name": "MoonStone",
                    "id": 64,
                    "image": "https://storage.googleapis.com/maybet/smartsoft/MoonStone.png",
                    "priority": 1,
                    "provider_id": 5,
                    "provider_name": "Smartsoft Gaming",
                    "status": 1
                  },
                  {
                    "category": "Slots",
                    "description": "",
                    "game_id": "MultiHotWays",
                    "game_name": "Multi Hot Ways",
                    "id": 65,
                    "image": "https://storage.googleapis.com/maybet/smartsoft/MultiHotWays.png",
                    "priority": 1,
                    "provider_id": 5,
                    "provider_name": "Smartsoft Gaming",
                    "status": 1
                  },
                  {
                    "category": "Slots",
                    "description": "",
                    "game_id": "Cowboy",
                    "game_name": "Cowboy",
                    "id": 67,
                    "image": "https://storage.googleapis.com/maybet/smartsoft/Cowboy.png",
                    "priority": 1,
                    "provider_id": 5,
                    "provider_name": "Smartsoft Gaming",
                    "status": 1
                  },
                  {
                    "category": "Slots",
                    "description": "",
                    "game_id": "Apollo",
                    "game_name": "Apollo",
                    "id": 68,
                    "image": "https://storage.googleapis.com/maybet/smartsoft/Apollo.png",
                    "priority": 1,
                    "provider_id": 5,
                    "provider_name": "Smartsoft Gaming",
                    "status": 1
                  },
                  {
                    "category": "Slots",
                    "description": "",
                    "game_id": "MagicGarden",
                    "game_name": "Magic Garden",
                    "id": 69,
                    "image": "https://storage.googleapis.com/maybet/smartsoft/MagicGarden.png",
                    "priority": 1,
                    "provider_id": 5,
                    "provider_name": "Smartsoft Gaming",
                    "status": 1
                  },
                  {
                    "category": "Slots",
                    "description": "",
                    "game_id": "ParaDice10",
                    "game_name": "Para Dice 10",
                    "id": 70,
                    "image": "https://storage.googleapis.com/maybet/smartsoft/ParaDice10.png",
                    "priority": 1,
                    "provider_id": 5,
                    "provider_name": "Smartsoft Gaming",
                    "status": 1
                  },
                  {
                    "category": "Slots",
                    "description": "",
                    "game_id": "vs10bbbnz",
                    "game_name": "Big Bass Day at the Races",
                    "id": 74,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vs10bbbnz.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Slots",
                    "description": "",
                    "game_id": "vs20clustext",
                    "game_name": "Gears of Horus",
                    "id": 76,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vs20clustext.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Slots",
                    "description": "",
                    "game_id": "vswaysmegareel",
                    "game_name": "Pompeii Megareels Megaways",
                    "id": 77,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vswaysmegareel.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Slots",
                    "description": "",
                    "game_id": "vswaysalterego",
                    "game_name": "The Alter Ego",
                    "id": 78,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vswaysalterego.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Slots",
                    "description": "",
                    "game_id": "vs20sugarrushx",
                    "game_name": "Sugar Rush 1000",
                    "id": 79,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vs20sugarrushx.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Slots",
                    "description": "",
                    "game_id": "vs10bbfloats",
                    "game_name": "Big Bass Floats my Boat",
                    "id": 80,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vs10bbfloats.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Slots",
                    "description": "",
                    "game_id": "vs20powerpays",
                    "game_name": "Red Hot Luck",
                    "id": 81,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vs20powerpays.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Slots",
                    "description": "",
                    "game_id": "vs10luckfort",
                    "game_name": "Good Luck & Good Fortune",
                    "id": 82,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vs10luckfort.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Slots",
                    "description": "",
                    "game_id": "vs20yotdk",
                    "game_name": "Year Of The Dragon King",
                    "id": 83,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vs20yotdk.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Slots",
                    "description": "",
                    "game_id": "vs20bigdawgs",
                    "game_name": "The Big Dawgs",
                    "id": 84,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vs20bigdawgs.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Slots",
                    "description": "",
                    "game_id": "vswaysexpandng",
                    "game_name": "Castle of Fire",
                    "id": 85,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vswaysexpandng.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Slots",
                    "description": "",
                    "game_id": "vs20loksriches",
                    "game_name": "Loki's Riches",
                    "id": 86,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vs20loksriches.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Slots",
                    "description": "",
                    "game_id": "vs10strawberry",
                    "game_name": "Strawberry Cocktail",
                    "id": 87,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vs10strawberry.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Slots",
                    "description": "",
                    "game_id": "vswaysfirewmw",
                    "game_name": "Blazing Wilds Megaways",
                    "id": 88,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vswaysfirewmw.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Slots",
                    "description": "",
                    "game_id": "vs20multiup",
                    "game_name": "Wheel O'Gold",
                    "id": 90,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vs20multiup.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Slots",
                    "description": "",
                    "game_id": "vs20cjcluster",
                    "game_name": "Candy Jar Clusters",
                    "id": 96,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vs20cjcluster.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Slots",
                    "description": "",
                    "game_id": "vswaysstampede",
                    "game_name": "Fire Stampede",
                    "id": 99,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vswaysstampede.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Slots",
                    "description": "",
                    "game_id": "vs10bbsplxmas",
                    "game_name": "Big Bass Christmas Bash",
                    "id": 102,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vs10bbsplxmas.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Slots",
                    "description": "",
                    "game_id": "vs20sugrux",
                    "game_name": "Sugar Rush Xmas",
                    "id": 104,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vs20sugrux.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Slots",
                    "description": "",
                    "game_id": "vs20maskgame",
                    "game_name": "Cash Chips",
                    "id": 108,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vs20maskgame.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Slots",
                    "description": "",
                    "game_id": "vs10gdchalleng",
                    "game_name": "8 Golden Dragon Challenge",
                    "id": 115,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vs10gdchalleng.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Slots",
                    "description": "",
                    "game_id": "vs20dhcluster",
                    "game_name": "Twilight Princess",
                    "id": 116,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vs20dhcluster.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Slots",
                    "description": "",
                    "game_id": "vswaysstrlght",
                    "game_name": "Fortunes of Aztec",
                    "id": 117,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vswaysstrlght.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Slots",
                    "description": "",
                    "game_id": "vs10bhallbnza",
                    "game_name": "Big Bass Halloween",
                    "id": 119,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vs10bhallbnza.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  }
                ]
              },
              {
                "id": 9,
                "name": "Live games",
                "priority": 70,
                "status": 1,
                "games": [
                  {
                    "category": "Live games",
                    "description": "",
                    "game_id": "302",
                    "game_name": "Blackjack 12",
                    "id": 369,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/302.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Live games",
                    "description": "",
                    "game_id": "1024",
                    "game_name": "Andar Bahar",
                    "id": 392,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/1024.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Live games",
                    "description": "",
                    "game_id": "114",
                    "game_name": "Asian Games",
                    "id": 393,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/114.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Live games",
                    "description": "",
                    "game_id": "426",
                    "game_name": "Baccarat 8",
                    "id": 402,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/426.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Live games",
                    "description": "",
                    "game_id": "436",
                    "game_name": "Baccarat 9",
                    "id": 403,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/436.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Live games",
                    "description": "",
                    "game_id": "425",
                    "game_name": "Baccarat 7",
                    "id": 404,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/425.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Live games",
                    "description": "",
                    "game_id": "515",
                    "game_name": "Blackjack 1 - Azure",
                    "id": 405,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/515.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Live games",
                    "description": "",
                    "game_id": "1320",
                    "game_name": "Big Bass Crash",
                    "id": 406,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/1320.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Live games",
                    "description": "",
                    "game_id": "225",
                    "game_name": "Auto Roulette",
                    "id": 407,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/225.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Live games",
                    "description": "",
                    "game_id": "104",
                    "game_name": "Baccarat Lobby",
                    "id": 408,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/104.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Live games",
                    "description": "",
                    "game_id": "422",
                    "game_name": "Baccarat 3",
                    "id": 409,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/422.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Live games",
                    "description": "",
                    "game_id": "413",
                    "game_name": "Baccarat 6",
                    "id": 410,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/413.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Live games",
                    "description": "",
                    "game_id": "411",
                    "game_name": "Baccarat 5",
                    "id": 411,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/411.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Live games",
                    "description": "",
                    "game_id": "525",
                    "game_name": "Blackjack 21 - Azure",
                    "id": 412,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/525.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Live games",
                    "description": "",
                    "game_id": "303",
                    "game_name": "Blackjack 14",
                    "id": 413,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/303.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Live games",
                    "description": "",
                    "game_id": "521",
                    "game_name": "Blackjack 17 - Azure",
                    "id": 414,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/521.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Live games",
                    "description": "",
                    "game_id": "524",
                    "game_name": "Blackjack 20 - Azure",
                    "id": 415,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/524.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Live games",
                    "description": "",
                    "game_id": "519",
                    "game_name": "Blackjack 2 - Azure",
                    "id": 416,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/519.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Live games",
                    "description": "",
                    "game_id": "305",
                    "game_name": "Blackjack 16",
                    "id": 417,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/305.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Live games",
                    "description": "",
                    "game_id": "522",
                    "game_name": "Blackjack 18 - Azure",
                    "id": 418,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/522.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Live games",
                    "description": "",
                    "game_id": "526",
                    "game_name": "Blackjack 22 - Azure",
                    "id": 419,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/526.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Live games",
                    "description": "",
                    "game_id": "517",
                    "game_name": "Blackjack 10 - Azure",
                    "id": 420,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/517.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Live games",
                    "description": "",
                    "game_id": "523",
                    "game_name": "Blackjack 19 - Azure",
                    "id": 421,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/523.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Live games",
                    "description": "",
                    "game_id": "536",
                    "game_name": "Blackjack 30 - Azure",
                    "id": 422,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/536.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Live games",
                    "description": "",
                    "game_id": "539",
                    "game_name": "Blackjack 27 - Azure",
                    "id": 423,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/539.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Live games",
                    "description": "",
                    "game_id": "530",
                    "game_name": "Blackjack 26 - Azure",
                    "id": 424,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/530.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Live games",
                    "description": "",
                    "game_id": "529",
                    "game_name": "Blackjack 25 - Azure",
                    "id": 425,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/529.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Live games",
                    "description": "",
                    "game_id": "535",
                    "game_name": "Blackjack 31 - Azure",
                    "id": 426,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/535.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Live games",
                    "description": "",
                    "game_id": "538",
                    "game_name": "Blackjack 28 - Azure",
                    "id": 427,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/538.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Live games",
                    "description": "",
                    "game_id": "511",
                    "game_name": "Blackjack 3 - Azure",
                    "id": 428,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/511.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Live games",
                    "description": "",
                    "game_id": "527",
                    "game_name": "Blackjack 23 - Azure",
                    "id": 429,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/527.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Live games",
                    "description": "",
                    "game_id": "537",
                    "game_name": "Blackjack 29 - Azure",
                    "id": 430,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/537.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Live games",
                    "description": "",
                    "game_id": "528",
                    "game_name": "Blackjack 24 - Azure",
                    "id": 431,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/528.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Live games",
                    "description": "",
                    "game_id": "564",
                    "game_name": "Blackjack 39 - Ruby",
                    "id": 432,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/564.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Live games",
                    "description": "",
                    "game_id": "514",
                    "game_name": "Blackjack 4 - Azure",
                    "id": 433,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/514.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Live games",
                    "description": "",
                    "game_id": "563",
                    "game_name": "Blackjack 38 - Ruby",
                    "id": 434,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/563.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Live games",
                    "description": "",
                    "game_id": "565",
                    "game_name": "Blackjack 40 - Ruby",
                    "id": 435,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/565.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Live games",
                    "description": "",
                    "game_id": "562",
                    "game_name": "Blackjack 37 - Ruby",
                    "id": 436,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/562.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Live games",
                    "description": "",
                    "game_id": "543",
                    "game_name": "Blackjack 35 - The Club",
                    "id": 437,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/543.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Live games",
                    "description": "",
                    "game_id": "540",
                    "game_name": "Blackjack 32 - Azure",
                    "id": 438,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/540.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Live games",
                    "description": "",
                    "game_id": "542",
                    "game_name": "Blackjack 34 - The Club",
                    "id": 439,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/542.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Live games",
                    "description": "",
                    "game_id": "544",
                    "game_name": "Blackjack 36 - The Club",
                    "id": 440,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/544.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Live games",
                    "description": "",
                    "game_id": "541",
                    "game_name": "Blackjack 33 - The Club",
                    "id": 441,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/541.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Live games",
                    "description": "",
                    "game_id": "558",
                    "game_name": "Blackjack 44 - Ruby",
                    "id": 442,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/558.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Live games",
                    "description": "",
                    "game_id": "552",
                    "game_name": "Blackjack 49 - Ruby",
                    "id": 443,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/552.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Live games",
                    "description": "",
                    "game_id": "551",
                    "game_name": "Blackjack 48 - Ruby",
                    "id": 444,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/551.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Live games",
                    "description": "",
                    "game_id": "557",
                    "game_name": "Blackjack 43 - Ruby",
                    "id": 445,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/557.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Live games",
                    "description": "",
                    "game_id": "559",
                    "game_name": "Blackjack 45 - Ruby",
                    "id": 446,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/559.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Live games",
                    "description": "",
                    "game_id": "560",
                    "game_name": "Blackjack 46 - Ruby",
                    "id": 447,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/560.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Live games",
                    "description": "",
                    "game_id": "555",
                    "game_name": "Blackjack 41 - Ruby",
                    "id": 448,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/555.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Live games",
                    "description": "",
                    "game_id": "518",
                    "game_name": "Blackjack 5 - Azure",
                    "id": 449,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/518.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Live games",
                    "description": "",
                    "game_id": "556",
                    "game_name": "Blackjack 42 - Ruby",
                    "id": 450,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/556.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Live games",
                    "description": "",
                    "game_id": "561",
                    "game_name": "Blackjack 47 - Ruby",
                    "id": 451,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/561.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Live games",
                    "description": "",
                    "game_id": "599",
                    "game_name": "Blackjack 58 - Azure",
                    "id": 452,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/599.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Live games",
                    "description": "",
                    "game_id": "595",
                    "game_name": "Blackjack 53 - Ruby",
                    "id": 453,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/595.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Live games",
                    "description": "",
                    "game_id": "593",
                    "game_name": "Blackjack 56 - Ruby",
                    "id": 454,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/593.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Live games",
                    "description": "",
                    "game_id": "598",
                    "game_name": "Blackjack 57 - Azure",
                    "id": 455,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/598.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Live games",
                    "description": "",
                    "game_id": "597",
                    "game_name": "Blackjack 55 - Ruby",
                    "id": 456,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/597.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Live games",
                    "description": "",
                    "game_id": "594",
                    "game_name": "Blackjack 52 - Ruby",
                    "id": 457,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/594.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Live games",
                    "description": "",
                    "game_id": "553",
                    "game_name": "Blackjack 50 - Ruby",
                    "id": 458,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/553.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Live games",
                    "description": "",
                    "game_id": "596",
                    "game_name": "Blackjack 54 - Ruby",
                    "id": 459,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/596.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Live games",
                    "description": "",
                    "game_id": "600",
                    "game_name": "Blackjack 59 - Azure",
                    "id": 460,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/600.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Live games",
                    "description": "",
                    "game_id": "554",
                    "game_name": "Blackjack 51 - Ruby",
                    "id": 461,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/554.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Live games",
                    "description": "",
                    "game_id": "626",
                    "game_name": "Blackjack 70 - Ruby",
                    "id": 462,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/626.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Live games",
                    "description": "",
                    "game_id": "621",
                    "game_name": "Blackjack 65 - Ruby",
                    "id": 463,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/621.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Live games",
                    "description": "",
                    "game_id": "603",
                    "game_name": "Blackjack 62 - Azure",
                    "id": 464,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/603.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Live games",
                    "description": "",
                    "game_id": "513",
                    "game_name": "Blackjack 7 - Azure",
                    "id": 465,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/513.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Live games",
                    "description": "",
                    "game_id": "590",
                    "game_name": "Blackjack 63 - Azure",
                    "id": 466,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/590.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Live games",
                    "description": "",
                    "game_id": "616",
                    "game_name": "Blackjack 71 - Ruby",
                    "id": 467,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/616.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Live games",
                    "description": "",
                    "game_id": "512",
                    "game_name": "Blackjack 6 - Azure",
                    "id": 468,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/512.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Live games",
                    "description": "",
                    "game_id": "620",
                    "game_name": "Blackjack 64 - Ruby",
                    "id": 469,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/620.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Live games",
                    "description": "",
                    "game_id": "617",
                    "game_name": "Blackjack 72 - Ruby",
                    "id": 470,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/617.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Live games",
                    "description": "",
                    "game_id": "602",
                    "game_name": "Blackjack 61 - Azure",
                    "id": 471,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/602.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Live games",
                    "description": "",
                    "game_id": "103",
                    "game_name": "Blackjack Lobby",
                    "id": 472,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/103.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Live games",
                    "description": "",
                    "game_id": "516",
                    "game_name": "Blackjack 9 - Azure",
                    "id": 473,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/516.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Live games",
                    "description": "",
                    "game_id": "3010",
                    "game_name": "Blackjack X 10 - Ruby",
                    "id": 474,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/3010.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Live games",
                    "description": "",
                    "game_id": "731",
                    "game_name": "Blackjack 76 - Azure",
                    "id": 475,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/731.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Live games",
                    "description": "",
                    "game_id": "730",
                    "game_name": "Blackjack 75 - Azure",
                    "id": 476,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/730.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Live games",
                    "description": "",
                    "game_id": "3014",
                    "game_name": "Blackjack X 14 - Ruby",
                    "id": 477,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/3014.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Live games",
                    "description": "",
                    "game_id": "520",
                    "game_name": "Blackjack 8 - Azure",
                    "id": 478,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/520.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Live games",
                    "description": "",
                    "game_id": "3001",
                    "game_name": "Blackjack X 1 - Azure",
                    "id": 479,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/3001.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Live games",
                    "description": "",
                    "game_id": "619",
                    "game_name": "Blackjack 74 - Ruby",
                    "id": 480,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/619.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Live games",
                    "description": "",
                    "game_id": "618",
                    "game_name": "Blackjack 73 - Ruby",
                    "id": 481,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/618.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Live games",
                    "description": "",
                    "game_id": "3024",
                    "game_name": "Blackjack X 24 - Azure",
                    "id": 482,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/3024.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Live games",
                    "description": "",
                    "game_id": "3002",
                    "game_name": "Blackjack X 2 - Azure",
                    "id": 483,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/3002.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Live games",
                    "description": "",
                    "game_id": "3015",
                    "game_name": "Blackjack X 15 - Ruby",
                    "id": 484,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/3015.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Live games",
                    "description": "",
                    "game_id": "3016",
                    "game_name": "Blackjack X 16 - Azure",
                    "id": 485,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/3016.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Live games",
                    "description": "",
                    "game_id": "3021",
                    "game_name": "Blackjack X 21 - Ruby",
                    "id": 486,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/3021.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Live games",
                    "description": "",
                    "game_id": "3018",
                    "game_name": "Blackjack X 18 - Azure",
                    "id": 487,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/3018.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Live games",
                    "description": "",
                    "game_id": "3025",
                    "game_name": "Blackjack X 25 - Azure",
                    "id": 488,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/3025.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Live games",
                    "description": "",
                    "game_id": "3020",
                    "game_name": "Blackjack X 20 - Ruby",
                    "id": 489,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/3020.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Live games",
                    "description": "",
                    "game_id": "3017",
                    "game_name": "Blackjack X 17 - Azure",
                    "id": 490,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/3017.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Live games",
                    "description": "",
                    "game_id": "3019",
                    "game_name": "Blackjack X 19 - Ruby",
                    "id": 491,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/3019.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Live games",
                    "description": "",
                    "game_id": "3007",
                    "game_name": "Blackjack X 7 - Azure",
                    "id": 492,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/3007.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Live games",
                    "description": "",
                    "game_id": "3004",
                    "game_name": "Blackjack X 4 - Ruby",
                    "id": 493,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/3004.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Live games",
                    "description": "",
                    "game_id": "3005",
                    "game_name": "Blackjack X 5 - Ruby",
                    "id": 494,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/3005.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Live games",
                    "description": "",
                    "game_id": "1001",
                    "game_name": "Dragon Tiger",
                    "id": 495,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/1001.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Live games",
                    "description": "",
                    "game_id": "3008",
                    "game_name": "Blackjack X 8 - Ruby",
                    "id": 496,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/3008.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Live games",
                    "description": "",
                    "game_id": "110",
                    "game_name": "D&W",
                    "id": 497,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/110.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Live games",
                    "description": "",
                    "game_id": "3006",
                    "game_name": "Blackjack X 6 - Azure",
                    "id": 498,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/3006.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Live games",
                    "description": "",
                    "game_id": "3003",
                    "game_name": "Blackjack X 3 - Azure",
                    "id": 499,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/3003.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Live games",
                    "description": "",
                    "game_id": "1401",
                    "game_name": "Boom City",
                    "id": 500,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/1401.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Live games",
                    "description": "",
                    "game_id": "3009",
                    "game_name": "Blackjack X 9 - Ruby",
                    "id": 501,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/3009.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Live games",
                    "description": "",
                    "game_id": "434",
                    "game_name": "Fortune 6 Baccarat",
                    "id": 502,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/434.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Live games",
                    "description": "",
                    "game_id": "701",
                    "game_name": "Mega Sic Bo",
                    "id": 504,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/701.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Live games",
                    "description": "",
                    "game_id": "801",
                    "game_name": "Mega Wheel",
                    "id": 505,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/801.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Live games",
                    "description": "",
                    "game_id": "105",
                    "game_name": "Gameshows",
                    "id": 506,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/105.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Live games",
                    "description": "",
                    "game_id": "442",
                    "game_name": "Mega Baccarat",
                    "id": 508,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/442.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Live games",
                    "description": "",
                    "game_id": "222",
                    "game_name": "German Roulette",
                    "id": 510,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/222.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Live games",
                    "description": "",
                    "game_id": "101",
                    "game_name": "Live Casino Lobby",
                    "id": 511,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/101.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Live games",
                    "description": "",
                    "game_id": "723",
                    "game_name": "Privé Lounge 3",
                    "id": 513,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/723.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Live games",
                    "description": "",
                    "game_id": "111",
                    "game_name": "Other Promos",
                    "id": 514,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/111.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Live games",
                    "description": "",
                    "game_id": "240",
                    "game_name": "PowerUP Roulette",
                    "id": 515,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/240.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Live games",
                    "description": "",
                    "game_id": "721",
                    "game_name": "Privé Lounge 1",
                    "id": 516,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/721.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Live games",
                    "description": "",
                    "game_id": "123",
                    "game_name": "Power Ball",
                    "id": 517,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/123.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Live games",
                    "description": "",
                    "game_id": "902",
                    "game_name": "ONE Blackjack 2 - Ruby",
                    "id": 518,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/902.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Live games",
                    "description": "",
                    "game_id": "722",
                    "game_name": "Privé Lounge 2",
                    "id": 519,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/722.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Live games",
                    "description": "",
                    "game_id": "901",
                    "game_name": "ONE Blackjack",
                    "id": 520,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/901.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Live games",
                    "description": "",
                    "game_id": "724",
                    "game_name": "Privé Lounge 4",
                    "id": 521,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/724.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Live games",
                    "description": "",
                    "game_id": "226",
                    "game_name": "Speed Auto Roulette",
                    "id": 522,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/226.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Live games",
                    "description": "",
                    "game_id": "424",
                    "game_name": "Speed Baccarat 11",
                    "id": 523,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/424.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Live games",
                    "description": "",
                    "game_id": "230",
                    "game_name": "Roulette Ruby",
                    "id": 524,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/230.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Live games",
                    "description": "",
                    "game_id": "1301",
                    "game_name": "Spaceman",
                    "id": 525,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/1301.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Live games",
                    "description": "",
                    "game_id": "428",
                    "game_name": "Speed Baccarat 10",
                    "id": 526,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/428.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Live games",
                    "description": "",
                    "game_id": "227",
                    "game_name": "Roulette Azure",
                    "id": 527,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/227.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Live games",
                    "description": "",
                    "game_id": "102",
                    "game_name": "Roulette Lobby",
                    "id": 528,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/102.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Live games",
                    "description": "",
                    "game_id": "109",
                    "game_name": "Sic Bo & Dragon Tiger",
                    "id": 529,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/109.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Live games",
                    "description": "",
                    "game_id": "725",
                    "game_name": "Privé Lounge 5",
                    "id": 530,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/725.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Live games",
                    "description": "",
                    "game_id": "1601",
                    "game_name": "Snake & Ladders Live",
                    "id": 531,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/1601.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Live games",
                    "description": "",
                    "game_id": "438",
                    "game_name": "Speed Baccarat 13",
                    "id": 532,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/438.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Live games",
                    "description": "",
                    "game_id": "439",
                    "game_name": "Speed Baccarat 17",
                    "id": 533,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/439.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Live games",
                    "description": "",
                    "game_id": "415",
                    "game_name": "Speed Baccarat 6",
                    "id": 534,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/415.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Live games",
                    "description": "",
                    "game_id": "421",
                    "game_name": "Speed Baccarat 12",
                    "id": 535,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/421.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Live games",
                    "description": "",
                    "game_id": "435",
                    "game_name": "Speed Baccarat 16",
                    "id": 536,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/435.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Live games",
                    "description": "",
                    "game_id": "403",
                    "game_name": "Speed Baccarat 2",
                    "id": 537,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/403.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Live games",
                    "description": "",
                    "game_id": "414",
                    "game_name": "Speed Baccarat 5",
                    "id": 538,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/414.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Live games",
                    "description": "",
                    "game_id": "431",
                    "game_name": "Speed Baccarat 7",
                    "id": 539,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/431.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Live games",
                    "description": "",
                    "game_id": "412",
                    "game_name": "Speed Baccarat 3",
                    "id": 540,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/412.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Live games",
                    "description": "",
                    "game_id": "427",
                    "game_name": "Speed Baccarat 15",
                    "id": 541,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/427.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Live games",
                    "description": "",
                    "game_id": "607",
                    "game_name": "Speed Blackjack 11 - Azure",
                    "id": 542,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/607.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Live games",
                    "description": "",
                    "game_id": "612",
                    "game_name": "Speed Blackjack -15 Ruby",
                    "id": 543,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/612.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Live games",
                    "description": "",
                    "game_id": "606",
                    "game_name": "Speed Blackjack 10 - Ruby",
                    "id": 544,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/606.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Live games",
                    "description": "",
                    "game_id": "614",
                    "game_name": "Speed Blackjack -17 Ruby",
                    "id": 545,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/614.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Live games",
                    "description": "",
                    "game_id": "608",
                    "game_name": "Speed Blackjack 12 - Azure",
                    "id": 546,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/608.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Live games",
                    "description": "",
                    "game_id": "566",
                    "game_name": "Speed Blackjack 1 - Ruby",
                    "id": 547,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/566.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Live games",
                    "description": "",
                    "game_id": "613",
                    "game_name": "Speed Blackjack -16 Ruby",
                    "id": 548,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/613.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Live games",
                    "description": "",
                    "game_id": "432",
                    "game_name": "Speed Baccarat 8",
                    "id": 549,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/432.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Live games",
                    "description": "",
                    "game_id": "430",
                    "game_name": "Speed Baccarat 9",
                    "id": 550,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/430.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Live games",
                    "description": "",
                    "game_id": "615",
                    "game_name": "Speed Blackjack -18 Ruby",
                    "id": 551,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/615.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Live games",
                    "description": "",
                    "game_id": "661",
                    "game_name": "Speed Blackjack 22 - Emerald",
                    "id": 552,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/661.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Live games",
                    "description": "",
                    "game_id": "643",
                    "game_name": "Speed Blackjack 26 - Emerald",
                    "id": 553,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/643.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Live games",
                    "description": "",
                    "game_id": "658",
                    "game_name": "Speed Blackjack 19 - Emerald",
                    "id": 554,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/658.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Live games",
                    "description": "",
                    "game_id": "644",
                    "game_name": "Speed Blackjack 27 - Emerald",
                    "id": 555,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/644.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Live games",
                    "description": "",
                    "game_id": "660",
                    "game_name": "Speed Blackjack 21 - Emerald",
                    "id": 556,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/660.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Live games",
                    "description": "",
                    "game_id": "659",
                    "game_name": "Speed Blackjack 20 - Emerald",
                    "id": 557,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/659.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Live games",
                    "description": "",
                    "game_id": "567",
                    "game_name": "Speed Blackjack 2 - Ruby",
                    "id": 558,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/567.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Live games",
                    "description": "",
                    "game_id": "642",
                    "game_name": "Speed Blackjack 25 - Emerald",
                    "id": 559,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/642.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Live games",
                    "description": "",
                    "game_id": "641",
                    "game_name": "Speed Blackjack 24 - Emerald",
                    "id": 560,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/641.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Live games",
                    "description": "",
                    "game_id": "609",
                    "game_name": "Speed Blackjack 14 - Azure",
                    "id": 561,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/609.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Live games",
                    "description": "",
                    "game_id": "570",
                    "game_name": "Speed Blackjack 5 - Ruby",
                    "id": 562,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/570.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Live games",
                    "description": "",
                    "game_id": "647",
                    "game_name": "Speed Blackjack 30 - Emerald",
                    "id": 563,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/647.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Live games",
                    "description": "",
                    "game_id": "569",
                    "game_name": "Speed Blackjack 4 - Ruby",
                    "id": 564,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/569.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Live games",
                    "description": "",
                    "game_id": "568",
                    "game_name": "Speed Blackjack 3 - Ruby",
                    "id": 565,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/568.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Live games",
                    "description": "",
                    "game_id": "605",
                    "game_name": "Speed Blackjack 9 - Ruby",
                    "id": 566,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/605.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Live games",
                    "description": "",
                    "game_id": "592",
                    "game_name": "Speed Blackjack 7 - Ruby",
                    "id": 567,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/592.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Live games",
                    "description": "",
                    "game_id": "646",
                    "game_name": "Speed Blackjack 29 - Emerald",
                    "id": 568,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/646.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Live games",
                    "description": "",
                    "game_id": "645",
                    "game_name": "Speed Blackjack 28 - Emerald",
                    "id": 569,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/645.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Live games",
                    "description": "",
                    "game_id": "604",
                    "game_name": "Speed Blackjack 8 - Ruby",
                    "id": 570,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/604.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Live games",
                    "description": "",
                    "game_id": "591",
                    "game_name": "Speed Blackjack 6 - Ruby",
                    "id": 571,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/591.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Live games",
                    "description": "",
                    "game_id": "624",
                    "game_name": "VIP Blackjack 11 - Ruby",
                    "id": 572,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/624.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Live games",
                    "description": "",
                    "game_id": "904",
                    "game_name": "Turkish ONE Blackjack",
                    "id": 573,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/904.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Live games",
                    "description": "",
                    "game_id": "623",
                    "game_name": "VIP Blackjack 10 - Ruby",
                    "id": 574,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/623.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Live games",
                    "description": "",
                    "game_id": "224",
                    "game_name": "Turkish Roulette",
                    "id": 576,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/224.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Live games",
                    "description": "",
                    "game_id": "433",
                    "game_name": "Super 8 Baccarat",
                    "id": 578,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/433.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Live games",
                    "description": "",
                    "game_id": "625",
                    "game_name": "VIP Blackjack 12 - Ruby",
                    "id": 579,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/625.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Live games",
                    "description": "",
                    "game_id": "546",
                    "game_name": "VIP Blackjack 1 - Ruby",
                    "id": 580,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/546.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Live games",
                    "description": "",
                    "game_id": "1101",
                    "game_name": "Sweet Bonanza CandyLand",
                    "id": 581,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/1101.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Live games",
                    "description": "",
                    "game_id": "545",
                    "game_name": "VIP Roulette - The Club",
                    "id": 582,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/545.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Live games",
                    "description": "",
                    "game_id": "663",
                    "game_name": "VIP Blackjack 7",
                    "id": 583,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/663.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Live games",
                    "description": "",
                    "game_id": "664",
                    "game_name": "VIP Blackjack 8",
                    "id": 584,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/664.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Live games",
                    "description": "",
                    "game_id": "622",
                    "game_name": "VIP Blackjack 9 - Ruby",
                    "id": 585,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/622.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Live games",
                    "description": "",
                    "game_id": "662",
                    "game_name": "VIP Blackjack 6",
                    "id": 586,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/662.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Live games",
                    "description": "",
                    "game_id": "549",
                    "game_name": "VIP Blackjack 4 - Ruby",
                    "id": 587,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/549.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Live games",
                    "description": "",
                    "game_id": "550",
                    "game_name": "VIP Blackjack 5 - Ruby",
                    "id": 588,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/550.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Live games",
                    "description": "",
                    "game_id": "548",
                    "game_name": "VIP Blackjack 3 - Ruby",
                    "id": 589,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/548.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Live games",
                    "description": "",
                    "game_id": "547",
                    "game_name": "VIP Blackjack 2 - Ruby",
                    "id": 590,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/547.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Live games",
                    "description": "",
                    "game_id": "1501",
                    "game_name": "Vegas Ball Bonanza",
                    "id": 591,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/1501.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Live games",
                    "description": "",
                    "game_id": "404",
                    "game_name": "Baccarat 2",
                    "id": 644,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/404.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Live games",
                    "description": "",
                    "game_id": "401",
                    "game_name": "Baccarat 1",
                    "id": 645,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/401.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Live games",
                    "description": "",
                    "game_id": "210",
                    "game_name": "Auto Mega Roulette",
                    "id": 650,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/210.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Live games",
                    "description": "",
                    "game_id": "301",
                    "game_name": "Blackjack 11",
                    "id": 651,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/301.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Live games",
                    "description": "",
                    "game_id": "206",
                    "game_name": "Roulette Macao",
                    "id": 652,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/206.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Live games",
                    "description": "",
                    "game_id": "201",
                    "game_name": "Roulette Green",
                    "id": 653,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/201.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Live games",
                    "description": "",
                    "game_id": "107",
                    "game_name": "Sic Bo",
                    "id": 654,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/107.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Live games",
                    "description": "",
                    "game_id": "204",
                    "game_name": "Mega Roulette",
                    "id": 655,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/204.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Live games",
                    "description": "",
                    "game_id": "304",
                    "game_name": "Blackjack 15",
                    "id": 657,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/304.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Live games",
                    "description": "",
                    "game_id": "405",
                    "game_name": "Speed Baccarat 14",
                    "id": 658,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/405.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Live games",
                    "description": "",
                    "game_id": "402",
                    "game_name": "Speed Baccarat 1",
                    "id": 659,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/402.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Live games",
                    "description": "",
                    "game_id": "108",
                    "game_name": "Dragon Tiger",
                    "id": 660,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/108.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Live games",
                    "description": "",
                    "game_id": "601",
                    "game_name": "Blackjack 60 - Azure",
                    "id": 661,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/601.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  }
                ]
              },
              {
                "id": 6,
                "name": "Casino games",
                "priority": 60,
                "status": 1,
                "games": [
                  {
                    "category": "Casino games",
                    "description": "",
                    "game_id": "VirtualBurningRoulette",
                    "game_name": "Virtual Burning Roulette",
                    "id": 44,
                    "image": "https://storage.googleapis.com/maybet/smartsoft/VirtualBurningRoulette.png",
                    "priority": 1,
                    "provider_id": 5,
                    "provider_name": "Smartsoft Gaming",
                    "status": 1
                  },
                  {
                    "category": "Casino games",
                    "description": "",
                    "game_id": "VirtualClassicRoulette",
                    "game_name": "Virtual Classic Roulette",
                    "id": 45,
                    "image": "https://storage.googleapis.com/maybet/smartsoft/VirtualClassicRoulette.png",
                    "priority": 1,
                    "provider_id": 5,
                    "provider_name": "Smartsoft Gaming",
                    "status": 1
                  },
                  {
                    "category": "Casino games",
                    "description": "",
                    "game_id": "PersonalRoulette",
                    "game_name": "Personal Roulette",
                    "id": 53,
                    "image": "https://storage.googleapis.com/maybet/smartsoft/PersonalRoulette.png",
                    "priority": 1,
                    "provider_id": 5,
                    "provider_name": "Smartsoft Gaming",
                    "status": 1
                  },
                  {
                    "category": "Casino games",
                    "description": "",
                    "game_id": "VirtualRoulette",
                    "game_name": "Virtual Roulette",
                    "id": 55,
                    "image": "https://storage.googleapis.com/maybet/smartsoft/VirtualRoulette.png",
                    "priority": 1,
                    "provider_id": 5,
                    "provider_name": "Smartsoft Gaming",
                    "status": 1
                  },
                  {
                    "category": "Casino games",
                    "description": "",
                    "game_id": "BonusRoulette",
                    "game_name": "Bonus Roulette",
                    "id": 60,
                    "image": "https://storage.googleapis.com/maybet/smartsoft/BonusRoulette.png",
                    "priority": 1,
                    "provider_id": 5,
                    "provider_name": "Smartsoft Gaming",
                    "status": 1
                  },
                  {
                    "category": "Casino games",
                    "description": "",
                    "game_id": "fastballs",
                    "game_name": "Fast Ball",
                    "id": 668,
                    "image": "https://storage.googleapis.com/maybet/elbet/fastballs.jpeg",
                    "priority": 1,
                    "provider_id": 4,
                    "provider_name": "Elbet",
                    "status": 1
                  },
                  {
                    "category": "Casino games",
                    "description": "",
                    "game_id": "6578466fc9215b0001208f66",
                    "game_name": "Jam Bonanza",
                    "id": 679,
                    "image": "https://yard.gcsd.io/assets/games/6578466fc9215b0001208f66/v0.0.31/logos/en/mobile.png",
                    "priority": 1,
                    "provider_id": 8,
                    "provider_name": "Booming Games",
                    "status": 1
                  },
                  {
                    "category": "Casino games",
                    "description": "",
                    "game_id": "64ec56ada2e36a0001d2ee75",
                    "game_name": "Fruit Heaven Hold and Win",
                    "id": 680,
                    "image": "https://yard.gcsd.io/assets/games/64ec56ada2e36a0001d2ee75/v0.0.73/logos/en/mobile.png",
                    "priority": 1,
                    "provider_id": 8,
                    "provider_name": "Booming Games",
                    "status": 1
                  },
                  {
                    "category": "Casino games",
                    "description": "",
                    "game_id": "64dcc2f2a2e36a0001d2ee69",
                    "game_name": "Bang Bang Reloaded",
                    "id": 681,
                    "image": "https://yard.gcsd.io/assets/games/64dcc2f2a2e36a0001d2ee69/v0.0.73/logos/en/mobile.png",
                    "priority": 1,
                    "provider_id": 8,
                    "provider_name": "Booming Games",
                    "status": 1
                  },
                  {
                    "category": "Casino games",
                    "description": "",
                    "game_id": "64abe7a9d5cc48000110f878",
                    "game_name": "Boogie Boom",
                    "id": 682,
                    "image": "https://yard.gcsd.io/assets/games/64abe7a9d5cc48000110f878/v0.1.107/logos/en/mobile.png",
                    "priority": 1,
                    "provider_id": 8,
                    "provider_name": "Booming Games",
                    "status": 1
                  },
                  {
                    "category": "Casino games",
                    "description": "",
                    "game_id": "64a65ea0252178000174d0dc",
                    "game_name": "Hoop Kings",
                    "id": 683,
                    "image": "https://yard.gcsd.io/assets/games/64a65ea0252178000174d0dc/v0.0.82/logos/en/mobile.png",
                    "priority": 1,
                    "provider_id": 8,
                    "provider_name": "Booming Games",
                    "status": 1
                  },
                  {
                    "category": "Casino games",
                    "description": "",
                    "game_id": "64a3e7c0afc503000168761e",
                    "game_name": "Power of Rome",
                    "id": 684,
                    "image": "https://yard.gcsd.io/assets/games/64a3e7c0afc503000168761e/v1.0.114/logos/en/mobile.png",
                    "priority": 1,
                    "provider_id": 8,
                    "provider_name": "Booming Games",
                    "status": 1
                  },
                  {
                    "category": "Casino games",
                    "description": "",
                    "game_id": "6499523cb08a1d0001be657d",
                    "game_name": "Fortune Tycoons",
                    "id": 685,
                    "image": "https://yard.gcsd.io/assets/games/6499523cb08a1d0001be657d/v0.0.26/logos/en/mobile.png",
                    "priority": 1,
                    "provider_id": 8,
                    "provider_name": "Booming Games",
                    "status": 1
                  },
                  {
                    "category": "Casino games",
                    "description": "",
                    "game_id": "645b98d8abe4c600013e1998",
                    "game_name": "Secret Book of Amun Ra",
                    "id": 686,
                    "image": "https://yard.gcsd.io/assets/games/645b98d8abe4c600013e1998/v0.1.94/logos/en/mobile.png",
                    "priority": 1,
                    "provider_id": 8,
                    "provider_name": "Booming Games",
                    "status": 1
                  },
                  {
                    "category": "Casino games",
                    "description": "",
                    "game_id": "6458c00b2e01ff00010a2090",
                    "game_name": "Holly Jolly Bonanza",
                    "id": 687,
                    "image": "https://yard.gcsd.io/assets/games/6458c00b2e01ff00010a2090/v2.1.39/logos/en/mobile.png",
                    "priority": 1,
                    "provider_id": 8,
                    "provider_name": "Booming Games",
                    "status": 1
                  },
                  {
                    "category": "Casino games",
                    "description": "",
                    "game_id": "6436be6c9268640001b42dd5",
                    "game_name": "Smoking Pistols",
                    "id": 688,
                    "image": "https://yard.gcsd.io/assets/games/6436be6c9268640001b42dd5/v0.0.107/logos/en/mobile.png",
                    "priority": 1,
                    "provider_id": 8,
                    "provider_name": "Booming Games",
                    "status": 1
                  },
                  {
                    "category": "Casino games",
                    "description": "",
                    "game_id": "641b253ef3cb4b00016f5224",
                    "game_name": "Space Cows to the Moon",
                    "id": 689,
                    "image": "https://yard.gcsd.io/assets/games/641b253ef3cb4b00016f5224/v1.2.20/logos/en/mobile.png",
                    "priority": 1,
                    "provider_id": 8,
                    "provider_name": "Booming Games",
                    "status": 1
                  },
                  {
                    "category": "Casino games",
                    "description": "",
                    "game_id": "6411815816f2ad00018e7426",
                    "game_name": "Mammoth Tundra",
                    "id": 690,
                    "image": "https://yard.gcsd.io/assets/games/6411815816f2ad00018e7426/v1.0.25/logos/en/mobile.png",
                    "priority": 1,
                    "provider_id": 8,
                    "provider_name": "Booming Games",
                    "status": 1
                  },
                  {
                    "category": "Casino games",
                    "description": "",
                    "game_id": "63fdd6b8428e5b0001dd7ca6",
                    "game_name": "Power of the Vikings",
                    "id": 691,
                    "image": "https://yard.gcsd.io/assets/games/63fdd6b8428e5b0001dd7ca6/v2.3.117/logos/en/mobile.png",
                    "priority": 1,
                    "provider_id": 8,
                    "provider_name": "Booming Games",
                    "status": 1
                  },
                  {
                    "category": "Casino games",
                    "description": "",
                    "game_id": "63f38e5fe2ef820001bcf29f",
                    "game_name": "Money Inc",
                    "id": 692,
                    "image": "https://yard.gcsd.io/assets/games/63f38e5fe2ef820001bcf29f/v0.23.35/logos/en/mobile.png",
                    "priority": 1,
                    "provider_id": 8,
                    "provider_name": "Booming Games",
                    "status": 1
                  },
                  {
                    "category": "Casino games",
                    "description": "",
                    "game_id": "63e21a63f78c9900017af6d6",
                    "game_name": "Billy Bob Boom",
                    "id": 693,
                    "image": "https://yard.gcsd.io/assets/games/63e21a63f78c9900017af6d6/v2.3.133/logos/en/mobile.png",
                    "priority": 1,
                    "provider_id": 8,
                    "provider_name": "Booming Games",
                    "status": 1
                  },
                  {
                    "category": "Casino games",
                    "description": "",
                    "game_id": "63bd9c96673cf800018438d4",
                    "game_name": "Dog Heist Shift ‘n’ Win",
                    "id": 694,
                    "image": "https://yard.gcsd.io/assets/games/63bd9c96673cf800018438d4/v2.5.147/logos/en/mobile.png",
                    "priority": 1,
                    "provider_id": 8,
                    "provider_name": "Booming Games",
                    "status": 1
                  },
                  {
                    "category": "Casino games",
                    "description": "",
                    "game_id": "63bbf0b15e59b8000118d595",
                    "game_name": "Light of Ra",
                    "id": 695,
                    "image": "https://yard.gcsd.io/assets/games/63bbf0b15e59b8000118d595/v0.8.146/logos/en/mobile.png",
                    "priority": 1,
                    "provider_id": 8,
                    "provider_name": "Booming Games",
                    "status": 1
                  },
                  {
                    "category": "Casino games",
                    "description": "",
                    "game_id": "63b450475e59b8000118d58f",
                    "game_name": "Bamboo Wilds",
                    "id": 696,
                    "image": "https://yard.gcsd.io/assets/games/63b450475e59b8000118d58f/v0.4.156/logos/en/mobile.png",
                    "priority": 1,
                    "provider_id": 8,
                    "provider_name": "Booming Games",
                    "status": 1
                  },
                  {
                    "category": "Casino games",
                    "description": "",
                    "game_id": "63a053373e9ac9000187c16e",
                    "game_name": "Butterfly Charms",
                    "id": 697,
                    "image": "https://yard.gcsd.io/assets/games/63a053373e9ac9000187c16e/v0.0.109/logos/en/mobile.png",
                    "priority": 1,
                    "provider_id": 8,
                    "provider_name": "Booming Games",
                    "status": 1
                  },
                  {
                    "category": "Casino games",
                    "description": "",
                    "game_id": "637cf755a784e30001057f18",
                    "game_name": "Classic Fantastic",
                    "id": 698,
                    "image": "https://yard.gcsd.io/assets/games/637cf755a784e30001057f18/v1.0.76/logos/en/mobile.png",
                    "priority": 1,
                    "provider_id": 8,
                    "provider_name": "Booming Games",
                    "status": 1
                  },
                  {
                    "category": "Casino games",
                    "description": "",
                    "game_id": "6358de4723ea5a000133a404",
                    "game_name": "Piñata Wildz",
                    "id": 699,
                    "image": "https://yard.gcsd.io/assets/games/6358de4723ea5a000133a404/v4.76.71/logos/en/mobile.png",
                    "priority": 1,
                    "provider_id": 8,
                    "provider_name": "Booming Games",
                    "status": 1
                  },
                  {
                    "category": "Casino games",
                    "description": "",
                    "game_id": "6357e1bf23ea5a000133a3fe",
                    "game_name": "Payday Pig",
                    "id": 700,
                    "image": "https://yard.gcsd.io/assets/games/6357e1bf23ea5a000133a3fe/v4.16.23/logos/en/mobile.png",
                    "priority": 1,
                    "provider_id": 8,
                    "provider_name": "Booming Games",
                    "status": 1
                  },
                  {
                    "category": "Casino games",
                    "description": "",
                    "game_id": "63566b0c26625500019175d7",
                    "game_name": "Gold Gold Gold",
                    "id": 701,
                    "image": "https://yard.gcsd.io/assets/games/63566b0c26625500019175d7/v1.0.120/logos/en/mobile.png",
                    "priority": 1,
                    "provider_id": 8,
                    "provider_name": "Booming Games",
                    "status": 1
                  },
                  {
                    "category": "Casino games",
                    "description": "",
                    "game_id": "634807c733c14000011d2f51",
                    "game_name": "Power of Olympus",
                    "id": 702,
                    "image": "https://yard.gcsd.io/assets/games/634807c733c14000011d2f51/v1.1.112/logos/en/mobile.png",
                    "priority": 1,
                    "provider_id": 8,
                    "provider_name": "Booming Games",
                    "status": 1
                  },
                  {
                    "category": "Casino games",
                    "description": "",
                    "game_id": "6346e6fb33c14000011d2f4b",
                    "game_name": "Go Bananza",
                    "id": 703,
                    "image": "https://yard.gcsd.io/assets/games/6346e6fb33c14000011d2f4b/v2.0.79/logos/en/mobile.png",
                    "priority": 1,
                    "provider_id": 8,
                    "provider_name": "Booming Games",
                    "status": 1
                  },
                  {
                    "category": "Casino games",
                    "description": "",
                    "game_id": "633c437186f7f10001c8f847",
                    "game_name": "King Cobra",
                    "id": 704,
                    "image": "https://yard.gcsd.io/assets/games/633c437186f7f10001c8f847/v0.3.102/logos/en/mobile.png",
                    "priority": 1,
                    "provider_id": 8,
                    "provider_name": "Booming Games",
                    "status": 1
                  },
                  {
                    "category": "Casino games",
                    "description": "",
                    "game_id": "633184d763e1660001d289b3",
                    "game_name": "Dog Squad",
                    "id": 705,
                    "image": "https://yard.gcsd.io/assets/games/633184d763e1660001d289b3/v2.3.51/logos/en/mobile.png",
                    "priority": 1,
                    "provider_id": 8,
                    "provider_name": "Booming Games",
                    "status": 1
                  },
                  {
                    "category": "Casino games",
                    "description": "",
                    "game_id": "63205f57313c900016e560dd",
                    "game_name": "Super Duper",
                    "id": 706,
                    "image": "https://yard.gcsd.io/assets/games/63205f57313c900016e560dd/v0.4.60/logos/en/mobile.png",
                    "priority": 1,
                    "provider_id": 8,
                    "provider_name": "Booming Games",
                    "status": 1
                  },
                  {
                    "category": "Casino games",
                    "description": "",
                    "game_id": "630dc4a95f550b000f4eeaf1",
                    "game_name": "The Mighty Toro",
                    "id": 707,
                    "image": "https://yard.gcsd.io/assets/games/630dc4a95f550b000f4eeaf1/v0.4.121/logos/en/mobile.png",
                    "priority": 1,
                    "provider_id": 8,
                    "provider_name": "Booming Games",
                    "status": 1
                  },
                  {
                    "category": "Casino games",
                    "description": "",
                    "game_id": "6304806e5f550b000e4eeaf1",
                    "game_name": "Golden Strawberries",
                    "id": 708,
                    "image": "https://yard.gcsd.io/assets/games/6304806e5f550b000e4eeaf1/v1.0.60/logos/en/mobile.png",
                    "priority": 1,
                    "provider_id": 8,
                    "provider_name": "Booming Games",
                    "status": 1
                  },
                  {
                    "category": "Casino games",
                    "description": "",
                    "game_id": "62fe444a6db4f70014a6342d",
                    "game_name": "TNT Bonanza",
                    "id": 709,
                    "image": "https://yard.gcsd.io/assets/games/62fe444a6db4f70014a6342d/v0.5.304/logos/en/mobile.png",
                    "priority": 1,
                    "provider_id": 8,
                    "provider_name": "Booming Games",
                    "status": 1
                  },
                  {
                    "category": "Casino games",
                    "description": "",
                    "game_id": "62e1137c76d612000c06bb20",
                    "game_name": "Dice Dice Baby",
                    "id": 710,
                    "image": "https://yard.gcsd.io/assets/games/62e1137c76d612000c06bb20/v2.1.89/logos/en/mobile.png",
                    "priority": 1,
                    "provider_id": 8,
                    "provider_name": "Booming Games",
                    "status": 1
                  },
                  {
                    "category": "Casino games",
                    "description": "",
                    "game_id": "62bde50056dc080011ae001e",
                    "game_name": "The Rodfather Megaways",
                    "id": 711,
                    "image": "https://yard.gcsd.io/assets/games/62bde50056dc080011ae001e/v2.3.62/logos/en/mobile.png",
                    "priority": 1,
                    "provider_id": 8,
                    "provider_name": "Booming Games",
                    "status": 1
                  },
                  {
                    "category": "Casino games",
                    "description": "",
                    "game_id": "62bde4e756dc080014ae001e",
                    "game_name": "Let it Spin",
                    "id": 712,
                    "image": "https://yard.gcsd.io/assets/games/62bde4e756dc080014ae001e/v2.1.116/logos/en/mobile.png",
                    "priority": 1,
                    "provider_id": 8,
                    "provider_name": "Booming Games",
                    "status": 1
                  },
                  {
                    "category": "Casino games",
                    "description": "",
                    "game_id": "6294abf79b2d00000e180197",
                    "game_name": "Megahops Megaways",
                    "id": 713,
                    "image": "https://yard.gcsd.io/assets/games/6294abf79b2d00000e180197/v0.39.27/logos/en/mobile.png",
                    "priority": 1,
                    "provider_id": 8,
                    "provider_name": "Booming Games",
                    "status": 1
                  },
                  {
                    "category": "Casino games",
                    "description": "",
                    "game_id": "628215226698440014bd3bd9",
                    "game_name": "The Wild Wings of Phoenix",
                    "id": 714,
                    "image": "https://yard.gcsd.io/assets/games/628215226698440014bd3bd9/v0.3.163/logos/en/mobile.png",
                    "priority": 1,
                    "provider_id": 8,
                    "provider_name": "Booming Games",
                    "status": 1
                  },
                  {
                    "category": "Casino games",
                    "description": "",
                    "game_id": "62738aa3a81ac8000e974472",
                    "game_name": "Crack the Bank Hold and Win",
                    "id": 715,
                    "image": "https://yard.gcsd.io/assets/games/62738aa3a81ac8000e974472/v1.4.137/logos/en/mobile.png",
                    "priority": 1,
                    "provider_id": 8,
                    "provider_name": "Booming Games",
                    "status": 1
                  },
                  {
                    "category": "Casino games",
                    "description": "",
                    "game_id": "627384b0a81ac80010974472",
                    "game_name": "Starlight Riches",
                    "id": 716,
                    "image": "https://yard.gcsd.io/assets/games/627384b0a81ac80010974472/v1.1.59/logos/en/mobile.png",
                    "priority": 1,
                    "provider_id": 8,
                    "provider_name": "Booming Games",
                    "status": 1
                  },
                  {
                    "category": "Casino games",
                    "description": "",
                    "game_id": "6272e427a81ac80014974472",
                    "game_name": "The Jungle Empire",
                    "id": 717,
                    "image": "https://yard.gcsd.io/assets/games/6272e427a81ac80014974472/v5.0.93/logos/en/mobile.png",
                    "priority": 1,
                    "provider_id": 8,
                    "provider_name": "Booming Games",
                    "status": 1
                  },
                  {
                    "category": "Casino games",
                    "description": "",
                    "game_id": "6262d4ec27d185000e76cc68",
                    "game_name": "Spin It Hold and Win",
                    "id": 718,
                    "image": "https://yard.gcsd.io/assets/games/6262d4ec27d185000e76cc68/v1.1.87/logos/en/mobile.png",
                    "priority": 1,
                    "provider_id": 8,
                    "provider_name": "Booming Games",
                    "status": 1
                  },
                  {
                    "category": "Casino games",
                    "description": "",
                    "game_id": "6261c2b227d185001376cc68",
                    "game_name": "Roll the Dice",
                    "id": 719,
                    "image": "https://yard.gcsd.io/assets/games/6261c2b227d185001376cc68/v1.9.115/logos/en/mobile.png",
                    "priority": 1,
                    "provider_id": 8,
                    "provider_name": "Booming Games",
                    "status": 1
                  },
                  {
                    "category": "Casino games",
                    "description": "",
                    "game_id": "6256ba20584e2d001065a312",
                    "game_name": "Burning Classics Go Wild",
                    "id": 720,
                    "image": "https://yard.gcsd.io/assets/games/6256ba20584e2d001065a312/v4.37.105/logos/en/mobile.png",
                    "priority": 1,
                    "provider_id": 8,
                    "provider_name": "Booming Games",
                    "status": 1
                  },
                  {
                    "category": "Casino games",
                    "description": "",
                    "game_id": "623f4cee90e187000eb59236",
                    "game_name": "Monster Truck Madness",
                    "id": 721,
                    "image": "https://yard.gcsd.io/assets/games/623f4cee90e187000eb59236/v1.0.146/logos/en/mobile.png",
                    "priority": 1,
                    "provider_id": 8,
                    "provider_name": "Booming Games",
                    "status": 1
                  },
                  {
                    "category": "Casino games",
                    "description": "",
                    "game_id": "623dbc6a90e1870012b59236",
                    "game_name": "Legendary Diamonds",
                    "id": 722,
                    "image": "https://yard.gcsd.io/assets/games/623dbc6a90e1870012b59236/v0.0.162/logos/en/mobile.png",
                    "priority": 1,
                    "provider_id": 8,
                    "provider_name": "Booming Games",
                    "status": 1
                  },
                  {
                    "category": "Casino games",
                    "description": "",
                    "game_id": "62053097befa3700135380a1",
                    "game_name": "Treasure Vault",
                    "id": 723,
                    "image": "https://yard.gcsd.io/assets/games/62053097befa3700135380a1/v2.1.17/logos/en/mobile.png",
                    "priority": 1,
                    "provider_id": 8,
                    "provider_name": "Booming Games",
                    "status": 1
                  },
                  {
                    "category": "Casino games",
                    "description": "",
                    "game_id": "6202363cbefa370016538086",
                    "game_name": "Gamblelicious Hold and Win",
                    "id": 724,
                    "image": "https://yard.gcsd.io/assets/games/6202363cbefa370016538086/v1.1.87/logos/en/mobile.png",
                    "priority": 1,
                    "provider_id": 8,
                    "provider_name": "Booming Games",
                    "status": 1
                  },
                  {
                    "category": "Casino games",
                    "description": "",
                    "game_id": "61debc32befa370011538096",
                    "game_name": "Khans Wild Quest",
                    "id": 725,
                    "image": "https://yard.gcsd.io/assets/games/61debc32befa370011538096/v1.4.151/logos/en/mobile.png",
                    "priority": 1,
                    "provider_id": 8,
                    "provider_name": "Booming Games",
                    "status": 1
                  },
                  {
                    "category": "Casino games",
                    "description": "",
                    "game_id": "61787bffbefa370011538086",
                    "game_name": "Blockchain Megaways",
                    "id": 726,
                    "image": "https://yard.gcsd.io/assets/games/61787bffbefa370011538086/v0.35.67/logos/en/mobile.png",
                    "priority": 1,
                    "provider_id": 8,
                    "provider_name": "Booming Games",
                    "status": 1
                  },
                  {
                    "category": "Casino games",
                    "description": "",
                    "game_id": "61787ba0befa370011538076",
                    "game_name": "Sticky Bombs",
                    "id": 727,
                    "image": "https://yard.gcsd.io/assets/games/61787ba0befa370011538076/v4.32.73/logos/en/mobile.png",
                    "priority": 1,
                    "provider_id": 8,
                    "provider_name": "Booming Games",
                    "status": 1
                  },
                  {
                    "category": "Casino games",
                    "description": "",
                    "game_id": "61787b92befa370019538086",
                    "game_name": "Kims Wild Journey",
                    "id": 728,
                    "image": "https://yard.gcsd.io/assets/games/61787b92befa370019538086/v20.47.74/logos/en/mobile.png",
                    "priority": 1,
                    "provider_id": 8,
                    "provider_name": "Booming Games",
                    "status": 1
                  },
                  {
                    "category": "Casino games",
                    "description": "",
                    "game_id": "61787b78befa370012538076",
                    "game_name": "Lucky Oasis",
                    "id": 729,
                    "image": "https://yard.gcsd.io/assets/games/61787b78befa370012538076/v1.4.180/logos/en/mobile.png",
                    "priority": 1,
                    "provider_id": 8,
                    "provider_name": "Booming Games",
                    "status": 1
                  },
                  {
                    "category": "Casino games",
                    "description": "",
                    "game_id": "61787b5dbefa370019538076",
                    "game_name": "Jesters Riches",
                    "id": 730,
                    "image": "https://yard.gcsd.io/assets/games/61787b5dbefa370019538076/v1.0.154/logos/en/mobile.png",
                    "priority": 1,
                    "provider_id": 8,
                    "provider_name": "Booming Games",
                    "status": 1
                  },
                  {
                    "category": "Casino games",
                    "description": "",
                    "game_id": "61787a29befa370016538076",
                    "game_name": "Flaming Chillies",
                    "id": 731,
                    "image": "https://yard.gcsd.io/assets/games/61787a29befa370016538076/v1.5.143/logos/en/mobile.png",
                    "priority": 1,
                    "provider_id": 8,
                    "provider_name": "Booming Games",
                    "status": 1
                  },
                  {
                    "category": "Casino games",
                    "description": "",
                    "game_id": "616168aabefa370018538081",
                    "game_name": "Fortune n Finery",
                    "id": 732,
                    "image": "https://yard.gcsd.io/assets/games/616168aabefa370018538081/v2.1.122/logos/en/mobile.png",
                    "priority": 1,
                    "provider_id": 8,
                    "provider_name": "Booming Games",
                    "status": 1
                  },
                  {
                    "category": "Casino games",
                    "description": "",
                    "game_id": "61616851befa370018538076",
                    "game_name": "Book of Poseidon",
                    "id": 733,
                    "image": "https://yard.gcsd.io/assets/games/61616851befa370018538076/v1.1.107/logos/en/mobile.png",
                    "priority": 1,
                    "provider_id": 8,
                    "provider_name": "Booming Games",
                    "status": 1
                  },
                  {
                    "category": "Casino games",
                    "description": "",
                    "game_id": "6139c9740cc9a00015ece6fb",
                    "game_name": "Money Moose",
                    "id": 734,
                    "image": "https://yard.gcsd.io/assets/games/6139c9740cc9a00015ece6fb/v1.2.140/logos/en/mobile.png",
                    "priority": 1,
                    "provider_id": 8,
                    "provider_name": "Booming Games",
                    "status": 1
                  },
                  {
                    "category": "Casino games",
                    "description": "",
                    "game_id": "612776dfe58605001ac445e9",
                    "game_name": "Ocean Drive",
                    "id": 735,
                    "image": "https://yard.gcsd.io/assets/games/612776dfe58605001ac445e9/v1.0.62/logos/en/mobile.png",
                    "priority": 1,
                    "provider_id": 8,
                    "provider_name": "Booming Games",
                    "status": 1
                  },
                  {
                    "category": "Casino games",
                    "description": "",
                    "game_id": "60faa3b0589d2900175cb98e",
                    "game_name": "Rhino Hold and Win",
                    "id": 736,
                    "image": "https://yard.gcsd.io/assets/games/60faa3b0589d2900175cb98e/v1.12.73/logos/en/mobile.png",
                    "priority": 1,
                    "provider_id": 8,
                    "provider_name": "Booming Games",
                    "status": 1
                  },
                  {
                    "category": "Casino games",
                    "description": "",
                    "game_id": "60e58c90dbc14400186d67d4",
                    "game_name": "Wild Bonus Re-spins",
                    "id": 737,
                    "image": "https://yard.gcsd.io/assets/games/60e58c90dbc14400186d67d4/v1.1.57/logos/en/mobile.png",
                    "priority": 1,
                    "provider_id": 8,
                    "provider_name": "Booming Games",
                    "status": 1
                  },
                  {
                    "category": "Casino games",
                    "description": "",
                    "game_id": "60c8728fdbc1440017518e05",
                    "game_name": "Mighty Gorilla",
                    "id": 738,
                    "image": "https://yard.gcsd.io/assets/games/60c8728fdbc1440017518e05/v9.5.132/logos/en/mobile.png",
                    "priority": 1,
                    "provider_id": 8,
                    "provider_name": "Booming Games",
                    "status": 1
                  },
                  {
                    "category": "Casino games",
                    "description": "",
                    "game_id": "60ae48669e1ee60015fdf22a",
                    "game_name": "Red Hot Volcano",
                    "id": 739,
                    "image": "https://yard.gcsd.io/assets/games/60ae48669e1ee60015fdf22a/v1.49.70/logos/en/mobile.png",
                    "priority": 1,
                    "provider_id": 8,
                    "provider_name": "Booming Games",
                    "status": 1
                  },
                  {
                    "category": "Casino games",
                    "description": "",
                    "game_id": "6093eb819e1ee60017fdf21e",
                    "game_name": "Howling Wolves Megaways",
                    "id": 740,
                    "image": "https://yard.gcsd.io/assets/games/6093eb819e1ee60017fdf21e/v1.13.50/logos/en/mobile.png",
                    "priority": 1,
                    "provider_id": 8,
                    "provider_name": "Booming Games",
                    "status": 1
                  },
                  {
                    "category": "Casino games",
                    "description": "",
                    "game_id": "6087befc9e1ee6001afdf220",
                    "game_name": "Crystal Classics",
                    "id": 741,
                    "image": "https://yard.gcsd.io/assets/games/6087befc9e1ee6001afdf220/v1.1.86/logos/en/mobile.png",
                    "priority": 1,
                    "provider_id": 8,
                    "provider_name": "Booming Games",
                    "status": 1
                  },
                  {
                    "category": "Casino games",
                    "description": "",
                    "game_id": "606d52719e1ee6001cfdf21e",
                    "game_name": "Greek Legends",
                    "id": 742,
                    "image": "https://yard.gcsd.io/assets/games/606d52719e1ee6001cfdf21e/v1.0.91/logos/en/mobile.png",
                    "priority": 1,
                    "provider_id": 8,
                    "provider_name": "Booming Games",
                    "status": 1
                  },
                  {
                    "category": "Casino games",
                    "description": "",
                    "game_id": "604b38b89e1ee60016fdf21e",
                    "game_name": "Wild Wild Vegas",
                    "id": 743,
                    "image": "https://yard.gcsd.io/assets/games/604b38b89e1ee60016fdf21e/v1.4.140/logos/en/mobile.png",
                    "priority": 1,
                    "provider_id": 8,
                    "provider_name": "Booming Games",
                    "status": 1
                  },
                  {
                    "category": "Casino games",
                    "description": "",
                    "game_id": "602a6703efcba9001485f940",
                    "game_name": "Bang Bang",
                    "id": 744,
                    "image": "https://yard.gcsd.io/assets/games/602a6703efcba9001485f940/v0.71.88/logos/en/mobile.png",
                    "priority": 1,
                    "provider_id": 8,
                    "provider_name": "Booming Games",
                    "status": 1
                  },
                  {
                    "category": "Casino games",
                    "description": "",
                    "game_id": "6025058defcba9001885f942",
                    "game_name": "Gold Hunter",
                    "id": 745,
                    "image": "https://yard.gcsd.io/assets/games/6025058defcba9001885f942/v1.0.63/logos/en/mobile.png",
                    "priority": 1,
                    "provider_id": 8,
                    "provider_name": "Booming Games",
                    "status": 1
                  },
                  {
                    "category": "Casino games",
                    "description": "",
                    "game_id": "5ff41d50efcba9001a85f940",
                    "game_name": "Majestic Safari",
                    "id": 746,
                    "image": "https://yard.gcsd.io/assets/games/5ff41d50efcba9001a85f940/v1.5.49/logos/en/mobile.png",
                    "priority": 1,
                    "provider_id": 8,
                    "provider_name": "Booming Games",
                    "status": 1
                  },
                  {
                    "category": "Casino games",
                    "description": "",
                    "game_id": "5fca4cf5efcba9001685f940",
                    "game_name": "Buffalo Hold and Win",
                    "id": 747,
                    "image": "https://yard.gcsd.io/assets/games/5fca4cf5efcba9001685f940/v1.5.64/logos/en/mobile.png",
                    "priority": 1,
                    "provider_id": 8,
                    "provider_name": "Booming Games",
                    "status": 1
                  },
                  {
                    "category": "Casino games",
                    "description": "",
                    "game_id": "5fbb633befcba9001c85f940",
                    "game_name": "Diamond Riches",
                    "id": 748,
                    "image": "https://yard.gcsd.io/assets/games/5fbb633befcba9001c85f940/v1.4.138/logos/en/mobile.png",
                    "priority": 1,
                    "provider_id": 8,
                    "provider_name": "Booming Games",
                    "status": 1
                  },
                  {
                    "category": "Casino games",
                    "description": "",
                    "game_id": "5f9ad932f7447a001df72349",
                    "game_name": "Wizarding Wins",
                    "id": 749,
                    "image": "https://yard.gcsd.io/assets/games/5f9ad932f7447a001df72349/v0.3.48/logos/en/mobile.png",
                    "priority": 1,
                    "provider_id": 8,
                    "provider_name": "Booming Games",
                    "status": 1
                  },
                  {
                    "category": "Casino games",
                    "description": "",
                    "game_id": "5f7ec229f7447a0018f91ae4",
                    "game_name": "Sphinx Fortune",
                    "id": 750,
                    "image": "https://yard.gcsd.io/assets/games/5f7ec229f7447a0018f91ae4/v1.4.67/logos/en/mobile.png",
                    "priority": 1,
                    "provider_id": 8,
                    "provider_name": "Booming Games",
                    "status": 1
                  },
                  {
                    "category": "Casino games",
                    "description": "",
                    "game_id": "5f48c770f2a091001423728c",
                    "game_name": "Book of Tombs",
                    "id": 751,
                    "image": "https://yard.gcsd.io/assets/games/5f48c770f2a091001423728c/v1.4.90/logos/en/mobile.png",
                    "priority": 1,
                    "provider_id": 8,
                    "provider_name": "Booming Games",
                    "status": 1
                  },
                  {
                    "category": "Casino games",
                    "description": "",
                    "game_id": "5f3510b5737b43001a2e8ff2",
                    "game_name": "Cash Pig",
                    "id": 752,
                    "image": "https://yard.gcsd.io/assets/games/5f3510b5737b43001a2e8ff2/v1.4.108/logos/en/mobile.png",
                    "priority": 1,
                    "provider_id": 8,
                    "provider_name": "Booming Games",
                    "status": 1
                  },
                  {
                    "category": "Casino games",
                    "description": "",
                    "game_id": "5f3153ec737b43008ed75bb6",
                    "game_name": "Freezing Classics",
                    "id": 753,
                    "image": "https://yard.gcsd.io/assets/games/5f3153ec737b43008ed75bb6/v1.5.98/logos/en/mobile.png",
                    "priority": 1,
                    "provider_id": 8,
                    "provider_name": "Booming Games",
                    "status": 1
                  },
                  {
                    "category": "Casino games",
                    "description": "",
                    "game_id": "5f0440d7737b43008ed75bab",
                    "game_name": "Jesters Joy",
                    "id": 754,
                    "image": "https://yard.gcsd.io/assets/games/5f0440d7737b43008ed75bab/v1.9.91/logos/en/mobile.png",
                    "priority": 1,
                    "provider_id": 8,
                    "provider_name": "Booming Games",
                    "status": 1
                  },
                  {
                    "category": "Casino games",
                    "description": "",
                    "game_id": "5efc87eb737b430014d75bac",
                    "game_name": "Wombaroo",
                    "id": 755,
                    "image": "https://yard.gcsd.io/assets/games/5efc87eb737b430014d75bac/v1.4.67/logos/en/mobile.png",
                    "priority": 1,
                    "provider_id": 8,
                    "provider_name": "Booming Games",
                    "status": 1
                  },
                  {
                    "category": "Casino games",
                    "description": "",
                    "game_id": "5ed60223d04d62001db4906e",
                    "game_name": "Horror House",
                    "id": 756,
                    "image": "https://yard.gcsd.io/assets/games/5ed60223d04d62001db4906e/v1.2.56/logos/en/mobile.png",
                    "priority": 1,
                    "provider_id": 8,
                    "provider_name": "Booming Games",
                    "status": 1
                  },
                  {
                    "category": "Casino games",
                    "description": "",
                    "game_id": "5ec22e4ad04d620015b4906e",
                    "game_name": "Lucky Scarabs",
                    "id": 757,
                    "image": "https://yard.gcsd.io/assets/games/5ec22e4ad04d620015b4906e/v1.5.27/logos/en/mobile.png",
                    "priority": 1,
                    "provider_id": 8,
                    "provider_name": "Booming Games",
                    "status": 1
                  },
                  {
                    "category": "Casino games",
                    "description": "",
                    "game_id": "5eb2acf4d04d620018b4906e",
                    "game_name": "Tiki Wins",
                    "id": 758,
                    "image": "https://yard.gcsd.io/assets/games/5eb2acf4d04d620018b4906e/v1.5.24/logos/en/mobile.png",
                    "priority": 1,
                    "provider_id": 8,
                    "provider_name": "Booming Games",
                    "status": 1
                  },
                  {
                    "category": "Casino games",
                    "description": "",
                    "game_id": "5e9dab9cd04d620016b4906e",
                    "game_name": "Howling Wolves",
                    "id": 759,
                    "image": "https://yard.gcsd.io/assets/games/5e9dab9cd04d620016b4906e/v1.8.26/logos/en/mobile.png",
                    "priority": 1,
                    "provider_id": 8,
                    "provider_name": "Booming Games",
                    "status": 1
                  },
                  {
                    "category": "Casino games",
                    "description": "",
                    "game_id": "5e732986ba03bd00171b05bb",
                    "game_name": "Burning Classics",
                    "id": 760,
                    "image": "https://yard.gcsd.io/assets/games/5e732986ba03bd00171b05bb/v1.6.29/logos/en/mobile.png",
                    "priority": 1,
                    "provider_id": 8,
                    "provider_name": "Booming Games",
                    "status": 1
                  },
                  {
                    "category": "Casino games",
                    "description": "",
                    "game_id": "5e68b021ba03bd00131b05bb",
                    "game_name": "Show Master",
                    "id": 761,
                    "image": "https://yard.gcsd.io/assets/games/5e68b021ba03bd00131b05bb/v0.22.19/logos/en/mobile.png",
                    "priority": 1,
                    "provider_id": 8,
                    "provider_name": "Booming Games",
                    "status": 1
                  },
                  {
                    "category": "Casino games",
                    "description": "",
                    "game_id": "5e4b02abe711300015eb83e6",
                    "game_name": "Gunspinner",
                    "id": 762,
                    "image": "https://yard.gcsd.io/assets/games/5e4b02abe711300015eb83e6/v1.3.27/logos/en/mobile.png",
                    "priority": 1,
                    "provider_id": 8,
                    "provider_name": "Booming Games",
                    "status": 1
                  },
                  {
                    "category": "Casino games",
                    "description": "",
                    "game_id": "5e32ce62ba03bd001c7d48e7",
                    "game_name": "Spinosaurus",
                    "id": 763,
                    "image": "https://yard.gcsd.io/assets/games/5e32ce62ba03bd001c7d48e7/v1.12.28/logos/en/mobile.png",
                    "priority": 1,
                    "provider_id": 8,
                    "provider_name": "Booming Games",
                    "status": 1
                  },
                  {
                    "category": "Casino games",
                    "description": "",
                    "game_id": "5dee2eb29db4d9001cd9ee9c",
                    "game_name": "Hooked",
                    "id": 764,
                    "image": "https://yard.gcsd.io/assets/games/5dee2eb29db4d9001cd9ee9c/v1.10.32/logos/en/mobile.png",
                    "priority": 1,
                    "provider_id": 8,
                    "provider_name": "Booming Games",
                    "status": 1
                  },
                  {
                    "category": "Casino games",
                    "description": "",
                    "game_id": "5de6616dfc94b4001af9e08e",
                    "game_name": "Arabian Spins",
                    "id": 765,
                    "image": "https://yard.gcsd.io/assets/games/5de6616dfc94b4001af9e08e/v1.5.46/logos/en/mobile.png",
                    "priority": 1,
                    "provider_id": 8,
                    "provider_name": "Booming Games",
                    "status": 1
                  },
                  {
                    "category": "Casino games",
                    "description": "",
                    "game_id": "5dba8c6857fc8c0016f595f7",
                    "game_name": "Gladiator Arena",
                    "id": 766,
                    "image": "https://yard.gcsd.io/assets/games/5dba8c6857fc8c0016f595f7/v1.9.27/logos/en/mobile.png",
                    "priority": 1,
                    "provider_id": 8,
                    "provider_name": "Booming Games",
                    "status": 1
                  },
                  {
                    "category": "Casino games",
                    "description": "",
                    "game_id": "5dba8c3e57fc8c001af595f7",
                    "game_name": "Wild Energy",
                    "id": 767,
                    "image": "https://yard.gcsd.io/assets/games/5dba8c3e57fc8c001af595f7/v1.8.29/logos/en/mobile.png",
                    "priority": 1,
                    "provider_id": 8,
                    "provider_name": "Booming Games",
                    "status": 1
                  },
                  {
                    "category": "Casino games",
                    "description": "",
                    "game_id": "5d7a278f0b32f4001923b215",
                    "game_name": "Danger Zone",
                    "id": 768,
                    "image": "https://yard.gcsd.io/assets/games/5d7a278f0b32f4001923b215/v0.15.28/logos/en/mobile.png",
                    "priority": 1,
                    "provider_id": 8,
                    "provider_name": "Booming Games",
                    "status": 1
                  },
                  {
                    "category": "Casino games",
                    "description": "",
                    "game_id": "5d5c05447c4c0b0016a7890f",
                    "game_name": "Dragons Chest",
                    "id": 769,
                    "image": "https://yard.gcsd.io/assets/games/5d5c05447c4c0b0016a7890f/v1.16.35/logos/en/mobile.png",
                    "priority": 1,
                    "provider_id": 8,
                    "provider_name": "Booming Games",
                    "status": 1
                  },
                  {
                    "category": "Casino games",
                    "description": "",
                    "game_id": "5d4d21a30cdb03001ed8a6ab",
                    "game_name": "Snail Race",
                    "id": 770,
                    "image": "https://yard.gcsd.io/assets/games/5d4d21a30cdb03001ed8a6ab/v0.9.21/logos/en/mobile.png",
                    "priority": 1,
                    "provider_id": 8,
                    "provider_name": "Booming Games",
                    "status": 1
                  },
                  {
                    "category": "Casino games",
                    "description": "",
                    "game_id": "5d2c76dfa15bc900160c6a30",
                    "game_name": "Witches Wild Brew",
                    "id": 771,
                    "image": "https://yard.gcsd.io/assets/games/5d2c76dfa15bc900160c6a30/v1.4.29/logos/en/mobile.png",
                    "priority": 1,
                    "provider_id": 8,
                    "provider_name": "Booming Games",
                    "status": 1
                  },
                  {
                    "category": "Casino games",
                    "description": "",
                    "game_id": "5d1e0d70a15bc9001c0067b9",
                    "game_name": "Yucatan Quest",
                    "id": 772,
                    "image": "https://yard.gcsd.io/assets/games/5d1e0d70a15bc9001c0067b9/2021-06-23/logos/en/mobile.png",
                    "priority": 1,
                    "provider_id": 8,
                    "provider_name": "Booming Games",
                    "status": 1
                  },
                  {
                    "category": "Casino games",
                    "description": "",
                    "game_id": "5ce681bb55e49c00148a86a5",
                    "game_name": "Aztec Palace",
                    "id": 773,
                    "image": "https://yard.gcsd.io/assets/games/5ce681bb55e49c00148a86a5/v0.32.19/logos/en/mobile.png",
                    "priority": 1,
                    "provider_id": 8,
                    "provider_name": "Booming Games",
                    "status": 1
                  },
                  {
                    "category": "Casino games",
                    "description": "",
                    "game_id": "5cd95f9e55e49c000d8a8696",
                    "game_name": "Colossal Vikings",
                    "id": 774,
                    "image": "https://yard.gcsd.io/assets/games/5cd95f9e55e49c000d8a8696/v1.5.19/logos/en/mobile.png",
                    "priority": 1,
                    "provider_id": 8,
                    "provider_name": "Booming Games",
                    "status": 1
                  },
                  {
                    "category": "Casino games",
                    "description": "",
                    "game_id": "5ccbfeaa55e49c00148a8689",
                    "game_name": "Surfin' Reels",
                    "id": 775,
                    "image": "https://yard.gcsd.io/assets/games/5ccbfeaa55e49c00148a8689/2021-06-23/logos/en/mobile.png",
                    "priority": 1,
                    "provider_id": 8,
                    "provider_name": "Booming Games",
                    "status": 1
                  },
                  {
                    "category": "Casino games",
                    "description": "",
                    "game_id": "5caf2fefe9688a004883d48d",
                    "game_name": "Leprechaun’s Lucky Barrel",
                    "id": 776,
                    "image": "https://yard.gcsd.io/assets/games/5caf2fefe9688a004883d48d/v0.11.30/logos/en/mobile.png",
                    "priority": 1,
                    "provider_id": 8,
                    "provider_name": "Booming Games",
                    "status": 1
                  },
                  {
                    "category": "Casino games",
                    "description": "",
                    "game_id": "5c9547cf2dd5bd000b45103b",
                    "game_name": "Pirate Booty",
                    "id": 777,
                    "image": "https://yard.gcsd.io/assets/games/5c9547cf2dd5bd000b45103b/2021-06-23/logos/en/mobile.png",
                    "priority": 1,
                    "provider_id": 8,
                    "provider_name": "Booming Games",
                    "status": 1
                  },
                  {
                    "category": "Casino games",
                    "description": "",
                    "game_id": "5c811b24362176000fa6a2e2",
                    "game_name": "Gold Vein",
                    "id": 778,
                    "image": "https://yard.gcsd.io/assets/games/5c811b24362176000fa6a2e2/v0.13.0/logos/en/mobile.png",
                    "priority": 1,
                    "provider_id": 8,
                    "provider_name": "Booming Games",
                    "status": 1
                  },
                  {
                    "category": "Casino games",
                    "description": "",
                    "game_id": "5c77a92c362176000f18ba75",
                    "game_name": "Wild Ocean",
                    "id": 779,
                    "image": "https://yard.gcsd.io/assets/games/5c77a92c362176000f18ba75/2021-06-23/logos/en/mobile.png",
                    "priority": 1,
                    "provider_id": 8,
                    "provider_name": "Booming Games",
                    "status": 1
                  },
                  {
                    "category": "Casino games",
                    "description": "",
                    "game_id": "5c669b77362176000f18b9e7",
                    "game_name": "Golden Lucky Pigs",
                    "id": 780,
                    "image": "https://yard.gcsd.io/assets/games/5c669b77362176000f18b9e7/2021-06-23/logos/en/mobile.png",
                    "priority": 1,
                    "provider_id": 8,
                    "provider_name": "Booming Games",
                    "status": 1
                  },
                  {
                    "category": "Casino games",
                    "description": "",
                    "game_id": "5c53038488b5a50011aa99eb",
                    "game_name": "Super Boom",
                    "id": 781,
                    "image": "https://yard.gcsd.io/assets/games/5c53038488b5a50011aa99eb/v1.10.19/logos/en/mobile.png",
                    "priority": 1,
                    "provider_id": 8,
                    "provider_name": "Booming Games",
                    "status": 1
                  },
                  {
                    "category": "Casino games",
                    "description": "",
                    "game_id": "5c3f2c0897fe0e00116fe583",
                    "game_name": "Crazy Bananas",
                    "id": 782,
                    "image": "https://yard.gcsd.io/assets/games/5c3f2c0897fe0e00116fe583/2021-06-23/logos/en/mobile.png",
                    "priority": 1,
                    "provider_id": 8,
                    "provider_name": "Booming Games",
                    "status": 1
                  },
                  {
                    "category": "Casino games",
                    "description": "",
                    "game_id": "5c2dd29697fe0e00116fe4cf",
                    "game_name": "Stellar Spins",
                    "id": 783,
                    "image": "https://yard.gcsd.io/assets/games/5c2dd29697fe0e00116fe4cf/2021-06-23/logos/en/mobile.png",
                    "priority": 1,
                    "provider_id": 8,
                    "provider_name": "Booming Games",
                    "status": 1
                  },
                  {
                    "category": "Casino games",
                    "description": "",
                    "game_id": "5c13b7610d83c500118ab4c6",
                    "game_name": "The King Panda",
                    "id": 784,
                    "image": "https://yard.gcsd.io/assets/games/5c13b7610d83c500118ab4c6/2019-08-02/logos/en/mobile.png",
                    "priority": 1,
                    "provider_id": 8,
                    "provider_name": "Booming Games",
                    "status": 1
                  },
                  {
                    "category": "Casino games",
                    "description": "",
                    "game_id": "5bf661bf80797a00112ea73c",
                    "game_name": "Cheeky Monkeys",
                    "id": 785,
                    "image": "https://yard.gcsd.io/assets/games/5bf661bf80797a00112ea73c/2021-06-23/logos/en/mobile.png",
                    "priority": 1,
                    "provider_id": 8,
                    "provider_name": "Booming Games",
                    "status": 1
                  },
                  {
                    "category": "Casino games",
                    "description": "",
                    "game_id": "5bf6611580797a00112ea731",
                    "game_name": "Show Me The Mummy",
                    "id": 786,
                    "image": "https://yard.gcsd.io/assets/games/5bf6611580797a00112ea731/2021-06-23/logos/en/mobile.png",
                    "priority": 1,
                    "provider_id": 8,
                    "provider_name": "Booming Games",
                    "status": 1
                  },
                  {
                    "category": "Casino games",
                    "description": "",
                    "game_id": "5bd80c17b4d3b9000f00001b",
                    "game_name": "VIP Filthy Riches",
                    "id": 787,
                    "image": "https://yard.gcsd.io/assets/games/5bd80c17b4d3b9000f00001b/2021-06-23/logos/en/mobile.png",
                    "priority": 1,
                    "provider_id": 8,
                    "provider_name": "Booming Games",
                    "status": 1
                  },
                  {
                    "category": "Casino games",
                    "description": "",
                    "game_id": "5bd2be026da6f1000e000004",
                    "game_name": "Rudolph's Ride",
                    "id": 788,
                    "image": "https://yard.gcsd.io/assets/games/5bd2be026da6f1000e000004/2021-06-23/logos/en/mobile.png",
                    "priority": 1,
                    "provider_id": 8,
                    "provider_name": "Booming Games",
                    "status": 1
                  },
                  {
                    "category": "Casino games",
                    "description": "",
                    "game_id": "5bc6f4a56b226f000e0000b6",
                    "game_name": "Barnyard Twister",
                    "id": 789,
                    "image": "https://yard.gcsd.io/assets/games/5bc6f4a56b226f000e0000b6/2021-06-23/logos/en/mobile.png",
                    "priority": 1,
                    "provider_id": 8,
                    "provider_name": "Booming Games",
                    "status": 1
                  },
                  {
                    "category": "Casino games",
                    "description": "",
                    "game_id": "5bc6f2806b226f000e0000ab",
                    "game_name": "Boomshakalaka",
                    "id": 790,
                    "image": "https://yard.gcsd.io/assets/games/5bc6f2806b226f000e0000ab/v2.9.0/logos/en/mobile.png",
                    "priority": 1,
                    "provider_id": 8,
                    "provider_name": "Booming Games",
                    "status": 1
                  },
                  {
                    "category": "Casino games",
                    "description": "",
                    "game_id": "5bb325cf6b226f000e000006",
                    "game_name": "Astro Pandas",
                    "id": 791,
                    "image": "https://yard.gcsd.io/assets/games/5bb325cf6b226f000e000006/2021-06-23/logos/en/mobile.png",
                    "priority": 1,
                    "provider_id": 8,
                    "provider_name": "Booming Games",
                    "status": 1
                  },
                  {
                    "category": "Casino games",
                    "description": "",
                    "game_id": "5b8f8fc5408b77001600015d",
                    "game_name": "Sugar Skulls",
                    "id": 792,
                    "image": "https://yard.gcsd.io/assets/games/5b8f8fc5408b77001600015d/2021-06-23/logos/en/mobile.png",
                    "priority": 1,
                    "provider_id": 8,
                    "provider_name": "Booming Games",
                    "status": 1
                  },
                  {
                    "category": "Casino games",
                    "description": "",
                    "game_id": "5b728cf4ad47ec000d00014f",
                    "game_name": "Feng Shui Kitties",
                    "id": 793,
                    "image": "https://yard.gcsd.io/assets/games/5b728cf4ad47ec000d00014f/2021-06-23/logos/en/mobile.png",
                    "priority": 1,
                    "provider_id": 8,
                    "provider_name": "Booming Games",
                    "status": 1
                  },
                  {
                    "category": "Casino games",
                    "description": "",
                    "game_id": "5b713f7fad47ec000d000125",
                    "game_name": "Bachelorette Party",
                    "id": 794,
                    "image": "https://yard.gcsd.io/assets/games/5b713f7fad47ec000d000125/2021-06-23/logos/en/mobile.png",
                    "priority": 1,
                    "provider_id": 8,
                    "provider_name": "Booming Games",
                    "status": 1
                  },
                  {
                    "category": "Casino games",
                    "description": "",
                    "game_id": "5b597da5ad47ec000d000098",
                    "game_name": "Wunderfest Deluxe",
                    "id": 795,
                    "image": "https://yard.gcsd.io/assets/games/5b597da5ad47ec000d000098/2021-06-23/logos/en/mobile.png",
                    "priority": 1,
                    "provider_id": 8,
                    "provider_name": "Booming Games",
                    "status": 1
                  },
                  {
                    "category": "Casino games",
                    "description": "",
                    "game_id": "5b558e83ad47ec000d000066",
                    "game_name": "Disco Bar 7s",
                    "id": 796,
                    "image": "https://yard.gcsd.io/assets/games/5b558e83ad47ec000d000066/2021-06-23/logos/en/mobile.png",
                    "priority": 1,
                    "provider_id": 8,
                    "provider_name": "Booming Games",
                    "status": 1
                  },
                  {
                    "category": "Casino games",
                    "description": "",
                    "game_id": "5b20da8ed62103000d000047",
                    "game_name": "Vegas VIP Gold",
                    "id": 797,
                    "image": "https://yard.gcsd.io/assets/games/5b20da8ed62103000d000047/2021-06-23/logos/en/mobile.png",
                    "priority": 1,
                    "provider_id": 8,
                    "provider_name": "Booming Games",
                    "status": 1
                  },
                  {
                    "category": "Casino games",
                    "description": "",
                    "game_id": "5ae1a410d62103001400003b",
                    "game_name": "Lava Loca",
                    "id": 798,
                    "image": "https://yard.gcsd.io/assets/games/5ae1a410d62103001400003b/2021-06-23/logos/en/mobile.png",
                    "priority": 1,
                    "provider_id": 8,
                    "provider_name": "Booming Games",
                    "status": 1
                  },
                  {
                    "category": "Casino games",
                    "description": "",
                    "game_id": "5ae1a3dcd621030014000030",
                    "game_name": "Winner's Cup",
                    "id": 799,
                    "image": "https://yard.gcsd.io/assets/games/5ae1a3dcd621030014000030/2021-06-23/logos/en/mobile.png",
                    "priority": 1,
                    "provider_id": 8,
                    "provider_name": "Booming Games",
                    "status": 1
                  },
                  {
                    "category": "Casino games",
                    "description": "",
                    "game_id": "5adf2463d62103001400000c",
                    "game_name": "Cuba Caliente",
                    "id": 800,
                    "image": "https://yard.gcsd.io/assets/games/5adf2463d62103001400000c/2021-06-23/logos/en/mobile.png",
                    "priority": 1,
                    "provider_id": 8,
                    "provider_name": "Booming Games",
                    "status": 1
                  },
                  {
                    "category": "Casino games",
                    "description": "",
                    "game_id": "5ac73df903726f000e000190",
                    "game_name": "Wild Diamond 7x",
                    "id": 801,
                    "image": "https://yard.gcsd.io/assets/games/5ac73df903726f000e000190/2021-06-23/logos/en/mobile.png",
                    "priority": 1,
                    "provider_id": 8,
                    "provider_name": "Booming Games",
                    "status": 1
                  },
                  {
                    "category": "Casino games",
                    "description": "",
                    "game_id": "5aba0fad03726f000e0000ec",
                    "game_name": "Boomerang Bonanza",
                    "id": 802,
                    "image": "https://yard.gcsd.io/assets/games/5aba0fad03726f000e0000ec/2021-06-23/logos/en/mobile.png",
                    "priority": 1,
                    "provider_id": 8,
                    "provider_name": "Booming Games",
                    "status": 1
                  },
                  {
                    "category": "Casino games",
                    "description": "",
                    "game_id": "5aa1197b03726f000e000002",
                    "game_name": "Baby Bloomers",
                    "id": 803,
                    "image": "https://yard.gcsd.io/assets/games/5aa1197b03726f000e000002/2021-06-23/logos/en/mobile.png",
                    "priority": 1,
                    "provider_id": 8,
                    "provider_name": "Booming Games",
                    "status": 1
                  },
                  {
                    "category": "Casino games",
                    "description": "",
                    "game_id": "5a8aaa3af9684f001d000014",
                    "game_name": "Miami Nights",
                    "id": 804,
                    "image": "https://yard.gcsd.io/assets/games/5a8aaa3af9684f001d000014/2021-06-23/logos/en/mobile.png",
                    "priority": 1,
                    "provider_id": 8,
                    "provider_name": "Booming Games",
                    "status": 1
                  },
                  {
                    "category": "Casino games",
                    "description": "",
                    "game_id": "5a82de13f9684f0020000012",
                    "game_name": "Taxi Movida",
                    "id": 805,
                    "image": "https://yard.gcsd.io/assets/games/5a82de13f9684f0020000012/2021-06-23/logos/en/mobile.png",
                    "priority": 1,
                    "provider_id": 8,
                    "provider_name": "Booming Games",
                    "status": 1
                  },
                  {
                    "category": "Casino games",
                    "description": "",
                    "game_id": "5a82b85ef9684f0023000014",
                    "game_name": "Mexico Wins",
                    "id": 806,
                    "image": "https://yard.gcsd.io/assets/games/5a82b85ef9684f0023000014/2021-06-23/logos/en/mobile.png",
                    "priority": 1,
                    "provider_id": 8,
                    "provider_name": "Booming Games",
                    "status": 1
                  },
                  {
                    "category": "Casino games",
                    "description": "",
                    "game_id": "5a65ae35136b4a0011000001",
                    "game_name": "Doublin Gold",
                    "id": 807,
                    "image": "https://yard.gcsd.io/assets/games/5a65ae35136b4a0011000001/2021-06-23/logos/en/mobile.png",
                    "priority": 1,
                    "provider_id": 8,
                    "provider_name": "Booming Games",
                    "status": 1
                  },
                  {
                    "category": "Casino games",
                    "description": "",
                    "game_id": "5a37d2585397390020000013",
                    "game_name": "Asia Wins",
                    "id": 808,
                    "image": "https://yard.gcsd.io/assets/games/5a37d2585397390020000013/2021-06-23/logos/en/mobile.png",
                    "priority": 1,
                    "provider_id": 8,
                    "provider_name": "Booming Games",
                    "status": 1
                  },
                  {
                    "category": "Casino games",
                    "description": "",
                    "game_id": "5a37d2355397390011000013",
                    "game_name": "Paris Nights",
                    "id": 809,
                    "image": "https://yard.gcsd.io/assets/games/5a37d2355397390011000013/2021-06-23/logos/en/mobile.png",
                    "priority": 1,
                    "provider_id": 8,
                    "provider_name": "Booming Games",
                    "status": 1
                  },
                  {
                    "category": "Casino games",
                    "description": "",
                    "game_id": "5a032814ed1496000800000f",
                    "game_name": "Big Apple Wins",
                    "id": 810,
                    "image": "https://yard.gcsd.io/assets/games/5a032814ed1496000800000f/2021-06-23/logos/en/mobile.png",
                    "priority": 1,
                    "provider_id": 8,
                    "provider_name": "Booming Games",
                    "status": 1
                  },
                  {
                    "category": "Casino games",
                    "description": "",
                    "game_id": "5a007c32ed1496001100000d",
                    "game_name": "Royal Wins",
                    "id": 811,
                    "image": "https://yard.gcsd.io/assets/games/5a007c32ed1496001100000d/2021-06-23/logos/en/mobile.png",
                    "priority": 1,
                    "provider_id": 8,
                    "provider_name": "Booming Games",
                    "status": 1
                  },
                  {
                    "category": "Casino games",
                    "description": "",
                    "game_id": "5a007babed14960023000015",
                    "game_name": "Romeo",
                    "id": 812,
                    "image": "https://yard.gcsd.io/assets/games/5a007babed14960023000015/2021-06-23/logos/en/mobile.png",
                    "priority": 1,
                    "provider_id": 8,
                    "provider_name": "Booming Games",
                    "status": 1
                  },
                  {
                    "category": "Casino games",
                    "description": "",
                    "game_id": "59d775109f4b700017000003",
                    "game_name": "Jingle Jingle",
                    "id": 813,
                    "image": "https://yard.gcsd.io/assets/games/59d775109f4b700017000003/2021-06-23/logos/en/mobile.png",
                    "priority": 1,
                    "provider_id": 8,
                    "provider_name": "Booming Games",
                    "status": 1
                  },
                  {
                    "category": "Casino games",
                    "description": "",
                    "game_id": "59b922f4bc5a81001d00000b",
                    "game_name": "Booming Bars",
                    "id": 814,
                    "image": "https://yard.gcsd.io/assets/games/59b922f4bc5a81001d00000b/2021-06-23/logos/en/mobile.png",
                    "priority": 1,
                    "provider_id": 8,
                    "provider_name": "Booming Games",
                    "status": 1
                  },
                  {
                    "category": "Casino games",
                    "description": "",
                    "game_id": "59b9221dbc5a81001700000a",
                    "game_name": "Wild Pride",
                    "id": 815,
                    "image": "https://yard.gcsd.io/assets/games/59b9221dbc5a81001700000a/2021-06-23/logos/en/mobile.png",
                    "priority": 1,
                    "provider_id": 8,
                    "provider_name": "Booming Games",
                    "status": 1
                  },
                  {
                    "category": "Casino games",
                    "description": "",
                    "game_id": "59a3d1f39624310015000021",
                    "game_name": "Wunderfest",
                    "id": 816,
                    "image": "https://yard.gcsd.io/assets/games/59a3d1f39624310015000021/2023-10-23/logos/en/mobile.png",
                    "priority": 1,
                    "provider_id": 8,
                    "provider_name": "Booming Games",
                    "status": 1
                  },
                  {
                    "category": "Casino games",
                    "description": "",
                    "game_id": "59677eea7d5d7c002400001c",
                    "game_name": "Vegas Wins",
                    "id": 817,
                    "image": "https://yard.gcsd.io/assets/games/59677eea7d5d7c002400001c/2021-06-23/logos/en/mobile.png",
                    "priority": 1,
                    "provider_id": 8,
                    "provider_name": "Booming Games",
                    "status": 1
                  },
                  {
                    "category": "Casino games",
                    "description": "",
                    "game_id": "594791455f97bf001b00000d",
                    "game_name": "Booming Bananas",
                    "id": 818,
                    "image": "https://yard.gcsd.io/assets/games/594791455f97bf001b00000d/2021-06-23/logos/en/mobile.png",
                    "priority": 1,
                    "provider_id": 8,
                    "provider_name": "Booming Games",
                    "status": 1
                  },
                  {
                    "category": "Casino games",
                    "description": "",
                    "game_id": "5947903c5f97bf001e000001",
                    "game_name": "Golden Girls",
                    "id": 819,
                    "image": "https://yard.gcsd.io/assets/games/5947903c5f97bf001e000001/2021-06-23/logos/en/mobile.png",
                    "priority": 1,
                    "provider_id": 8,
                    "provider_name": "Booming Games",
                    "status": 1
                  },
                  {
                    "category": "Casino games",
                    "description": "",
                    "game_id": "594780d85f97bf001b000001",
                    "game_name": "Golden Profits",
                    "id": 820,
                    "image": "https://yard.gcsd.io/assets/games/594780d85f97bf001b000001/2021-06-23/logos/en/mobile.png",
                    "priority": 1,
                    "provider_id": 8,
                    "provider_name": "Booming Games",
                    "status": 1
                  },
                  {
                    "category": "Casino games",
                    "description": "",
                    "game_id": "59316ea1f54229000900000c",
                    "game_name": "Valley of Pharaohs",
                    "id": 821,
                    "image": "https://yard.gcsd.io/assets/games/59316ea1f54229000900000c/2021-06-23/logos/en/mobile.png",
                    "priority": 1,
                    "provider_id": 8,
                    "provider_name": "Booming Games",
                    "status": 1
                  },
                  {
                    "category": "Casino games",
                    "description": "",
                    "game_id": "58f6115ce76b3f0020000001",
                    "game_name": "Hababam",
                    "id": 822,
                    "image": "https://yard.gcsd.io/assets/games/58f6115ce76b3f0020000001/2021-06-23/logos/en/mobile.png",
                    "priority": 1,
                    "provider_id": 8,
                    "provider_name": "Booming Games",
                    "status": 1
                  },
                  {
                    "category": "Casino games",
                    "description": "",
                    "game_id": "58de56997ce2b4002400000b",
                    "game_name": "Devil's Heat",
                    "id": 823,
                    "image": "https://yard.gcsd.io/assets/games/58de56997ce2b4002400000b/2021-06-23/logos/en/mobile.png",
                    "priority": 1,
                    "provider_id": 8,
                    "provider_name": "Booming Games",
                    "status": 1
                  },
                  {
                    "category": "Casino games",
                    "description": "",
                    "game_id": "58de0c477ce2b4002100000b",
                    "game_name": "Exotic Fruit Deluxe",
                    "id": 824,
                    "image": "https://yard.gcsd.io/assets/games/58de0c477ce2b4002100000b/2021-06-23/logos/en/mobile.png",
                    "priority": 1,
                    "provider_id": 8,
                    "provider_name": "Booming Games",
                    "status": 1
                  },
                  {
                    "category": "Casino games",
                    "description": "",
                    "game_id": "58de0c307ce2b4000c000009",
                    "game_name": "Exotic Fruit",
                    "id": 825,
                    "image": "https://yard.gcsd.io/assets/games/58de0c307ce2b4000c000009/2021-06-23/logos/en/mobile.png",
                    "priority": 1,
                    "provider_id": 8,
                    "provider_name": "Booming Games",
                    "status": 1
                  },
                  {
                    "category": "Casino games",
                    "description": "",
                    "game_id": "58b7ce30f2bb4e001f000020",
                    "game_name": "Cold Cash",
                    "id": 826,
                    "image": "https://yard.gcsd.io/assets/games/58b7ce30f2bb4e001f000020/2021-06-23/logos/en/mobile.png",
                    "priority": 1,
                    "provider_id": 8,
                    "provider_name": "Booming Games",
                    "status": 1
                  },
                  {
                    "category": "Casino games",
                    "description": "",
                    "game_id": "58b44628f2bb4e001f000007",
                    "game_name": "Wild Jester",
                    "id": 827,
                    "image": "https://yard.gcsd.io/assets/games/58b44628f2bb4e001f000007/2021-06-23/logos/en/mobile.png",
                    "priority": 1,
                    "provider_id": 8,
                    "provider_name": "Booming Games",
                    "status": 1
                  },
                  {
                    "category": "Casino games",
                    "description": "",
                    "game_id": "58b44605f2bb4e0022000003",
                    "game_name": "Genie Wishes",
                    "id": 828,
                    "image": "https://yard.gcsd.io/assets/games/58b44605f2bb4e0022000003/2021-06-23/logos/en/mobile.png",
                    "priority": 1,
                    "provider_id": 8,
                    "provider_name": "Booming Games",
                    "status": 1
                  },
                  {
                    "category": "Casino games",
                    "description": "",
                    "game_id": "58a1bcafe6b8ee0015000010",
                    "game_name": "Dolphin's Luck 2",
                    "id": 829,
                    "image": "https://yard.gcsd.io/assets/games/58a1bcafe6b8ee0015000010/2021-06-23/logos/en/mobile.png",
                    "priority": 1,
                    "provider_id": 8,
                    "provider_name": "Booming Games",
                    "status": 1
                  },
                  {
                    "category": "Casino games",
                    "description": "",
                    "game_id": "58985095bf53460009000001",
                    "game_name": "Cherry Bomb Deluxe",
                    "id": 830,
                    "image": "https://yard.gcsd.io/assets/games/58985095bf53460009000001/2021-06-23/logos/en/mobile.png",
                    "priority": 1,
                    "provider_id": 8,
                    "provider_name": "Booming Games",
                    "status": 1
                  },
                  {
                    "category": "Casino games",
                    "description": "",
                    "game_id": "5889c0f7b7e88a001b000002",
                    "game_name": "Monster Munchies",
                    "id": 831,
                    "image": "https://yard.gcsd.io/assets/games/5889c0f7b7e88a001b000002/2021-06-23/logos/en/mobile.png",
                    "priority": 1,
                    "provider_id": 8,
                    "provider_name": "Booming Games",
                    "status": 1
                  },
                  {
                    "category": "Casino games",
                    "description": "",
                    "game_id": "582b2e00252f290732000009",
                    "game_name": "Santa's Kiss",
                    "id": 832,
                    "image": "https://yard.gcsd.io/assets/games/582b2e00252f290732000009/2021-06-23/logos/en/mobile.png",
                    "priority": 1,
                    "provider_id": 8,
                    "provider_name": "Booming Games",
                    "status": 1
                  },
                  {
                    "category": "Casino games",
                    "description": "",
                    "game_id": "58231b3d381e0c00c400000d",
                    "game_name": "Booming Seven Deluxe",
                    "id": 833,
                    "image": "https://yard.gcsd.io/assets/games/58231b3d381e0c00c400000d/2021-06-23/logos/en/mobile.png",
                    "priority": 1,
                    "provider_id": 8,
                    "provider_name": "Booming Games",
                    "status": 1
                  },
                  {
                    "category": "Casino games",
                    "description": "",
                    "game_id": "580dc69068712a00a9000018",
                    "game_name": "Classico",
                    "id": 834,
                    "image": "https://yard.gcsd.io/assets/games/580dc69068712a00a9000018/2021-06-23/logos/en/mobile.png",
                    "priority": 1,
                    "provider_id": 8,
                    "provider_name": "Booming Games",
                    "status": 1
                  },
                  {
                    "category": "Casino games",
                    "description": "",
                    "game_id": "57f351a07d203600c0000020",
                    "game_name": "Wild Cherries",
                    "id": 835,
                    "image": "https://yard.gcsd.io/assets/games/57f351a07d203600c0000020/2021-06-23/logos/en/mobile.png",
                    "priority": 1,
                    "provider_id": 8,
                    "provider_name": "Booming Games",
                    "status": 1
                  },
                  {
                    "category": "Casino games",
                    "description": "",
                    "game_id": "57e10619f6612200bb000005",
                    "game_name": "Harvest Fest",
                    "id": 836,
                    "image": "https://yard.gcsd.io/assets/games/57e10619f6612200bb000005/2021-06-23/logos/en/mobile.png",
                    "priority": 1,
                    "provider_id": 8,
                    "provider_name": "Booming Games",
                    "status": 1
                  },
                  {
                    "category": "Casino games",
                    "description": "",
                    "game_id": "57ced064d9a46400ac000001",
                    "game_name": "Octoberfest",
                    "id": 837,
                    "image": "https://yard.gcsd.io/assets/games/57ced064d9a46400ac000001/2021-06-23/logos/en/mobile.png",
                    "priority": 1,
                    "provider_id": 8,
                    "provider_name": "Booming Games",
                    "status": 1
                  },
                  {
                    "category": "Casino games",
                    "description": "",
                    "game_id": "57c93b79f6349f00b2000001",
                    "game_name": "Tailgating",
                    "id": 838,
                    "image": "https://yard.gcsd.io/assets/games/57c93b79f6349f00b2000001/2021-06-23/logos/en/mobile.png",
                    "priority": 1,
                    "provider_id": 8,
                    "provider_name": "Booming Games",
                    "status": 1
                  },
                  {
                    "category": "Casino games",
                    "description": "",
                    "game_id": "57bc527a9eaf6900af000001",
                    "game_name": "Gangster Gamblers",
                    "id": 839,
                    "image": "https://yard.gcsd.io/assets/games/57bc527a9eaf6900af000001/2021-06-23/logos/en/mobile.png",
                    "priority": 1,
                    "provider_id": 8,
                    "provider_name": "Booming Games",
                    "status": 1
                  },
                  {
                    "category": "Casino games",
                    "description": "",
                    "game_id": "57b6c85607c76500ac000001",
                    "game_name": "Desert Drag",
                    "id": 840,
                    "image": "https://yard.gcsd.io/assets/games/57b6c85607c76500ac000001/2021-06-23/logos/en/mobile.png",
                    "priority": 1,
                    "provider_id": 8,
                    "provider_name": "Booming Games",
                    "status": 1
                  },
                  {
                    "category": "Casino games",
                    "description": "",
                    "game_id": "57a05d744d809100bb000005",
                    "game_name": "Freemason's Fortune",
                    "id": 841,
                    "image": "https://yard.gcsd.io/assets/games/57a05d744d809100bb000005/2021-06-23/logos/en/mobile.png",
                    "priority": 1,
                    "provider_id": 8,
                    "provider_name": "Booming Games",
                    "status": 1
                  },
                  {
                    "category": "Casino games",
                    "description": "",
                    "game_id": "57725e5894de7b00bb00000c",
                    "game_name": "La Romantica",
                    "id": 842,
                    "image": "https://yard.gcsd.io/assets/games/57725e5894de7b00bb00000c/2021-06-23/logos/en/mobile.png",
                    "priority": 1,
                    "provider_id": 8,
                    "provider_name": "Booming Games",
                    "status": 1
                  },
                  {
                    "category": "Casino games",
                    "description": "",
                    "game_id": "5759424b86a34000af00008e",
                    "game_name": "Shark Meet",
                    "id": 843,
                    "image": "https://yard.gcsd.io/assets/games/5759424b86a34000af00008e/2021-06-25/logos/en/mobile.png",
                    "priority": 1,
                    "provider_id": 8,
                    "provider_name": "Booming Games",
                    "status": 1
                  },
                  {
                    "category": "Casino games",
                    "description": "",
                    "game_id": "5748216e86a34000b5000055",
                    "game_name": "Goal!!!",
                    "id": 844,
                    "image": "https://yard.gcsd.io/assets/games/5748216e86a34000b5000055/2021-06-23/logos/en/mobile.png",
                    "priority": 1,
                    "provider_id": 8,
                    "provider_name": "Booming Games",
                    "status": 1
                  },
                  {
                    "category": "Casino games",
                    "description": "",
                    "game_id": "573c163f86a34000a9000016",
                    "game_name": "Legend of Qu Yuan",
                    "id": 845,
                    "image": "https://yard.gcsd.io/assets/games/573c163f86a34000a9000016/2021-06-25/logos/en/mobile.png",
                    "priority": 1,
                    "provider_id": 8,
                    "provider_name": "Booming Games",
                    "status": 1
                  },
                  {
                    "category": "Casino games",
                    "description": "",
                    "game_id": "57064dba056ac568bf00010f",
                    "game_name": "Revolution!",
                    "id": 846,
                    "image": "https://yard.gcsd.io/assets/games/57064dba056ac568bf00010f/2021-06-25/logos/en/mobile.png",
                    "priority": 1,
                    "provider_id": 8,
                    "provider_name": "Booming Games",
                    "status": 1
                  },
                  {
                    "category": "Casino games",
                    "description": "",
                    "game_id": "57064b77056ac568c200010e",
                    "game_name": "Cinco de Mayo",
                    "id": 847,
                    "image": "https://yard.gcsd.io/assets/games/57064b77056ac568c200010e/2021-06-23/logos/en/mobile.png",
                    "priority": 1,
                    "provider_id": 8,
                    "provider_name": "Booming Games",
                    "status": 1
                  },
                  {
                    "category": "Casino games",
                    "description": "",
                    "game_id": "56d44327056ac5443e000011",
                    "game_name": "Zoodiac",
                    "id": 848,
                    "image": "https://yard.gcsd.io/assets/games/56d44327056ac5443e000011/2021-06-23/logos/en/mobile.png",
                    "priority": 1,
                    "provider_id": 8,
                    "provider_name": "Booming Games",
                    "status": 1
                  },
                  {
                    "category": "Casino games",
                    "description": "",
                    "game_id": "56d4423e056ac53938000011",
                    "game_name": "Heroes and Beasts",
                    "id": 849,
                    "image": "https://yard.gcsd.io/assets/games/56d4423e056ac53938000011/2021-06-23/logos/en/mobile.png",
                    "priority": 1,
                    "provider_id": 8,
                    "provider_name": "Booming Games",
                    "status": 1
                  },
                  {
                    "category": "Casino games",
                    "description": "",
                    "game_id": "56d40730056ac5443e000001",
                    "game_name": "Oh Catrina!",
                    "id": 850,
                    "image": "https://yard.gcsd.io/assets/games/56d40730056ac5443e000001/2021-06-23/logos/en/mobile.png",
                    "priority": 1,
                    "provider_id": 8,
                    "provider_name": "Booming Games",
                    "status": 1
                  },
                  {
                    "category": "Casino games",
                    "description": "",
                    "game_id": "5697a459056ac55082000001",
                    "game_name": "Booming Gold",
                    "id": 851,
                    "image": "https://yard.gcsd.io/assets/games/5697a459056ac55082000001/2021-06-23/logos/en/mobile.png",
                    "priority": 1,
                    "provider_id": 8,
                    "provider_name": "Booming Games",
                    "status": 1
                  },
                  {
                    "category": "Casino games",
                    "description": "",
                    "game_id": "56979f1e056ac55085000001",
                    "game_name": "Chicago Nights",
                    "id": 852,
                    "image": "https://yard.gcsd.io/assets/games/56979f1e056ac55085000001/2021-06-23/logos/en/mobile.png",
                    "priority": 1,
                    "provider_id": 8,
                    "provider_name": "Booming Games",
                    "status": 1
                  },
                  {
                    "category": "Casino games",
                    "description": "",
                    "game_id": "56570ba7056ac57044000001",
                    "game_name": "Merry Spinning",
                    "id": 853,
                    "image": "https://yard.gcsd.io/assets/games/56570ba7056ac57044000001/2021-06-25/logos/en/mobile.png",
                    "priority": 1,
                    "provider_id": 8,
                    "provider_name": "Booming Games",
                    "status": 1
                  },
                  {
                    "category": "Casino games",
                    "description": "",
                    "game_id": "56570b98056ac57049000003",
                    "game_name": "Lotus Love",
                    "id": 854,
                    "image": "https://yard.gcsd.io/assets/games/56570b98056ac57049000003/2021-06-23/logos/en/mobile.png",
                    "priority": 1,
                    "provider_id": 8,
                    "provider_name": "Booming Games",
                    "status": 1
                  },
                  {
                    "category": "Casino games",
                    "description": "",
                    "game_id": "56570b77056ac57049000002",
                    "game_name": "Galactic Speedway",
                    "id": 855,
                    "image": "https://yard.gcsd.io/assets/games/56570b77056ac57049000002/2021-06-23/logos/en/mobile.png",
                    "priority": 1,
                    "provider_id": 8,
                    "provider_name": "Booming Games",
                    "status": 1
                  },
                  {
                    "category": "Casino games",
                    "description": "",
                    "game_id": "56264967056ac513af000001",
                    "game_name": "Joker's Wild",
                    "id": 856,
                    "image": "https://yard.gcsd.io/assets/games/56264967056ac513af000001/2021-06-23/logos/en/mobile.png",
                    "priority": 1,
                    "provider_id": 8,
                    "provider_name": "Booming Games",
                    "status": 1
                  },
                  {
                    "category": "Casino games",
                    "description": "",
                    "game_id": "55f2dc09ba36f81816000001",
                    "game_name": "Booming Seven",
                    "id": 857,
                    "image": "https://yard.gcsd.io/assets/games/55f2dc09ba36f81816000001/2021-06-23/logos/en/mobile.png",
                    "priority": 1,
                    "provider_id": 8,
                    "provider_name": "Booming Games",
                    "status": 1
                  },
                  {
                    "category": "Casino games",
                    "description": "",
                    "game_id": "55b78ba2ba36f83c52000001",
                    "game_name": "Stellar Stones",
                    "id": 858,
                    "image": "https://yard.gcsd.io/assets/games/55b78ba2ba36f83c52000001/2021-06-23/logos/en/mobile.png",
                    "priority": 1,
                    "provider_id": 8,
                    "provider_name": "Booming Games",
                    "status": 1
                  },
                  {
                    "category": "Casino games",
                    "description": "",
                    "game_id": "5559cb33ba36f855db000001",
                    "game_name": "Little Goblins",
                    "id": 859,
                    "image": "https://yard.gcsd.io/assets/games/5559cb33ba36f855db000001/2021-06-23/logos/en/mobile.png",
                    "priority": 1,
                    "provider_id": 8,
                    "provider_name": "Booming Games",
                    "status": 1
                  },
                  {
                    "category": "Casino games",
                    "description": "",
                    "game_id": "5511caa0056ac57607002bd0",
                    "game_name": "Cherry Bomb",
                    "id": 860,
                    "image": "https://yard.gcsd.io/assets/games/5511caa0056ac57607002bd0/2021-06-23/logos/en/mobile.png",
                    "priority": 1,
                    "provider_id": 8,
                    "provider_name": "Booming Games",
                    "status": 1
                  }
                ]
              },
              {
                "id": 1,
                "name": "Crash",
                "priority": 10,
                "status": 1,
                "games": [
                  {
                    "category": "Crash",
                    "description": "",
                    "game_id": "aviator",
                    "game_name": "Aviator",
                    "id": 674,
                    "image": "https://storage.googleapis.com/maybet/spribe/aviator.png",
                    "priority": 100,
                    "provider_id": 7,
                    "provider_name": "Spribe",
                    "status": 1
                  },
                  {
                    "category": "Crash",
                    "description": "",
                    "game_id": "rocketman",
                    "game_name": "Rocket Man",
                    "id": 667,
                    "image": "https://storage.googleapis.com/maybet/elbet/rocketman.png",
                    "priority": 99,
                    "provider_id": 4,
                    "provider_name": "Elbet",
                    "status": 1
                  },
                  {
                    "category": "Crash",
                    "description": "",
                    "game_id": "JetX",
                    "game_name": "JetX",
                    "id": 27,
                    "image": "https://storage.googleapis.com/maybet/smartsoft/JetX.png",
                    "priority": 98,
                    "provider_id": 5,
                    "provider_name": "Smartsoft Gaming",
                    "status": 1
                  },
                  {
                    "category": "Crash",
                    "description": "",
                    "game_id": "1",
                    "game_name": "Comet",
                    "id": 1,
                    "image": "https://stgameswesteu.blob.core.windows.net/strapi-uploads/assets/gameicon_comet_crash_x3_3fac109cdf.png",
                    "priority": 95,
                    "provider_id": 3,
                    "provider_name": "STP",
                    "status": 1
                  },
                  {
                    "category": "Crash",
                    "description": "",
                    "game_id": "JetX3",
                    "game_name": "JetX3",
                    "id": 38,
                    "image": "https://storage.googleapis.com/maybet/smartsoft/JetX3.png",
                    "priority": 90,
                    "provider_id": 5,
                    "provider_name": "Smartsoft Gaming",
                    "status": 1
                  },
                  {
                    "category": "Crash",
                    "description": "",
                    "game_id": "FootballX",
                    "game_name": "FootballX",
                    "id": 31,
                    "image": "https://storage.googleapis.com/maybet/smartsoft/FootballX.png",
                    "priority": 56,
                    "provider_id": 5,
                    "provider_name": "Smartsoft Gaming",
                    "status": 1
                  },
                  {
                    "category": "Crash",
                    "description": "",
                    "game_id": "SpinX",
                    "game_name": "SpinX",
                    "id": 22,
                    "image": "https://storage.googleapis.com/maybet/smartsoft/SpinX.png",
                    "priority": 10,
                    "provider_id": 5,
                    "provider_name": "Smartsoft Gaming",
                    "status": 1
                  },
                  {
                    "category": "Crash",
                    "description": "",
                    "game_id": "TowerX",
                    "game_name": "TowerX",
                    "id": 23,
                    "image": "https://storage.googleapis.com/maybet/smartsoft/TowerX.png",
                    "priority": 10,
                    "provider_id": 5,
                    "provider_name": "Smartsoft Gaming",
                    "status": 1
                  },
                  {
                    "category": "Crash",
                    "description": "",
                    "game_id": "4",
                    "game_name": "Taxi Ride",
                    "id": 4,
                    "image": "https://stgameswesteu.blob.core.windows.net/strapi-uploads/assets/gameicon_taxi_ride_x3_e0ce63211f.png",
                    "priority": 1,
                    "provider_id": 3,
                    "provider_name": "STP",
                    "status": 1
                  },
                  {
                    "category": "Crash",
                    "description": "",
                    "game_id": "14",
                    "game_name": "Rugby Run",
                    "id": 14,
                    "image": "https://stgameswesteu.blob.core.windows.net/strapi-uploads/assets/gameicon_rugby_x3_5c31de72bf.png",
                    "priority": 1,
                    "provider_id": 3,
                    "provider_name": "STP",
                    "status": 1
                  },
                  {
                    "category": "Crash",
                    "description": "",
                    "game_id": "CrazyHuntX",
                    "game_name": "Crazy HuntX",
                    "id": 17,
                    "image": "https://storage.googleapis.com/maybet/smartsoft/CrazyHuntX.png",
                    "priority": 1,
                    "provider_id": 5,
                    "provider_name": "Smartsoft Gaming",
                    "status": 1
                  },
                  {
                    "category": "Crash",
                    "description": "",
                    "game_id": "DoubleX",
                    "game_name": "DoubleX",
                    "id": 18,
                    "image": "https://storage.googleapis.com/maybet/smartsoft/DoubleX.png",
                    "priority": 1,
                    "provider_id": 5,
                    "provider_name": "Smartsoft Gaming",
                    "status": 1
                  },
                  {
                    "category": "Crash",
                    "description": "",
                    "game_id": "CricketX",
                    "game_name": "CricketX",
                    "id": 28,
                    "image": "https://storage.googleapis.com/maybet/smartsoft/CricketX.png",
                    "priority": 1,
                    "provider_id": 5,
                    "provider_name": "Smartsoft Gaming",
                    "status": 1
                  },
                  {
                    "category": "Crash",
                    "description": "",
                    "game_id": "HelicopterX",
                    "game_name": "HelicopterX",
                    "id": 32,
                    "image": "https://storage.googleapis.com/maybet/smartsoft/HelicopterX.png",
                    "priority": 1,
                    "provider_id": 5,
                    "provider_name": "Smartsoft Gaming",
                    "status": 1
                  },
                  {
                    "category": "Crash",
                    "description": "",
                    "game_id": "SmashX",
                    "game_name": "Smash X",
                    "id": 33,
                    "image": "https://storage.googleapis.com/maybet/smartsoft/SmashX.png",
                    "priority": 1,
                    "provider_id": 5,
                    "provider_name": "Smartsoft Gaming",
                    "status": 1
                  },
                  {
                    "category": "Crash",
                    "description": "",
                    "game_id": "Cappadocia",
                    "game_name": "Cappadocia",
                    "id": 37,
                    "image": "https://storage.googleapis.com/maybet/smartsoft/Cappadocia.png",
                    "priority": 1,
                    "provider_id": 5,
                    "provider_name": "Smartsoft Gaming",
                    "status": 1
                  },
                  {
                    "category": "Crash",
                    "description": "",
                    "game_id": "Balloon",
                    "game_name": "Balloon",
                    "id": 39,
                    "image": "https://storage.googleapis.com/maybet/smartsoft/Balloon.png",
                    "priority": 1,
                    "provider_id": 5,
                    "provider_name": "Smartsoft Gaming",
                    "status": 1
                  },
                  {
                    "category": "Crash",
                    "description": "",
                    "game_id": "HunterX",
                    "game_name": "HunterX",
                    "id": 40,
                    "image": "https://storage.googleapis.com/maybet/smartsoft/HunterX.png",
                    "priority": 1,
                    "provider_id": 5,
                    "provider_name": "Smartsoft Gaming",
                    "status": 1
                  },
                  {
                    "category": "Crash",
                    "description": "",
                    "game_id": "Jokers4Bonuses",
                    "game_name": "Joker's 4 Bonuses",
                    "id": 51,
                    "image": "https://storage.googleapis.com/maybet/smartsoft/Jokers4Bonuses.png",
                    "priority": 1,
                    "provider_id": 5,
                    "provider_name": "Smartsoft Gaming",
                    "status": 1
                  },
                  {
                    "category": "Crash",
                    "description": "",
                    "game_id": "MineIsland",
                    "game_name": "MineIsland",
                    "id": 61,
                    "image": "https://storage.googleapis.com/maybet/smartsoft/MineIsland.png",
                    "priority": 1,
                    "provider_id": 5,
                    "provider_name": "Smartsoft Gaming",
                    "status": 1
                  },
                  {
                    "category": "Crash",
                    "description": "",
                    "game_id": "CrashDuelX",
                    "game_name": "Crash Duel X",
                    "id": 66,
                    "image": "https://storage.googleapis.com/maybet/smartsoft/CrashDuelX.png",
                    "priority": 1,
                    "provider_id": 5,
                    "provider_name": "Smartsoft Gaming",
                    "status": 1
                  },
                  {
                    "category": "Crash",
                    "description": "",
                    "game_id": "dice",
                    "game_name": "Dice",
                    "id": 669,
                    "image": "https://storage.googleapis.com/maybet/spribe/dice.png",
                    "priority": 1,
                    "provider_id": 7,
                    "provider_name": "Spribe",
                    "status": 1
                  },
                  {
                    "category": "Crash",
                    "description": "",
                    "game_id": "goal",
                    "game_name": "Goal",
                    "id": 670,
                    "image": "https://storage.googleapis.com/maybet/spribe/goal.png",
                    "priority": 1,
                    "provider_id": 7,
                    "provider_name": "Spribe",
                    "status": 1
                  },
                  {
                    "category": "Crash",
                    "description": "",
                    "game_id": "plinko",
                    "game_name": "Plinko",
                    "id": 671,
                    "image": "https://storage.googleapis.com/maybet/spribe/plinko.png",
                    "priority": 1,
                    "provider_id": 7,
                    "provider_name": "Spribe",
                    "status": 1
                  },
                  {
                    "category": "Crash",
                    "description": "",
                    "game_id": "keno",
                    "game_name": "Keno",
                    "id": 672,
                    "image": "https://storage.googleapis.com/maybet/spribe/keno.png",
                    "priority": 1,
                    "provider_id": 7,
                    "provider_name": "Spribe",
                    "status": 1
                  },
                  {
                    "category": "Crash",
                    "description": "",
                    "game_id": "mini-roulette",
                    "game_name": "Mini Roulette",
                    "id": 673,
                    "image": "https://storage.googleapis.com/maybet/spribe/mini-roulette.png",
                    "priority": 1,
                    "provider_id": 7,
                    "provider_name": "Spribe",
                    "status": 1
                  },
                  {
                    "category": "Crash",
                    "description": "",
                    "game_id": "mines",
                    "game_name": "Mines",
                    "id": 675,
                    "image": "https://storage.googleapis.com/maybet/spribe/mines.png",
                    "priority": 1,
                    "provider_id": 7,
                    "provider_name": "Spribe",
                    "status": 1
                  },
                  {
                    "category": "Crash",
                    "description": "",
                    "game_id": "hi-lo",
                    "game_name": "Hi Lo",
                    "id": 676,
                    "image": "https://storage.googleapis.com/maybet/spribe/hi-lo.png",
                    "priority": 1,
                    "provider_id": 7,
                    "provider_name": "Spribe",
                    "status": 1
                  },
                  {
                    "category": "Crash",
                    "description": "",
                    "game_id": "hotline",
                    "game_name": "Hotline",
                    "id": 677,
                    "image": "https://storage.googleapis.com/maybet/spribe/hotline.png",
                    "priority": 1,
                    "provider_id": 7,
                    "provider_name": "Spribe",
                    "status": 1
                  }
                ]
              },
              {
                "id": 20,
                "name": "Favorites",
                "priority": 0,
                "status": 1,
                "games": [
                  {
                    "category": "Favorites",
                    "description": "",
                    "game_id": "2",
                    "game_name": "Gold Rush",
                    "id": 2,
                    "image": "https://stgameswesteu.blob.core.windows.net/strapi-uploads/assets/gameicon_gold_rush_x3_671b69a39e.png",
                    "priority": 1,
                    "provider_id": 3,
                    "provider_name": "STP",
                    "status": 1
                  },
                  {
                    "category": "Favorites",
                    "description": "",
                    "game_id": "TheKingdomOfTheElves",
                    "game_name": "The Kingdom of The Elves",
                    "id": 20,
                    "image": "https://storage.googleapis.com/maybet/smartsoft/TheKingdomOfTheElves.png",
                    "priority": 1,
                    "provider_id": 5,
                    "provider_name": "Smartsoft Gaming",
                    "status": 1
                  },
                  {
                    "category": "Favorites",
                    "description": "",
                    "game_id": "vs20stckwldsc",
                    "game_name": "Pot of Fortune",
                    "id": 72,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vs20stckwldsc.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  }
                ]
              },
              {
                "id": 22,
                "name": "Virtual",
                "priority": 0,
                "status": 1,
                "games": [
                  {
                    "category": "Virtual",
                    "description": "",
                    "game_id": "10203",
                    "game_name": "Horses 6",
                    "id": 861,
                    "image": "https://cdn.xpressgaming.net/images/47_8244677.png",
                    "priority": 1,
                    "provider_id": 9,
                    "provider_name": "Golden races",
                    "status": 1
                  },
                  {
                    "category": "Virtual",
                    "description": "",
                    "game_id": "10166",
                    "game_name": "Keno - On Demand",
                    "id": 863,
                    "image": "https://cdn.xpressgaming.net/images/47_2416037.png",
                    "priority": 1,
                    "provider_id": 9,
                    "provider_name": "Golden races",
                    "status": 1
                  },
                  {
                    "category": "Virtual",
                    "description": "",
                    "game_id": "10111",
                    "game_name": "Horse Races",
                    "id": 864,
                    "image": "https://cdn.xpressgaming.net/images/47_4694846.png",
                    "priority": 1,
                    "provider_id": 9,
                    "provider_name": "Golden races",
                    "status": 1
                  },
                  {
                    "category": "Virtual",
                    "description": "",
                    "game_id": "10174",
                    "game_name": "Dog 6",
                    "id": 865,
                    "image": "https://cdn.xpressgaming.net/images/47_6421164.png",
                    "priority": 1,
                    "provider_id": 9,
                    "provider_name": "Golden races",
                    "status": 1
                  },
                  {
                    "category": "Virtual",
                    "description": "",
                    "game_id": "10159",
                    "game_name": "Spin2Win",
                    "id": 867,
                    "image": "https://cdn.xpressgaming.net/images/47_9868579.png",
                    "priority": 1,
                    "provider_id": 9,
                    "provider_name": "Golden races",
                    "status": 1
                  },
                  {
                    "category": "Virtual",
                    "description": "",
                    "game_id": "10110",
                    "game_name": "Dog Races",
                    "id": 868,
                    "image": "https://cdn.xpressgaming.net/images/47_1662251.png",
                    "priority": 1,
                    "provider_id": 9,
                    "provider_name": "Golden races",
                    "status": 1
                  },
                  {
                    "category": "Virtual",
                    "description": "",
                    "game_id": "10100",
                    "game_name": "Goldenrace",
                    "id": 869,
                    "image": "https://cdn.xpressgaming.net/images/47_8323692.png",
                    "priority": 1,
                    "provider_id": 9,
                    "provider_name": "Golden races",
                    "status": 1
                  },
                  {
                    "category": "Virtual",
                    "description": "",
                    "game_id": "10189",
                    "game_name": "Champions - ondemand",
                    "id": 870,
                    "image": "https://cdn.xpressgaming.net/images/47_3947312.png",
                    "priority": 1,
                    "provider_id": 9,
                    "provider_name": "Golden races",
                    "status": 1
                  },
                  {
                    "category": "Virtual",
                    "description": "",
                    "game_id": "10105",
                    "game_name": "Football",
                    "id": 871,
                    "image": "https://cdn.xpressgaming.net/images/47_4390411.png",
                    "priority": 1,
                    "provider_id": 9,
                    "provider_name": "Golden races",
                    "status": 1
                  },
                  {
                    "category": "Virtual",
                    "description": "",
                    "game_id": "10401",
                    "game_name": "Number Games",
                    "id": 872,
                    "image": "https://cdn.xpress-ix.com/images/_2306320.png",
                    "priority": 1,
                    "provider_id": 9,
                    "provider_name": "Golden races",
                    "status": 1
                  },
                  {
                    "category": "Virtual",
                    "description": "",
                    "game_id": "10282",
                    "game_name": "Spain League - ondemand",
                    "id": 873,
                    "image": "https://cdn.xpressgaming.net/images/47_183402.png",
                    "priority": 1,
                    "provider_id": 9,
                    "provider_name": "Golden races",
                    "status": 1
                  },
                  {
                    "category": "Virtual",
                    "description": "",
                    "game_id": "10176",
                    "game_name": "Italia League",
                    "id": 874,
                    "image": "https://cdn.xpressgaming.net/images/47_9313552.png",
                    "priority": 1,
                    "provider_id": 9,
                    "provider_name": "Golden races",
                    "status": 1
                  },
                  {
                    "category": "Virtual",
                    "description": "",
                    "game_id": "10191",
                    "game_name": "England League - ondemand",
                    "id": 875,
                    "image": "https://cdn.xpressgaming.net/images/47_1006699.png",
                    "priority": 1,
                    "provider_id": 9,
                    "provider_name": "Golden races",
                    "status": 1
                  },
                  {
                    "category": "Virtual",
                    "description": "",
                    "game_id": "10190",
                    "game_name": "Italy League - ondemand",
                    "id": 876,
                    "image": "https://cdn.xpressgaming.net/images/47_9481749.png",
                    "priority": 1,
                    "provider_id": 9,
                    "provider_name": "Golden races",
                    "status": 1
                  },
                  {
                    "category": "Virtual",
                    "description": "",
                    "game_id": "10158",
                    "game_name": "Champions",
                    "id": 877,
                    "image": "https://cdn.xpressgaming.net/images/47_3642540.png",
                    "priority": 1,
                    "provider_id": 9,
                    "provider_name": "Golden races",
                    "status": 1
                  },
                  {
                    "category": "Virtual",
                    "description": "",
                    "game_id": "10179",
                    "game_name": "Spain League",
                    "id": 878,
                    "image": "https://cdn.xpressgaming.net/images/47_7871526.png",
                    "priority": 1,
                    "provider_id": 9,
                    "provider_name": "Golden races",
                    "status": 1
                  },
                  {
                    "category": "Virtual",
                    "description": "",
                    "game_id": "10114",
                    "game_name": "Instant Sports",
                    "id": 879,
                    "image": "https://cdn.xpressgaming.net/images/47_2091191.png",
                    "priority": 1,
                    "provider_id": 9,
                    "provider_name": "Golden races",
                    "status": 1
                  },
                  {
                    "category": "Virtual",
                    "description": "",
                    "game_id": "10177",
                    "game_name": "England League",
                    "id": 880,
                    "image": "https://cdn.xpressgaming.net/images/47_6627174.png",
                    "priority": 1,
                    "provider_id": 9,
                    "provider_name": "Golden races",
                    "status": 1
                  }
                ]
              },
              {
                "id": 14,
                "name": "Roulette",
                "priority": 0,
                "status": 1,
                "games": [
                  {
                    "category": "Roulette",
                    "description": "",
                    "game_id": "rla",
                    "game_name": "Roulette",
                    "id": 641,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/rla.png",
                    "priority": 99,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Roulette",
                    "description": "",
                    "game_id": "14",
                    "game_name": "Rugby Run",
                    "id": 14,
                    "image": "https://stgameswesteu.blob.core.windows.net/strapi-uploads/assets/gameicon_rugby_x3_5c31de72bf.png",
                    "priority": 1,
                    "provider_id": 3,
                    "provider_name": "STP",
                    "status": 1
                  }
                ]
              },
              {
                "id": 13,
                "name": "Video Poker",
                "priority": 0,
                "status": 1,
                "games": [
                  {
                    "category": "Video Poker",
                    "description": "",
                    "game_id": "13",
                    "game_name": "Rock Paper Scissors",
                    "id": 13,
                    "image": "https://stgameswesteu.blob.core.windows.net/strapi-uploads/assets/gameicon_rps_x3_7bb476c3cb.png",
                    "priority": 1,
                    "provider_id": 3,
                    "provider_name": "STP",
                    "status": 1
                  },
                  {
                    "category": "Video Poker",
                    "description": "",
                    "game_id": "vpa",
                    "game_name": "Jacks or Better",
                    "id": 632,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/vpa.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Video Poker",
                    "description": "",
                    "game_id": "poker",
                    "game_name": "Poker",
                    "id": 678,
                    "image": "https://storage.googleapis.com/maybet/spribe/poker.png",
                    "priority": 1,
                    "provider_id": 7,
                    "provider_name": "Spribe",
                    "status": 1
                  }
                ]
              },
              {
                "id": 12,
                "name": "Scratch card",
                "priority": 0,
                "status": 1,
                "games": [
                  {
                    "category": "Scratch card",
                    "description": "",
                    "game_id": "12",
                    "game_name": "Archeo",
                    "id": 12,
                    "image": "https://stgameswesteu.blob.core.windows.net/strapi-uploads/assets/gameicon_archeo_x3_7314ed5561.png",
                    "priority": 1,
                    "provider_id": 3,
                    "provider_name": "STP",
                    "status": 1
                  },
                  {
                    "category": "Scratch card",
                    "description": "",
                    "game_id": "scqog",
                    "game_name": "Queen of Gold 100,000",
                    "id": 612,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/scqog.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Scratch card",
                    "description": "",
                    "game_id": "scsafari",
                    "game_name": "Hot Safari 50,000",
                    "id": 614,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/scsafari.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Scratch card",
                    "description": "",
                    "game_id": "scwolfgold",
                    "game_name": "Wolf Gold 1 Million",
                    "id": 615,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/scwolfgold.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Scratch card",
                    "description": "",
                    "game_id": "scpanda",
                    "game_name": "Panda Gold 10,000",
                    "id": 617,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/scpanda.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Scratch card",
                    "description": "",
                    "game_id": "scdiamond",
                    "game_name": "Diamond Strike 100,000",
                    "id": 618,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/scdiamond.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Scratch card",
                    "description": "",
                    "game_id": "scgoldrush",
                    "game_name": "Gold Rush 250,000",
                    "id": 620,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/scgoldrush.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Scratch card",
                    "description": "",
                    "game_id": "sc7piggies",
                    "game_name": "7 Piggies 5,000",
                    "id": 628,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/sc7piggies.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  }
                ]
              },
              {
                "id": 11,
                "name": "Baccarat",
                "priority": 0,
                "status": 1,
                "games": [
                  {
                    "category": "Baccarat",
                    "description": "",
                    "game_id": "bca",
                    "game_name": "Baccarat",
                    "id": 608,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/bca.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  }
                ]
              },
              {
                "id": 10,
                "name": "Blackjack",
                "priority": 0,
                "status": 1,
                "games": [
                  {
                    "category": "Blackjack",
                    "description": "",
                    "game_id": "bjmb",
                    "game_name": "American Blackjack",
                    "id": 400,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/bjmb.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Blackjack",
                    "description": "",
                    "game_id": "bjma",
                    "game_name": "Multihand Blackjack",
                    "id": 616,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/bjma.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  }
                ]
              },
              {
                "id": 8,
                "name": "Classic Slots",
                "priority": 0,
                "status": 1,
                "games": [
                  {
                    "category": "Classic Slots",
                    "description": "",
                    "game_id": "cs3irishcharms",
                    "game_name": "Irish Charms",
                    "id": 357,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/cs3irishcharms.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Classic Slots",
                    "description": "",
                    "game_id": "cs5triple8gold",
                    "game_name": "888 Gold",
                    "id": 361,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/cs5triple8gold.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  },
                  {
                    "category": "Classic Slots",
                    "description": "",
                    "game_id": "cs3w",
                    "game_name": "Diamonds are Forever 3 Lines",
                    "id": 630,
                    "image": "https://api-dk11.pragmaticplay.net/game_pic/square/200/cs3w.png",
                    "priority": 1,
                    "provider_id": 1,
                    "provider_name": "Pragmatic Play",
                    "status": 1
                  }
                ]
              },
              {
                "id": 5,
                "name": "Keno",
                "priority": 0,
                "status": 1,
                "games": [
                  {
                    "category": "Keno",
                    "description": "",
                    "game_id": "RussianKeno",
                    "game_name": "Russian Keno",
                    "id": 16,
                    "image": "https://storage.googleapis.com/maybet/smartsoft/RussianKeno.png",
                    "priority": 1,
                    "provider_id": 5,
                    "provider_name": "Smartsoft Gaming",
                    "status": 1
                  },
                  {
                    "category": "Keno",
                    "description": "",
                    "game_id": "FrenchKeno",
                    "game_name": "French Keno",
                    "id": 43,
                    "image": "https://storage.googleapis.com/maybet/smartsoft/FrenchKeno.png",
                    "priority": 1,
                    "provider_id": 5,
                    "provider_name": "Smartsoft Gaming",
                    "status": 1
                  },
                  {
                    "category": "Keno",
                    "description": "",
                    "game_id": "VIPKeno",
                    "game_name": "VIP Keno",
                    "id": 46,
                    "image": "https://storage.googleapis.com/maybet/smartsoft/VIPKeno.png",
                    "priority": 1,
                    "provider_id": 5,
                    "provider_name": "Smartsoft Gaming",
                    "status": 1
                  }
                ]
              },
              {
                "id": 3,
                "name": "Plinko",
                "priority": 0,
                "status": 1,
                "games": [
                  {
                    "category": "Plinko",
                    "description": "",
                    "game_id": "1",
                    "game_name": "Comet",
                    "id": 1,
                    "image": "https://stgameswesteu.blob.core.windows.net/strapi-uploads/assets/gameicon_comet_crash_x3_3fac109cdf.png",
                    "priority": 95,
                    "provider_id": 3,
                    "provider_name": "STP",
                    "status": 1
                  },
                  {
                    "category": "Plinko",
                    "description": "",
                    "game_id": "6",
                    "game_name": "Soccer Mania",
                    "id": 6,
                    "image": "https://stgameswesteu.blob.core.windows.net/strapi-uploads/assets/gameicon_soccer_mania_x3_d3dba60f76.png",
                    "priority": 1,
                    "provider_id": 3,
                    "provider_name": "STP",
                    "status": 1
                  },
                  {
                    "category": "Plinko",
                    "description": "",
                    "game_id": "11",
                    "game_name": "Plinko",
                    "id": 11,
                    "image": "https://stgameswesteu.blob.core.windows.net/strapi-uploads/assets/gameicon_plinko_x3_7b2264e673.png",
                    "priority": 1,
                    "provider_id": 3,
                    "provider_name": "STP",
                    "status": 1
                  },
                  {
                    "category": "Plinko",
                    "description": "",
                    "game_id": "PlinkoX",
                    "game_name": "PlinkoX",
                    "id": 57,
                    "image": "https://storage.googleapis.com/maybet/smartsoft/PlinkoX.png",
                    "priority": 1,
                    "provider_id": 5,
                    "provider_name": "Smartsoft Gaming",
                    "status": 1
                  }
                ]
              },
              {
                "id": 2,
                "name": "Cashout",
                "priority": 0,
                "status": 1,
                "games": [
                  {
                    "category": "Cashout",
                    "description": "",
                    "game_id": "2",
                    "game_name": "Gold Rush",
                    "id": 2,
                    "image": "https://stgameswesteu.blob.core.windows.net/strapi-uploads/assets/gameicon_gold_rush_x3_671b69a39e.png",
                    "priority": 1,
                    "provider_id": 3,
                    "provider_name": "STP",
                    "status": 1
                  },
                  {
                    "category": "Cashout",
                    "description": "",
                    "game_id": "3",
                    "game_name": "Paperplane",
                    "id": 3,
                    "image": "https://stgameswesteu.blob.core.windows.net/strapi-uploads/assets/gameicon_paperplane_x3_61cae0dd33.png",
                    "priority": 1,
                    "provider_id": 3,
                    "provider_name": "STP",
                    "status": 1
                  },
                  {
                    "category": "Cashout",
                    "description": "",
                    "game_id": "5",
                    "game_name": "Monkey Bizniz",
                    "id": 5,
                    "image": "https://stgameswesteu.blob.core.windows.net/strapi-uploads/assets/gameicon_monkey_bizniz_x3_e2c9d18066.png",
                    "priority": 1,
                    "provider_id": 3,
                    "provider_name": "STP",
                    "status": 1
                  },
                  {
                    "category": "Cashout",
                    "description": "",
                    "game_id": "7",
                    "game_name": "Jungle Gems",
                    "id": 7,
                    "image": "https://stgameswesteu.blob.core.windows.net/strapi-uploads/assets/gameicon_jungle_gems_x3_460340fbfc.png",
                    "priority": 1,
                    "provider_id": 3,
                    "provider_name": "STP",
                    "status": 1
                  },
                  {
                    "category": "Cashout",
                    "description": "",
                    "game_id": "8",
                    "game_name": "Magic Numbers",
                    "id": 8,
                    "image": "https://stgameswesteu.blob.core.windows.net/strapi-uploads/assets/gameicon_magic_numbers_tribal_1c04303bb9.png",
                    "priority": 1,
                    "provider_id": 3,
                    "provider_name": "STP",
                    "status": 1
                  },
                  {
                    "category": "Cashout",
                    "description": "",
                    "game_id": "9",
                    "game_name": "Street Dice",
                    "id": 9,
                    "image": "https://stgameswesteu.blob.core.windows.net/strapi-uploads/assets/gameicon_street_dice_3x_f138acd7ca.png",
                    "priority": 1,
                    "provider_id": 3,
                    "provider_name": "STP",
                    "status": 1
                  },
                  {
                    "category": "Cashout",
                    "description": "",
                    "game_id": "10",
                    "game_name": "Dare Devil",
                    "id": 10,
                    "image": "https://stgameswesteu.blob.core.windows.net/strapi-uploads/assets/gameicon_daredevil_x3_16bd37b27c.png",
                    "priority": 1,
                    "provider_id": 3,
                    "provider_name": "STP",
                    "status": 1
                  },
                  {
                    "category": "Cashout",
                    "description": "",
                    "game_id": "12",
                    "game_name": "Archeo",
                    "id": 12,
                    "image": "https://stgameswesteu.blob.core.windows.net/strapi-uploads/assets/gameicon_archeo_x3_7314ed5561.png",
                    "priority": 1,
                    "provider_id": 3,
                    "provider_name": "STP",
                    "status": 1
                  },
                  {
                    "category": "Cashout",
                    "description": "",
                    "game_id": "13",
                    "game_name": "Rock Paper Scissors",
                    "id": 13,
                    "image": "https://stgameswesteu.blob.core.windows.net/strapi-uploads/assets/gameicon_rps_x3_7bb476c3cb.png",
                    "priority": 1,
                    "provider_id": 3,
                    "provider_name": "STP",
                    "status": 1
                  }
                ]
              },
              {
                "id": 23,
                "name": "crash game",
                "priority": 0,
                "status": 1,
                "games": [
                  {
                    "category": "crash game",
                    "description": "",
                    "game_id": "10450",
                    "game_name": "Don´t drop the ball",
                    "id": 862,
                    "image": "https://cdn.xpress-ix.com/images/31_7347931.png",
                    "priority": 1,
                    "provider_id": 9,
                    "provider_name": "Golden races",
                    "status": 1
                  },
                  {
                    "category": "crash game",
                    "description": "",
                    "game_id": "10451",
                    "game_name": "Final Fist",
                    "id": 866,
                    "image": "https://cdn.xpress-ix.com/images/30_2932315.png",
                    "priority": 1,
                    "provider_id": 9,
                    "provider_name": "Golden races",
                    "status": 1
                  }
                ]
              }
          ],
    };
  },


  mounted: function () {

    this.$store.dispatch("setCurrentPage", "casino");

    // this.getCategories();

    //this.reloadProfile();

    this.showSlides();

    /*
    this.getCasinoEvents(201)
    this.getCasinoEvents(203)
    this.getCasinoEvents(225)
    this.getCasinoEvents(229)
    this.getCasinoEvents(230)
    this.getCasinoEvents(545)
    */

  },

  computed: {
    app: function () {
      return this.$store.state.app;
    },

    profile: function () {
      return this.getProfile();
    },
    token: function () {
      return this.getAuth();
    },
  },

  methods: {

    getCategories: function(){

      var vm = this;

      var path = "/category";

      casino.get(path)
          .then(res => {

            vm.categories = res.data

            vm.jQuery.each(vm.categories, function (k,v){

              vm.categories[k].games = []

              vm.getGames(k,v.id)

            })

            setTimeout(function (){

              console.log(' vm.categories '+JSON.stringify(vm.categories, undefined, 2))


            },10000)

          })
          .catch(error => {

            console.log(error)

          })

    },

    getGames: function(index, categoryID){

      var vm = this;

      var path = "/games";

      casino.get(path, {params: {
          page: 1,
          per_page: 200,
          category_id: categoryID,
        }})
          .then(res => {

            var cat = vm.categories[index]
            cat.games = res.data.data
            vm.categories[index] = cat

          })
          .catch(error => {

            console.log(error)

          })
    },

    showSlides: function () {

      var vm = this;

      var slideIndex = 0;

      var i;
      var slides = document.getElementsByClassName("mySlides");
      var dots = document.getElementsByClassName("dot");

      for (i = 0; i < slides.length; i++) {

        slides[i].style.display = "none";
      }

      slideIndex++;

      if (slideIndex > slides.length) {

        slideIndex = 1;
      }

      for (i = 0; i < dots.length; i++) {

        dots[i].className = dots[i].className.replace(" active", "");

      }

      if(slides[slideIndex - 1]) {

        slides[slideIndex - 1].style.display = "block";
        dots[slideIndex - 1].className += " active";

      }

      setTimeout(function (){

        vm.showSlides();

      },2000)  // Change image every 2 seconds

    },

    getKey: function (index) {

      return Math.random()
          .toString(10)
          .replace("0.", "games-id-" + index + "-");
    },

  },
  filters: {

    amount: function (val) {

      if (val === undefined) {
        return val;
      }

      return parseFloat(val).toFixed(2).toLocaleString();
    },
  },
};
</script>