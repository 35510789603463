import axios from 'axios';

const casino = axios.create({
    baseURL: process.env.VUE_APP_BASE_CASINO_BASE_URL,
});

casino.defaults.headers.post['Content-Type'] = 'application/json';
casino.defaults.headers.post["Access-Control-Allow-Origin"] = "*";

casino.defaults.method = "get";

export default casino;